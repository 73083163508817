import React, {useMemo} from "react";
import AINode from "./AINode";
import InputNode from "./InputNode";
import OutputNode from "./OutputNode";
import ProcessingNode from "./ProcessingNode";
import {HeadNode} from "./BaseNode";
import LLMNode from "./LLMNode";
import SDNode from "./SDNode";

const CustomNodeTypes = ({username, projectName, setExternalNodes}) => {
    const AINodeType = useMemo(() => (props) => <AINode {...props} username={username} projectName={projectName}
                                                        setExternalNodes={setExternalNodes}/>, [username, projectName, setExternalNodes]);
    const InputNodeType = useMemo(() => (props) => <InputNode {...props} username={username} projectName={projectName}
                                                              setExternalNodes={setExternalNodes}/>, [setExternalNodes, username, projectName]);
    const OutputNodeType = useMemo(() => (props) => <OutputNode {...props} username={username} projectName={projectName} setExternalNodes={setExternalNodes} />, [projectName, setExternalNodes, username]);
    const ProcessingNodeType = useMemo(() => (props) =>
        <ProcessingNode {...props} username={username} projectName={projectName} setExternalNodes={setExternalNodes}/>, [setExternalNodes, projectName, username]);
    const LLMNodeType = useMemo(() => (props) => <LLMNode {...props} setExternalNodes={setExternalNodes}
                                                          username={username}
                                                          projectName={projectName}/>, [setExternalNodes, username, projectName]);
    const SDNodeType = useMemo(() => (props) => <SDNode {...props} setExternalNodes={setExternalNodes}
                                                          username={username}
                                                          projectName={projectName}/>, [setExternalNodes, username, projectName]);
    const HeadNodeType = useMemo(() => (props) => <HeadNode {...props} />, []);

    return useMemo(
        () => ({
            AI: AINodeType,
            HEAD: HeadNodeType,
            INPUT: InputNodeType,
            OUTPUT: OutputNodeType,
            CUSTOM_PYTHON: ProcessingNodeType,
            LLM: LLMNodeType,
            SD: SDNodeType,
        }),
        [AINodeType, ProcessingNodeType, HeadNodeType, InputNodeType, OutputNodeType, LLMNodeType, SDNodeType]
    );
};

export default CustomNodeTypes;