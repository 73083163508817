import React, { useEffect, useState } from "react";
import { findLabelById } from "./utils";
import { Row, Col, Image } from "react-bootstrap";
import styles from "./styles/ProjectInfo.module.css";


import turquoise from "./images/exclamationMark/turquoise.svg";
import lightGreen from "./images/exclamationMark/lightGreen.svg";
import red from "./images/exclamationMark/red.svg";
import blue from "./images/exclamationMark/blue.svg";


export default function  ProjectInfoPage({
    projectInfo,
    availableHardware,
}) {
    const [acceleratorName, setAcceleratorName] = useState(null);
    const titleState = projectInfo.state

    const state = {
        "ACTIVE": {
            style: { background: "#E1FBFB", color: "#0F7583" },
            image: turquoise
        },
        "RUNNING": {
            style: { background: "#DAFBE1", color: "#059D5B" },
            image: lightGreen
        },
        "ERROR": {
            style: { background: "#FFE0E0", color: "#B32632" },
            image: red
        },
        "IDLE": {
            style: { background: "#E8F0FB", color: "#1053D4" },
            image: blue
        },
        "STARTING": {
            style: { background: "#DAFBE1", color: "#059D5B" },
            image: lightGreen
        },
        "STOPPING": {
            style: { background: "#FFE0E0", color: "#B32632" },
            image: red
        },
    }
    const details = [
        { title: "Inference count", value: projectInfo.predictions_count || 0 },
        { title: "Accelerator", value: acceleratorName },
        { title: "Av. inference time", value: projectInfo.average_inference_time || 0 },
    ]

    useEffect(() => {
        if (projectInfo) {
            fillProjectInfo();
        }
    }, [projectInfo]);

    const fillProjectInfo = () => {
        const acceleratorName = findLabelById(projectInfo.preferred_accelerator, availableHardware)
        setAcceleratorName(acceleratorName)
    }

    useEffect(() => {
        if (projectInfo) {
            fillProjectInfo();
        }
    }, [projectInfo]);



    if (!projectInfo) {
        return (
            <h1>Project not found</h1>
        )
    } else {
        return (
            <Col className={styles.main}>
                <div className={styles.text}>
                    <span
                        style={state[projectInfo.state]?.style}
                        className={styles.state}
                    >
                        {titleState === "IDLE"
                            ? titleState
                            : titleState.replace(
                                titleState.slice(1),
                                titleState.slice(1).toLowerCase())
                        }
                        {/*&nbsp;*/}
                        {/*<Image*/}
                        {/*    src={state[projectInfo.state]?.image}*/}
                        {/*    alt="Exclamation Mark"*/}
                        {/*    className={styles.image}*/}
                        {/*/>*/}
                    </span>
                </div>
                <span className={styles.created_at}>
                    <span>
                        Created: &nbsp;
                    </span>
                    <span className={styles.date}>
                        {new Date(projectInfo.created_at).toLocaleDateString("en-US")}
                    </span>
                </span>
                {/* {details.map((detail, index) => (
                    <Col key={index} xs={12} className={styles.text}>
                        <span className={styles.title}>
                            {detail.title}:
                        </span>
                        &nbsp;
                        <span>
                            {detail.value}
                        </span>
                    </Col>
                ))} */}
            </Col>
        );
    }

}

