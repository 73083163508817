import React from 'react';
import { Link as LK } from 'react-router-dom';
import { Card, Container, Text, Spacer } from '@nextui-org/react';
import { HeaderSpacer } from "../Header/HeaderSpacer";
import SingButtons from "./SignButtons";
import loginImage from './images/signup.jpeg';


const RegisterForm = () => {
    return (
        <Container xs justify="center" style={{ minHeight: "calc(100vh - 190px)", marginTop: "0px" }}>
            <HeaderSpacer />
            <Card>
                <Card.Body>
                    <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                        <Text h2 style={{ marginBottom: "0", paddingBottom: "0" }}>Sign Up</Text>
                        <Text>to get early access credits and bonuses</Text>
                        <Spacer y={1} />
                        <Card.Image
                            src={loginImage}
                            objectFit="cover"
                            width="100%"
                            style={{ borderRadius: "10px" }}
                        />

                        <Spacer y={1} />
                        <Text size={11}>Already have an account? <LK to={"/login"} style={{ color: "#7828C8" }}>Log in</LK></Text>
                        <Spacer y={0.5} />
                        <SingButtons isSignUp={true} />
                    </div>
                </Card.Body>
            </Card>
            <HeaderSpacer />
        </Container>
    );
};

export default RegisterForm;