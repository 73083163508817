import React from 'react';
import { Modal, Text, Button } from '@nextui-org/react';
import {getTeam, removeMember} from '../../../api/users';
import { toastSuccess, toastError } from '../../../utils/toasts';

const RemoveMemberModal = ({ isOpen, onClose, teamUsername, selectedMember, setTeamData }) => {
    const selectedUsername = selectedMember?.user?.username
    const handleRemoveMember = async () => {
        try {
            await removeMember(teamUsername, selectedUsername);
            const team = await getTeam(teamUsername);
            setTeamData(team);
            toastSuccess('Member removed successfully.');
            onClose();
        } catch (error) {
            toastError('Failed to remove member. Please try again.');
        }
    };

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Modal.Header>
                <Text h3>Remove Member</Text>
            </Modal.Header>
            <Modal.Body>
                <Text>Are you sure you want to remove {selectedUsername} from the team?</Text>
            </Modal.Body>
            <Modal.Footer>
                <Button auto flat color="error" onClick={onClose}>
                    Cancel
                </Button>
                <Button auto color="error" onClick={handleRemoveMember}>
                    Remove
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RemoveMemberModal;