import React, { useState } from 'react';

const useFormValidation = (initialValues, validationRules) => {
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [showHelperText, setShowHelperText] = useState(false);

    const helperTextStyle = {
        color: 'red',
        visibility: 'visible'
    };

    const handleChange = (name, value) => {
        const newValues = { ...values, [name]: value };
        setValues(newValues);
        if (validationRules[name]) {
            const newErrors = { ...errors, [name]: validationRules[name](value, newValues) };
            setErrors(newErrors);
        }
    };


    const handleSubmit = (callback) => async (event) => {
        event.preventDefault();
        setShowHelperText(true);
        const newErrors = Object.keys(validationRules).reduce((acc, key) => {
            const error = validationRules[key](values[key], values);
            if (error) acc[key] = error;
            return acc;
        }, {});

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            await callback(values);
        }
    };

    const getHelperText = (fieldName) => {
        if (!showHelperText || !errors[fieldName]) return { text: null };
        return { text: <span style={helperTextStyle}>{errors[fieldName]}</span>, style: helperTextStyle };
    };

    return { values, handleChange, handleSubmit, getHelperText };
};

export default useFormValidation;