const initialState = {
    user: null,
    activeUser: null,
    teams: [],
    error: null,
    isLoading: false,
};

const user = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_REQUEST':
            return { ...state, isLoading: true, error: null };
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                user: action.payload,
                activeUser: action.payload,
                teams: action.payload.teams || [],
                isLoading: false,
                error: null,
            };
        case 'LOGIN_FAILURE':
            return { ...state, error: action.payload, isLoading: false };
        case 'USER_LOGOUT':
            return { ...initialState };
        case 'SET_ACTIVE_USER':
            return {
                ...state,
                activeUser: action.payload,
            };
        case 'UPDATE_USER':
            return {
                ...state,
                user: action.payload,
            };
        default:
            return state;
    }
};

export default user;