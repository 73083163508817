import React, { useState } from "react";
import { useResize } from "../../../hooks/useresize.ts";
import { Row, Col, Image, Container } from "react-bootstrap";
import styles from "./SettingMonitoring.module.css";
import DeploySettings from "../ProjectMain/InteractivePage/DeploymentComponents/DeploySettings";
import PodsContainer from "../ProjectMain/InteractivePage/DeploymentComponents/PodsContainer.tsx";
import Accordion from "../ProjectMain/components/Accordion/index.tsx";

import vector from "../../Projects/ProjectMain/images/Vector912.svg";

export default function SettingMonitoring({
    username,
    projectInfo,
    setProjectInfo,
    availableHardware,
    projectDeploymentInfo,
    ...props
}) {
    const BREAKPOINT = 991
    const width = useResize()
    const [showSetting, setShowSetting] = useState(false)

    return (
        <Container fluid>
            <Row className={styles.main}>
                {width < BREAKPOINT && <Accordion
                    title="Settings"
                    state={showSetting}
                    func={_ => setShowSetting(!showSetting)}
                />}
                {(showSetting || width >= BREAKPOINT) &&
                    <>
                        <Col className={styles.deploySettings}>
                            <DeploySettings
                                username={username}
                                projectInfo={projectInfo}
                                setProjectInfo={setProjectInfo}
                                availableHardware={availableHardware}
                                projectDeploymentInfo={projectDeploymentInfo}
                                state={showSetting}
                                func={_ => setShowSetting(!showSetting)}
                                widthScreen={width}
                                BREAKPOINT={BREAKPOINT}
                            />
                        </Col>
                        {width < BREAKPOINT &&
                            <Col xs={12} >
                                <Image src={vector} width={1000} alt="vector" style={{ paddingTop: "24px", paddingBottom: "0px" }} />
                            </Col>
                        }
                        {/* Monitoring */}
                        <Col className={styles.monitoringMain}>
                            <div className={styles.monitoring}>
                                <PodsContainer
                                    type="idle"
                                    projectDeploymentInfo={projectDeploymentInfo}
                                    maxPods={projectInfo.max_pods}
                                    widthScreen={width}
                                    BREAKPOINT={BREAKPOINT}
                                    updateProjectState={props.updateProjectState}
                                />
                            </div>
                        </Col>
                    </>}
            </Row >
        </Container>
    );
}