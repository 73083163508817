import React from 'react';
import {Modal, Text, Button} from "@nextui-org/react";
import {checkCeleryTaskStatus} from "../../../../api/projects";
import {stopProject} from "../../../../api/flow";


const StopServerModal = ({isOpen, onClose, username, projectInfo, setProjectInfo}) => {

    const handleClose = () => {
        onClose();
        document.body.style.overflow = 'auto';
    };

    const handleSubmit = async () => {
        setProjectInfo({...projectInfo, state: 'STOPPING'});
        handleClose();
        await stopProject(username, projectInfo.name);
    }

    return (
        isOpen ? (
                <Modal
                    open={isOpen}
                    onClose={handleClose}
                >
                    <Modal.Header>
                        <Text h3>Stop Server</Text>
                    </Modal.Header>
                    <Modal.Body>
                            <Text size={16}>Are you sure you want to stop the project?</Text>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button auto flat color="gray" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            auto
                            onClick={handleSubmit}
                            color="error"
                            disabled={!projectInfo.state === 'RUNNING'}
                        >
                            Stop
                        </Button>


                    </Modal.Footer>
                </Modal>
            ) :
            null
    )
        ;
};

export default StopServerModal;
