import {
    Button,
    Card, Grid, Modal, Text,
    Input, Checkbox,
} from "@nextui-org/react";
import React, {
    memo, useContext, useEffect, useState
} from "react";
import BaseNode, {
    HandleActionsContainer,
    MinimizedCardBody,
    MinimizedCardHeader,
    MinimizedCardWrapper,
    StyledRightHandle,
} from "../CustomNodes/BaseNode";
import Tooltip from "../Tooltips";
import FlowContext from "../FlowContext";
import {getHandleData, getMaxHandleWidth} from "../utils";
import {compareDims} from "./checks";
import {toastWarn} from "../../../utils/toasts";
import {getNode, updateIOField} from "../../../api/flow";
import FieldEditorModal from "../Modals/utils/InputFieldConstructor";


const ContentBody = ({data, nodes, nodeId, projectState, node, setExternalNodes, username, projectName}) => {
    const source_handles = data.source_handles || [];
    const newObjExists = source_handles.find((obj) => obj.id === 'new_source_handle');
    const [handleModal, setHandleModal] = useState({visible: false});
    const [selectedHandle, setSelectedHandle] = useState(null);
    const [fieldType, setFieldType] = useState(null);
    const [maxHandleWidth, setMaxHandleWidth] = useState('50px');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editableField, setEditableField] = useState(null);


    useEffect(() => {
        if (data.source_handles.length > 0) {
            const maxWidth = getMaxHandleWidth(data.source_handles,  true);
            setMaxHandleWidth(maxWidth);
        }
    }, [data.source_handles]);


    if (!newObjExists && ['ERROR', 'IDLE'].includes(projectState)) {
        const newObj = {
            id: 'new_source_handle',
            label_slug: 'Connect field',
        };
        source_handles.push(newObj);
    }

    const handleSaveField = async (selectedHandle, updatedFieldData) => {
        const attributes = updatedFieldData.attributes
        const fieldType = updatedFieldData.field_type
        const updatedSelectedHandleData = {
            ...selectedHandle.data, ...attributes,
        };
        const newHandle = {
            label: selectedHandle.label,
            data: updatedSelectedHandleData,
            field_type: fieldType,
        };
        const handleTypeKey = 'source_handles';
        node.data[handleTypeKey]['source_handles'] = newHandle;
        await updateIOField(newHandle, selectedHandle.id, node.id, username, projectName);
        setHandleModal({visible: false});
        setSelectedHandle(null);
        const tempNode = await getNode(node.id, username, projectName);
        await setExternalNodes(tempNode, tempNode.id);

    };

    const createIsValidConnection = (handleId, nodeId, handleType) => {
        return (connection) => {
            const allowedNodeTypes = ['CUSTOM_PYTHON', 'AI', 'LLM', 'SD']
            const newHandlesIds = ['new_source_handle', 'new_target_handle'];
            const {startHandle, endNode, endHandle} = getHandleData(nodes, handleId, nodeId, handleType, connection);
            if (newHandlesIds.includes(endHandle.id) && newHandlesIds.includes(startHandle.id)) {
                toastWarn("Please, first create the handle manually", `createHandleManually-${startHandle.id}-${endHandle.id}`);
                return false;
            }
            if (startHandle.id === 'new_source_handle' && allowedNodeTypes.includes(endNode.type)) {
                return true;
            } else {
                if (JSON.stringify(endHandle.dims)) {
                    if (!compareDims(startHandle.dims, endHandle.dims)) {
                        toastWarn('Dims do not match', `dimsDoNotMatch-${startHandle.id}-${endHandle.id}`);
                        return false;
                    }
                }
                if (endHandle.ai_format) {
                    if (JSON.stringify(startHandle.ai_format) !== JSON.stringify(endHandle.ai_format)) {
                        toastWarn('Formats do not match', `formatsDoNotMatch-${startHandle.id}-${endHandle.id}`);
                        return false;
                    }
                }
                return true
            }
        }
    }

    return (
        <>
            <MinimizedCardWrapper>
                <Card
                    variant={"flat"}
                    css={{overflow: "visible", $$cardColor: "$colors$accents2"}}
                >
                    <MinimizedCardHeader>Outputs</MinimizedCardHeader>
                    {source_handles &&
                        source_handles.map((source) => (
                            <MinimizedCardBody key={source.id}>
                                <Grid.Container justify="between" style={{width: '100%'}}>
                                    <Grid xs={6} style={{width: maxHandleWidth}}>
                                        {source.label_slug}
                                    </Grid>
                                    {source.id !== 'new_source_handle' &&
                                        <Grid xs={6}>
                                            <HandleActionsContainer>
                                                {projectState !== 'DEMO' &&
                                                    <Button auto size="xs" style={{marginRight: '5px'}}
                                                            onClick={() => {
                                                                setEditableField(source);
                                                                setIsModalOpen(true);
                                                            }}>
                                                        edit
                                                    </Button>
                                                }
                                                <div style={{marginRight: '5px'}}>
                                                    <Tooltip tooltip={source.dims?.join(',')} button='dims'></Tooltip>
                                                </div>
                                                <Tooltip tooltip={source.ai_format} button='format'></Tooltip>
                                            </HandleActionsContainer>
                                        </Grid>
                                    }
                                </Grid.Container>
                                <StyledRightHandle
                                    id={`${source.id.toString()}`}
                                    type="source"
                                    position="right"
                                    optional={source.data?.optional?.toString()}
                                    isValidConnection={createIsValidConnection(source.id, nodeId, "source_handles")}
                                />
                            </MinimizedCardBody>
                        ))}
                </Card>
            </MinimizedCardWrapper>
            <FieldEditorModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                selectedHandle={editableField}
                onSave={handleSaveField}
            />
        </>
    );
};

const InputNode = ({setExternalNodes, username, projectName, ...props}) => {
    const {nodes, edges, projectState} = useContext(FlowContext)
    return (
        <BaseNode
            id={props.id}
            label={props.data.label}
            type={props.type}
            data={props.data}
            content={
                <ContentBody
                    data={{...props.data}}
                    nodes={nodes}
                    nodeId={props.id}
                    edges={edges}
                    projectState={projectState}
                    node={props}
                    setExternalNodes={setExternalNodes}
                    username={username}
                    projectName={projectName}
                />
            }
        />
    );
};

export default memo(InputNode);
