import axiosInstance, {getBaseUrl} from './request';
import axios from "axios";

export async function login(username, password) {
    const data = { username, password };
    return await axiosInstance.post('auth/login/', data);
}

export async function logout() {
    return await axiosInstance.post('auth/logout/');
}

export async function getUserinfo() {
    return await axiosInstance.get('auth/user/');
}

export async function refreshToken(token) {
    const data = { refresh: token };
    return await axios.post(`${getBaseUrl()}auth/token/refresh/`, data);
}

export async function register(data) {
return await axiosInstance.post('auth/registration/', data);
}


export async function oauthLogin(provider, data) {
    return await axiosInstance.post(`auth/${provider}/`, data);
}
