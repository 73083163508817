import React, {useState} from "react";
import {CopyToClipboard} from "react-copy-to-clipboard";
import { Image } from "react-bootstrap";
import copyToClipboard from "./images/copyToClipboard.svg";
import styles from "./index.module.css";

export default function Copy({ text }) {
    const [isCopied, setIsCopied] = useState(false);

    const onCopyText = () => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 1500);
    };
    return (
        <span className={styles.main}>
            <CopyToClipboard text={text} onCopy={onCopyText} >
                <Image src={copyToClipboard} className={styles.image} />
            </CopyToClipboard >
            {isCopied ? <span className={styles.copied}>Copied!</span> : null}
        </span>
    );
}