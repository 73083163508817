import axiosInstance from "./request";

let financesUrl = 'finances/'

export async function getPayments(username, page = 1) {
    return await axiosInstance.get(`${financesUrl}${username}/payments/?page=${page}`);
}

export async function getTransactions(username, page = 1) {
    return await axiosInstance.get(`${financesUrl}${username}/transactions/?page=${page}`);
}

export async function getProjectUsage(username, page = 1) {
    return await axiosInstance.get(`${financesUrl}${username}/usage/?page=${page}`);
}

export async function addFunds(username, data) {
    return await axiosInstance.post(`${financesUrl}${username}/payments/add_funds/`, data);
}

// Function to get saved cards for a user
export async function getSavedCards(username) {
    return await axiosInstance.get(`${financesUrl}${username}/payments/payment_methods/`);
}

// Function to save a new card for a user
export async function saveCard(username, paymentMethodId) {
    return await axiosInstance.post(`${financesUrl}${username}/payments/save_payment_method/`, {
        payment_method_id: paymentMethodId,
    });
}

// Function to update auto-payment settings for a user
export async function updateAutoPaymentSettings(username, settings) {
    return await axiosInstance.put(`${financesUrl}${username}/payments/auto_payment_settings/`, settings);
}
