export const HANDLE_TYPES = [
    {value: "3", label: "TYPE_INT8"},
    {value: "5", label: "TYPE_INT16"},
    {value: "6", label: "TYPE_INT32"},
    {value: "7", label: "TYPE_INT64"},
    {value: "2", label: "TYPE_UINT8"},
    {value: "4", label: "TYPE_UINT16"},
    {value: "12", label: "TYPE_UINT32"},
    {value: "13", label: "TYPE_UINT64"},
    {value: "8", label: "TYPE_STRING"},
    {value: "9", label: "TYPE_BOOL"},
    {value: "1", label: "TYPE_FP32"},
    {value: "10", label: "TYPE_FP16"},
    {value: "11", label: "TYPE_FP64"},
    {value: "14", label: "TYPE_COMPLEX64"},
    {value: "15", label: "TYPE_COMPLEX128"},
    {value: "16", label: "TYPE_BF16"}
];

export const TRAINED_FORMATS = [
    {value: 'FP16', label: 'FP16'},
    {value: 'FP32', label: 'FP32'},
    {value: 'INT8', label: 'INT8'}
];


export const LLM_TYPES = [
    {value: 'bigscience/bloom-560m', label: 'BLOOM 560M'},
    {value: 'google/flan-t5-xxl', label: 'FLAN-T5 11.3B'},
    {value: 'facebook/galactica-120b', label: 'Galactica 120B'},
    {value: 'EleutherAI/gpt-neox-20ba', label: 'GPT-Neox 20B'},
    {value: 'facebookresearch/llama', label: 'Llama 7B'},
    {value: 'meta-llama/Llama-2-7b-hf', label: 'Llama2 7B'},
    {value: 'facebook/opt-66b', label: 'OPT 66B'},
    {value: 'bigcode/santacoder', label: 'SantaCoder 1B'},
    {value: 'bigcode/starcoder', label: 'Starcoder 15.5B'},
    {value: 'tiiuae/falcon-7b', label: 'Falcon 7B'},
    {value: 'tiiuae/falcon-40b', label: 'Falcon 40B'},
    {value: 'mosaicml/mpt-30b', label: 'MTP 30B'},
    {value: 'ehartford/Wizard-Vicuna-7B-Uncensored', label: 'Vicuna Uncensored 7B'}
];
