import {axiosGatewayInstance, GATEWAY_URL} from "./request";

export async function getProjectSchema(username, projectName, apiKey) {
    const headers = apiKey ? {'X-API-KEY': apiKey} : {};
    return await axiosGatewayInstance.get(`${username}/${projectName}/openapi.json`, {headers});
}

export async function createPredictionRequest(username, projectName, apiKey, data) {
    const headers = apiKey ? {'X-API-KEY': apiKey} : {};
    const formData = new FormData();
    for (const key in data) {
        formData.append(key, data[key]);
    }

    const response = await fetch(`${GATEWAY_URL}${username}/${projectName}/predict`, {
        method: 'POST',
        headers: headers,
        body: formData,
        keepalive: false
    });
    return await response.text();
}

export async function createStreamPredictionRequest(username, projectName, apiKey, data) {
    const headers = apiKey ? {'X-API-KEY': apiKey} : {};
    const formData = new FormData();
    for (const key in data) {
        formData.append(key, data[key]);
    }

    const response = await fetch(`${GATEWAY_URL}${username}/${projectName}/predict/stream/`, {
        method: 'POST',
        headers: headers,
        body: formData,
        keepalive: false
    });
    return response;
}
