import React, { useState } from "react";
import { Row, Col, Image } from "react-bootstrap";

import styles from "./index.module.css";

import defaultCard from "./images/defaultCard.svg";
import visa from "./images/visa.svg";
import mastercard from "./images/mastercard.svg";
import chevronUp from "./images/chevronUp.svg";
import chevronDown from "./images/chevronDown.svg";

const formatExpiryDate = (expiryDate) => {
    if (!expiryDate) {
        return "--/--"
    }
    const date = new Date(expiryDate);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);
    return `${month}/${year}`;
};

export default function ViewCards({ savedCards, selectedCard, setSelectedCard }) {
    return (
        <Row style={{ marginBottom: "14px", paddingRight: "20px" }}>
            {savedCards.map((item, index) => (
                <PaymentCard
                    key={index}
                    item={item}
                    onClick={_ => setSelectedCard(item.stripe_payment_method_id)}
                    selectedCard={selectedCard}
                />
            ))}
        </Row >
    );
}

export function PaymentCard({ item, onClick, selectedCard, style = {} }) {
    return (
        <Col key={item.id} xs={12} className={styles.container} style={style} onClick={onClick}>
            <div className={styles.brandImage}>
                {getCardIcon(item.card_brand)}
            </div>
            <div>
                <div>
                    <span className={styles.numberCard}>
                        {
                            item.card_brand[0].toUpperCase() +
                            item.card_brand.slice(1)}
                            &nbsp;
                        •••• •••• •••• {item.last_four}
                    </span>
                    {item.stripe_payment_method_id === selectedCard &&
                        <span className={styles.primary}>Selected</span>
                    }
                </div>
                <div className={styles.expiry}>
                    Expiry: {formatExpiryDate(item.expiry_date)}
                </div>
            </div>
        </Col>
    );
}

export function DropDown({ savedCards, selectedCard, setSelectedCard }) {
    const selected = savedCards.find(card => card.stripe_payment_method_id === selectedCard) || null
    const [show, setShow] = useState(false)

    const handlerSelect = (card) => {
        setSelectedCard(card.stripe_payment_method_id)
        setShow(false)
    }
    return (
        <div>
            <div className={`${styles.dropdown}`} onClick={_ => setShow(!show)}>
                {selected ?
                    <>
                        <div className={styles.brandImage}>
                            {getCardIcon(selected.card_brand)}
                        </div>
                        <div style={{ paddingTop: "12px" }}>
                            <span className={styles.numberCard}>
                                {selected.card_brand[0].toUpperCase() +
                                    selected.card_brand.slice(1)}
                                •••• •••• •••• {selected.last_four}
                            </span>
                            <span className={styles.primary}>Selected</span>
                            <span className={styles.dropdownbtn}>
                                <Image src={show ? chevronUp : chevronDown} alt="chevron" />
                            </span>
                        </div>
                    </>
                    : <div style={{ paddingTop: "8px", display: "inline-flex", justifyContent: "space-around" }}>
                        <span>
                            Select payment method
                        </span>
                        <span
                            className={styles.dropdownbtn}
                            style={{ top: "16px" }}
                        >
                            <Image src={show ? chevronUp : chevronDown} alt="chevron" />
                        </span>
                    </div>}
            </div>
            {show &&
                <div className={styles.cardContainer}>
                    {savedCards.map((card, index) => (
                        <div className={styles.card} key={index} >
                            <PaymentCard
                                onClick={_ => handlerSelect(card)}
                                item={card}
                                style={{
                                    paddingLeft: "12px",
                                    paddingTop: "4px",
                                }} />
                        </div>
                    ))}
                </div>
            }
        </div>
    );
}

const getCardIcon = (brand) => {
    if (!brand) return null;

    switch (brand.toLowerCase()) {
        case 'visa':
            return <Image src={visa} ait="Brand Image" style={{ paddingTop: "8px" }} />;
        case 'mastercard':
            return <Image src={mastercard} ait="Brand Image" style={{ paddingTop: "8px" }} />;;
        default:
            return <Image src={defaultCard} ait="Brand Image"  style={{padding: "4px 8px", marginRight: "2px"}} />;;
    }
};