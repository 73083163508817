import React from 'react';
import {login} from '../../api/auth';
import {useDispatch} from 'react-redux';
import {Card, Container, Input, Text, Spacer, Button} from '@nextui-org/react';
import { Link as LK, useNavigate } from 'react-router-dom';
import {HeaderSpacer} from '../Header/HeaderSpacer';
import useFormValidation from '../../utils/checkForm';
import SingButtons from "./SignButtons";
import loginImage from './images/signin.jpeg';
import {toastError} from "../../utils/toasts";


const LegacyLoginForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationRules = {
        username: (value) => value.trim() === '' ? 'Please fill in this field.' : null,
        password: (value) => value.trim() === '' ? 'Please fill in this field.' : null,
    };

    const {values, handleChange, handleSubmit, getHelperText} = useFormValidation(
        {username: '', password: ''},
        validationRules
    );

    const handleLogin = async (values) => {
        try {
            dispatch({type: 'LOGIN_REQUEST'});
            const user = await login(values.username, values.password);
            dispatch({
                type: 'TOKEN_CREATED',
                payload: {accessToken: user.access, refreshToken: user.refresh},
            });
            dispatch({type: 'LOGIN_SUCCESS', payload: user.user});
            dispatch({type: 'UPDATE_CLOUDS', payload: user.user});
            navigate('/explore');
        } catch (error) {
            dispatch({type: 'LOGIN_FAILURE', payload: error.message});
            toastError(JSON.stringify(error))
        }
    };

    return (
        <Container xs={true} justify={"center"}>
            <HeaderSpacer/>
            <Card>
                <Card.Body>
                    <div style={{marginLeft: "10px", marginRight: "10px"}}>
                        <form onSubmit={handleSubmit(handleLogin)}>
                            <Text h2>Login</Text>
                            <Card.Image
                                src={loginImage}
                                objectFit="cover"
                                width="100%"
                                style={{borderRadius: "10px"}}
                            />
                            <Spacer y={2} />
                            <Input
                                bordered
                                color={"secondary"}
                                labelPlaceholder="Username or Email"
                                width="100%"
                                type="text"
                                aria-label="Username or Email"
                                value={values.username}
                                onChange={(event) => handleChange('username', event.target.value)}
                                helperText={getHelperText('username').text}
                            />
                            <Spacer y={2}/>
                            <Input.Password
                                labelPlaceholder="Password"
                                bordered
                                color={"secondary"}
                                width="100%"
                                aria-label="Password"
                                value={values.password}
                                onChange={(event) => handleChange('password', event.target.value)}
                                helperText={getHelperText('password').text}
                            />
                            <Spacer y={1}/>
                            <Text size={11}>Don't have an account? <LK to="/signup" style={{color: "#7828C8"}}>Sign Up</LK></Text>
                            <Spacer y={0.5}/>
                            <Button bordered color="secondary" type="submit" style={{width: "100%"}}>
                                Login
                            </Button>
                        </form>
                    </div>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default LegacyLoginForm;