import React from 'react';
import { getProjects } from '../../api/projects';
import { useSelector } from "react-redux";
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { HeaderSpacer } from '../Header/HeaderSpacer';
import { renderProjects } from '../../scripts/renderListAI';
import { CenteredContent } from './ProjectMain/ProjectMainPage';
import useProjectPagination from '../../hooks/useProjectPagination.ts';
import { TITLE_SPLIT_GROUP } from '../../utils/constants.js';

function ProjectList() {
    const size = { xs: 12, sm: 6, md: 6, lg: 4, xl: 4, }
    const username = useSelector((state) => state.user.activeUser.username);

    const {
        activeProjects,
        notActiveProjects,
        error,
        loading,
        lastProjectRef
    } = useProjectPagination(getProjects, username);

    if (error) {
        return <div className="error">{error}</div>;
    }

    return (
        <Container fluid="lg" style={{ minHeight: "calc(100vh - 190px)", marginTop: "0px" }}>
            <HeaderSpacer />
            <Row>
                <Col xs={3} style={{
                    display: 'flex', justifyContent: 'flex-end'
                }}>
                    {/* <Button bordered color={'secondary'} auto onClick={() => setIsModalOpen(true)}>Create new project</Button> */}
                </Col>
            </Row>
            <HeaderSpacer />
            {renderProjects(
                activeProjects,
                TITLE_SPLIT_GROUP.ACTIVE,
                lastProjectRef,
                size
            )}
            {renderProjects(
                notActiveProjects,
                TITLE_SPLIT_GROUP.NOT_ACTIVE,
                lastProjectRef,
                size
            )}
            {loading &&
                <CenteredContent>
                    <Spinner animation="border" role="status" variant="secondary">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </CenteredContent>}
        </Container>
    );
}

export default ProjectList;
