import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Label } from "../Modals/AddPayment/index.tsx";
import { Link } from "react-router-dom";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";

import styles from "../Modals/AddPayment/index.module.css";
import stylesForm from "./index.module.css";
import { saveCard } from "../../../api/finances.js";
import { toastError, toastSuccess } from "../../../utils/toasts.js";

export default function AddCardForm({ user, handleClose, setUnsavedCards = undefined, amount = 0, onlySave = false }) {
    const [shouldSaveCard, setShouldSaveCard] = useState(false);
    const [zipCode, setZipCode] = useState<string>()
    const [loading, setLoading] = useState(false)
    const elements = useElements();
    const stripe = useStripe()

    const handler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setLoading(true)
        try {
            if (!elements) {
                throw new Error("Stripe elements not available")
            }

            if (!stripe) {
                throw new Error("Stripe not available")
            }
            const numberCard = elements.getElement(CardNumberElement)
            if (!numberCard) {
                throw new Error("Card element not available");
            }
            const { paymentMethod: newPaymentMethod, error } = await stripe.createPaymentMethod({
                type: 'card',
                card: numberCard,
                billing_details: {
                    address: {
                        postal_code: zipCode,
                    },
                }
            });
            if (error) throw error;

            try {
                if (shouldSaveCard || onlySave) {
                    await saveCard(user.username, newPaymentMethod.id);
                    toastSuccess("Card saved successfully!");
                } else {
                    setUnsavedCards((e: any) => [...e, {
                        card_brand: newPaymentMethod.card?.brand,
                        id: null,
                        is_default: false,
                        last_four: newPaymentMethod.card?.last4,
                        stripe_payment_method_id: newPaymentMethod.id,
                    }])
                    toastSuccess("Card adding successfully!")
                }
                handleClose()
            } catch (error) {
                console.error("Error adding card:", error);
                toastError("Failed to save card, but payment will proceed");

            }
            

            {/**
            const result = await stripe.confirmCardPayment(client_secret, {
                payment_method: paymentMethod
            });
            if (result.error) throw result.error;
            toastSuccess("Payment successful!");
                */}
        } catch (error) {
            console.error("Payment error:", error);
            toastError(error.message || "Payment failed");
        }
        setLoading(false)
    }

    return (
        <form onSubmit={e => handler(e)}>
            {/*
                <Row className={styles.crow}>
                    <Col xs={12}>
                        <Label>Name on card</Label>
                    </Col>
                    <Col style={{ position: "relative" }}>
                        <input
                            className={`
                            ${styles.inputMoney} 
                            ${styles.inputText}
                                        `}
                            value={amount}
                            type="number"
                            placeholder="0"
                        />
                        <div className={`${styles.dollar} ${styles.inputText}`}>$</div>
                    </Col>
                </Row>
            */}
            <Row className={styles.crow}>
                <Col xs={12}>
                    <Label>Card details</Label>
                </Col>
                <Col xs={12}>
                    <div className={`${styles.fields} ${styles.numberCard}`}>
                        <CardNumberElement />
                    </div>
                </Col>
                <Col xs={6} style={{ paddingRight: "0px" }}>
                    <div className={`${styles.fields} ${styles.expiry}`}>
                        <CardExpiryElement />
                    </div>
                </Col>
                <Col xs={6} style={{ paddingLeft: "0px" }}>
                    <div className={`${styles.fields} ${styles.cvc}`}>
                        <CardCvcElement />
                    </div>
                </Col>
            </Row>
            {/*
            <Row className={styles.crow}>
                <Col xs={12}>
                    <Label>Country</Label>
                </Col>
                <Col>
                    <select className={styles.fields}>
                        <option value="">Select country</option>
                    </select>
                </Col>
            </Row> */}
            {/*<Row className={styles.crow}>*/}
            {/*    <Col xs={12}>*/}
            {/*        <Label>ZIP code</Label>*/}
            {/*    </Col>*/}
            {/*    <Col xs={12}>*/}
            {/*        <input*/}
            {/*        value={zipCode}*/}
            {/*            className={stylesForm.input}*/}
            {/*            placeholder="Enter ZIP code"*/}
            {/*            onChange={e => setZipCode(e.target.value)}*/}
            {/*        />*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            {/*
            <Row className={styles.crow}>
                <Col xs={12}>
                    <Label>Billing contact</Label>
                </Col>
                <Col xs={12}>
                    <Input
                        placeholder="example@mail.net"
                        type="email"
                    />
                </Col>
            </Row>
            */}
            {!onlySave &&
                <Row className={`${styles.crow} pt-1`}>
                    <Col xs={12} style={{ display: "inline-flex", justifyContent: "start" }}>
                        <input
                            type="checkbox"
                            className='me-2'
                            checked={shouldSaveCard}
                            onChange={_ => setShouldSaveCard(!shouldSaveCard)}
                        />
                        <Label>Save this card for the next time</Label>
                    </Col>
                </Row>
            }
            <Row className={styles.crow}>
                <Col xs={12}>
                    <span className={styles.agreetext}>
                        By clicking Pay, you agree to FlyMy.Ai’s&nbsp;
                        <Link to="/terms">Terms of Use</Link> and&nbsp;
                        <Link to="/privacy">Privacy Policy</Link>.
                    </span>
                </Col>
            </Row>
            <Row>
                <Col xs={12} style={{ display: "inline-flex", justifyContent: "end", gap: "16px" }}>
                    <button className={`
                                    ${styles.cancelButton}
                                    ${styles.text}
                                    ${styles.button}
                                `}
                        type="button"
                        onClick={handleClose}
                    >
                        Cancel
                    </button>
                    <button
                    disabled={loading}
                        className={`
                                        ${styles.payButton}
                                        ${styles.text}
                                        ${styles.button}
                                    `}
                    >
                        Save
                    </button>
                </Col>
            </Row>
        </form>
    );
}