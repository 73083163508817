import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Col, Container, Row, Spinner } from 'react-bootstrap';

import AddCardForm from '../AddCardForm/index.tsx';
import { Input, Label } from '../Modals/AddPayment/index.tsx';
import { DropDown } from '../ViewCards/index.jsx';
import { updateAutoPaymentSettings } from '../../../api/finances.js';
import { toastError, toastSuccess } from '../../../utils/toasts.js';

import titleStyle from "../../Finances/Finances.module.css";
import styles from "./index.module.css";


function AutoPaymentModal({ user, show, setShow, savedCards, handleSuccess, selectedCard, setSelectedCard}) {
    const [amount, setAmount] = useState(user.auto_payment_amount || NaN)
    const [threshold, setThreshold] = useState(user.auto_payment_threshold || NaN)
    const [isAutoPayment, setIsAutoPayment] = useState(user.auto_payment_enabled || NaN)
    const [loading, setLoading] = useState(false);
    const [showAddCardForm, setShowAddCardForm] = useState(false)

    const handlerClose = () => setShow(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        try {
            const updatedSettings = await updateAutoPaymentSettings(user.username, {
                auto_payment_enabled: isAutoPayment,
                auto_payment_threshold: threshold,
                auto_payment_amount: amount,
                default_payment_method_id: selectedCard
            });
            toastSuccess("Auto-payment settings updated successfully");
            setIsAutoPayment(updatedSettings.auto_payment_enabled);
            setThreshold(updatedSettings.auto_payment_threshold);
            setAmount(updatedSettings.auto_payment_amount);
            handleSuccess(updatedSettings);
            handlerClose();
        } catch (error) {
            console.error("Auto-payment settings update error:", error);
            toastError(error.response?.data?.detail || "Failed to update auto-payment settings");
        }
        setLoading(false);
    };


    return (
        <>
            <Modal show={show} onHide={handlerClose} size={showAddCardForm ? "lg" : undefined}>
                <Container fluid style={{ padding: "30px" }}>
                    <Row>
                        <Col className={titleStyle.bigText} xs={8}>
                            Auto Payment Settings
                        </Col>
                        <Col>
                            <Modal.Header closeButton style={{ border: "none", padding: "6px" }}>
                            </Modal.Header>
                        </Col>
                    </Row>
                    {loading
                        ?
                        <div className={styles.save}>
                            <Spinner variant="primary" />
                        </div>
                        :
                        <Row>
                            <Col>
                                <form onSubmit={e => handleSubmit(e)}>
                                    <Row className={styles.crow}>
                                        <Col xs={12}>
                                            <Label>Payment</Label>
                                        </Col>
                                        <Input value={amount} setValue={setAmount} />
                                    </Row>
                                    <Row className={styles.crow}>
                                        <Col xs={12}>
                                            <Label>When minimum balance is</Label>
                                        </Col>
                                        <Input value={threshold} setValue={setThreshold} />
                                    </Row>
                                    <Row className={styles.crow}>
                                        <Col xs={12}>
                                            <Label>Payment method</Label>
                                        </Col>
                                        <Col>
                                            <DropDown savedCards={savedCards} selectedCard={selectedCard} setSelectedCard={setSelectedCard}/>
                                        </Col>
                                    </Row>
                                    <Row className={`${styles.crow} pt-1`}>
                                        <Col xs={12} style={{ display: "inline-flex", justifyContent: "start" }}>
                                            <input
                                                type="checkbox"
                                                className='me-2'
                                                checked={isAutoPayment}
                                                onChange={_ => setIsAutoPayment(!isAutoPayment)}
                                            />
                                            <Label>Enable auto-payment</Label>
                                        </Col>
                                    </Row>
                                    <Row style={{paddingBottom: "24px" }}>
                                        <Col xs={12} style={{ display: "inline-flex", justifyContent: "start", gap: "16px" }}>
                                            <button className={`
                                    ${styles.cancelButton}
                                    ${styles.text}
                                    ${styles.button}
                                `}
                                                type="button"
                                                onClick={ _ => setShowAddCardForm(!showAddCardForm)}
                                            >
                                                Add payment method
                                            </button>
                                        </Col>
                                    </Row>

                                    {!showAddCardForm &&
                                        <Row>
                                            <Col xs={12} style={{ display: "inline-flex", justifyContent: "end", gap: "16px"}}>
                                                <button className={`
                                    ${styles.cancelButton}
                                    ${styles.text}
                                    ${styles.button}
                                    `}
                                                    type="button"
                                                    onClick={handlerClose}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    className={`
                                            ${styles.payButton}
                                            ${styles.text}
                                            ${styles.button}
                                            `}
                                                    type="submit"
                                                >
                                                    Save
                                                </button>
                                            </Col>
                                        </Row>
                                    }
                                </form>
                            </Col>
                            {showAddCardForm &&
                                <Col xs={12} lg={6}>
                                    <AddCardForm user={user} handleClose={_ => setShowAddCardForm(false)} onlySave={true} />
                                </Col>
                            }
                        </Row>
                    }
                </Container>
            </Modal>
        </>
    );
}

export default AutoPaymentModal;