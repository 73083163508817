import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { Input, Button } from '@nextui-org/react';
import { FaMicrophone, FaStop, FaCamera } from 'react-icons/fa';
import styles from '../styles/Playground.module.css';
import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import ButtonFly from '../../../Button';

const globalRegisterPromise = connect().then(register);

const FileInput = forwardRef(({ name, detail, handleChange }, ref) => {
    const [file, setFile] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const fileInputRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);

    const [previewUrl, setPreviewUrl] = useState(null);
    const [isCapturing, setIsCapturing] = useState(false);
    const videoRef = useRef(null);
    const [stream, setStream] = useState(null);
    const canvasRef = useRef(null);

    const isAudioInput = detail.contentMediaType === 'audio/*';
    const isImageInput = detail.contentMediaType === 'image/*';

    useEffect(() => {
        if (isAudioInput) {
            const setupRecorder = async () => {
                if (!isReady) {
                    await globalRegisterPromise;
                    setIsReady(true);
                }
            };
            setupRecorder();
        } else {
            setIsReady(true);
        }
    }, [isAudioInput, isReady]);

    useEffect(() => {
        if (isCapturing && videoRef.current && stream) {
            videoRef.current.srcObject = stream;
            if (!canvasRef.current) {
                canvasRef.current = document.createElement('canvas');
            }
        }
    }, [isCapturing]);

    useEffect(() => {
        return () => {
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }
            if (previewUrl) {
                URL.revokeObjectURL(previewUrl);
            }
            if (canvasRef.current) {
                canvasRef.current = null;
            }
        };
    }, []);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            handleChange(name, selectedFile, 'file');
            if (isImageInput) {
                const url = URL.createObjectURL(selectedFile);
                setPreviewUrl(url);
            }
        }
    };

    const toggleRecording = async () => {
        if (isRecording) {
            stopRecording();
        } else {
            startRecording();
        }
    };

    const startRecording = async () => {
        setFile(null);
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'audio/wav' });
            audioChunksRef.current = [];

            mediaRecorderRef.current.ondataavailable = (event) => {
                audioChunksRef.current.push(event.data);
            };

            mediaRecorderRef.current.onstop = () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                const audioFile = new File([audioBlob], 'your_amazing_voice.wav', { type: 'audio/wav' });
                setFile(audioFile);
                handleChange(name, audioFile, 'file');
            };

            mediaRecorderRef.current.start();
            setIsRecording(true);
        } catch (error) {
            alert('Failed to start recording. Please check your microphone permissions.');
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
        }
    };

    const toggleCapture = async () => {
        if (isCapturing) {
            stopCapture();
        } else {
            startCapture();
        }
    };

    const startCapture = async () => {
        setFile(null);
        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
            setStream(mediaStream);
            setIsCapturing(true);
            handleChange(name, 'capturing', 'file');
        } catch (error) {
            alert('Failed to access camera. Please check your camera permissions.');
        }
    };

    const stopCapture = () => {
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
        }
        setStream(null);
        setIsCapturing(false);
        handleChange(name, null, 'file');
    };

    const clearImage = () => {
        if (previewUrl) {
            URL.revokeObjectURL(previewUrl);
        }
        setPreviewUrl(null);
        setFile(null);
        handleChange(name, null, 'file');
    };

    const makePhoto = async () => {
        if (isCapturing && videoRef.current && canvasRef.current) {
            const video = videoRef.current;
            const canvas = canvasRef.current;

            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const context = canvas.getContext('2d');
            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            canvas.toBlob(blob => {
                if (blob) {
                    const imageFile = new File([blob], 'captured_image.png', { type: 'image/png' });
                    setFile(imageFile);
                    const url = URL.createObjectURL(blob);
                    setPreviewUrl(url);
                    if (stream) {
                        stream.getTracks().forEach(track => track.stop());
                    }
                    setStream(null);
                    setIsCapturing(false);
                    handleChange(name, imageFile, 'file');
                } else {
                    alert('Failed to capture image');
                }
            }, 'image/png');
        }
    };

    const getCurrentFrame = async () => {
        if (isCapturing && videoRef.current && canvasRef.current) {
            const video = videoRef.current;
            const canvas = canvasRef.current;

            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const context = canvas.getContext('2d');
            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            return new Promise((resolve, reject) => {
                canvas.toBlob(blob => {
                    if (blob) {
                        const imageFile = new File([blob], 'captured_image.png', { type: 'image/png' });
                        resolve(imageFile);
                    } else {
                        reject(new Error('Failed to capture image'));
                    }
                }, 'image/png');
            });
        } else {
            return Promise.resolve(null);
        }
    };

    React.useImperativeHandle(ref, () => ({
        getCurrentFrame,
        isCapturing: () => isCapturing,
    }));

    return (
        <div className={styles.fileInputContainer}>
            {!isCapturing && !previewUrl && (
                <>
                    <input
                        type="file"
                        onChange={handleFileChange}
                        className={styles.fakeFileInput}
                        ref={fileInputRef}
                        accept={detail.contentMediaType}
                    />
                    <div className={styles.inputWrapper}>
                        <Input
                            readOnly
                            placeholder="Select a file"
                            value={file ? file.name : ''}
                            className={styles.fileInput}
                            fullWidth
                        />
                        <button onClick={() => fileInputRef.current.click()} className={styles.chooseFileButton}>
                            Choose...
                        </button>
                        {isAudioInput && (
                            <Button
                                auto
                                light
                                icon={isRecording ? <FaStop size={20} /> : <FaMicrophone size={20} />}
                                onPress={toggleRecording}
                                className={styles.recordButton}
                                disabled={!isReady}
                            />
                        )}
                        {isImageInput && (
                            <Button
                                auto
                                light
                                icon={<FaCamera size={20} />}
                                onPress={toggleCapture}
                                className={styles.captureButton}
                            />
                        )}
                    </div>
                </>
            )}

            {isCapturing && (
                <div className={styles.videoContainer}>
                    <video ref={videoRef} autoPlay playsInline className={styles.videoElement} />
                    <div className={styles.captureButtons}>
                        <ButtonFly onClick={makePhoto} type={"run"}>Make Photo</ButtonFly>
                        <ButtonFly onClick={stopCapture} >Clear</ButtonFly>
                        {/* <Button onPress={makePhoto} className={`${styles.smallButton}`}>Make Photo</Button> */}
                        {/* <Button onPress={stopCapture} className={`${styles.smallBorderedButton}`}>Clear</Button> */}
                    </div>
                </div>
            )}

            {previewUrl && (
                <div className={styles.previewContainer}>
                    <img src={previewUrl} alt="Preview" className={styles.previewImage} />
                    <ButtonFly onClick={clearImage} >Clear</ButtonFly>
                    {/* <Button onPress={clearImage} className={`${styles.smallBorderedButton}`}>Clear</Button> */}
                </div>
            )}
        </div>
    );
});

export default FileInput;
