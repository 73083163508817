import styled from 'styled-components';
import React, {memo, useContext, useEffect, useState,} from "react";
import {Card, Text, Button, Spacer, Grid, Loading} from "@nextui-org/react";
import {Handle} from "reactflow";
import FlowContext from "../FlowContext";
import {FaTrash} from 'react-icons/fa';


export const Body = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: none;
    border-radius: 4px;
    font-size: 10pt;
    min-width: fit-content;
    width: fit-content;
    `;

export const ContentWrapper = styled.div`
    padding: 8px 8px;
`;

export const StyledHandle = styled(Handle)`
    background: ${(props) => ((props.optional === true || props.optional === "true") ? 'white' : '#ff4b00')};
    border-radius: 50%;
    border-width: 2px;
    border-color: #ff4b00;
    box-shadow: none;
    top: 50%;
    transform: translateY(-50%);
    z-index: 30;
    width: 10px;
    height: 10px;
`;


export const StyledLeftHandle = styled(StyledHandle)`
    left: -38px;
`;

export const StyledRightHandle = styled(StyledHandle)`
    right: -38px;
`;

export const MinimizedCardWrapper = styled.div`
    position: relative;
    z-index: 1;
    width: fit-content; 
    min-width: 300px; 
    max-width: 100%;
`;

export const MinimizedCardHeader = styled(Card.Header)`
    padding-bottom: 4px;
`;

export const MinimizedCardBody = styled(Card.Body)`
    padding-top: 0;
    padding-bottom: 5px;
    position: relative;
    overflow: visible;
    background-color: transparent;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    width: fit-content;
    min-width: 100%;
`;


export const StyledInput = styled.input`
    position: absolute;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
    display: none;
`;

export const StyledInputLabel = styled(Button)`
    background: linear-gradient(112deg, #06B7DB -63.59%, #FF4ECD -20.3%, #0072F5 70.46%);
    width: 100%;
`;

const CustomCardHeader = styled(Card.Header)`
    padding-bottom: 0;
`;


const DeleteButton = ({id, removeNode, deletable, projectState}) => {
    if (!deletable || !["IDLE", "ERROR"].includes(projectState)) {
        return null;
    }
    return (
        <>
            <Button
                auto
                size={"xs"}
                style={{width: '95%', paddingTop: "15px", paddingBottom: "15px", margin: "auto"}}
                bordered
                color="error"
                onPress={() => {
                    removeNode(id);
                }}
            >
                <FaTrash/>
            </Button>
        </>
    );
}

export const HandleActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end; // Выравниваем элементы по правому краю
  align-items: center; // Выравниваем элементы по вертикальному центру
  width: 100%; // Занимаем всю доступную ширину
  //margin-right: 3px; // Устанавливаем отступ справа
`;


const BaseNode = (props) => {
    const {removeNode, projectState} = useContext(FlowContext);
    const {id, label, type, content, openEditor} = props;
    const [isLoading, setIsLoading] = useState(true);
    const formatNodeTitle = () => {
        if (type === "INPUT" || type === "OUTPUT") {
            return label;
        }
        if (isLoading) {
            return label;
        }
        return `${label}-${id}`;
    };
    useEffect(() => {
        if (isNaN(Number(id))) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [id]);
    return (
        <Card css={{overflow: "visible", $$cardColor: props.data.background_color || "#FFF"}}>
            {isLoading ? (
                <>
                    <CustomCardHeader>
                        <Text h3
                              style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '300px',
                              }}
                        >
                            {formatNodeTitle()}
                        </Text>
                    </CustomCardHeader>
                    <Body>
                        <ContentWrapper style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingBottom: "20px"
                        }}><Loading/></ContentWrapper>
                    </Body>
                </>
            ) : (
                <>
                    <CustomCardHeader>
                        <Text h3
                              style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '300px',
                              }}
                        >
                            {formatNodeTitle()}
                        </Text>
                    </CustomCardHeader>
                    <MinimizedCardBody css={{overflow: "visible"}}>
                        <Body>
                            <ContentWrapper>{content}</ContentWrapper>
                        </Body>
                        <Spacer y={0.3}/>
                        {type === 'CUSTOM_PYTHON' && ["IDLE", "ERROR"].includes(projectState) && (
                            <Grid.Container justify={"center"} style={{width: "96%", margin: "auto"}}>
                                <Grid xs={5}>
                                    <Button auto size={"xs"} color="primary" bordered
                                            style={{
                                                width: '95%',
                                                paddingTop: "15px",
                                                paddingBottom: "15px",
                                                margin: "auto"
                                            }}>
                                        Set up
                                    </Button>
                                </Grid>
                                <Grid xs={5}>
                                    <Button auto size={"xs"} color="secondary" bordered onPress={openEditor}
                                            style={{
                                                width: '95%',
                                                paddingTop: "15px",
                                                paddingBottom: "15px",
                                                margin: "auto"
                                            }}>
                                        Edit code
                                    </Button>
                                </Grid>
                                <Grid xs={2}>
                                    <DeleteButton
                                        bordered
                                        id={id}
                                        removeNode={removeNode}
                                        deletable={props.data.deletable}
                                        projectState={projectState}
                                    />
                                </Grid>
                            </Grid.Container>
                        )}
                        {!['CUSTOM_PYTHON', 'INPUT', 'OUTPUT'].includes(type) && ["IDLE", "ERROR"].includes(projectState) && (
                            <Grid.Container justify={"center"} style={{width: "96%", margin: "auto"}}>
                                <Grid xs={10}>
                                    <Button auto size={"xs"} color="primary" bordered
                                            style={{
                                                width: '95%',
                                                paddingTop: "15px",
                                                paddingBottom: "15px",
                                                margin: "auto"
                                            }}>
                                        Set up
                                    </Button>
                                </Grid>
                                <Grid xs={2}>
                                    <DeleteButton
                                        bordered
                                        id={id}
                                        removeNode={removeNode}
                                        deletable={props.data.deletable}
                                        projectState={projectState}
                                    />
                                </Grid>
                            </Grid.Container>
                        )}
                        <Spacer y={0.4}/>
                    </MinimizedCardBody>
                </>
            )}
        </Card>
    );

};

export const HeadNode = () => {
};


export default memo(BaseNode);

