import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner, Container } from 'react-bootstrap';
import { HeaderSpacer } from '../Header/HeaderSpacer';
import Search from '../menu/Search';
import FastSpeed from '../menu/FastSpeed';
import { CenteredContent } from "../Projects/ProjectMain/ProjectMainPage";
import Categories from './components/Categories/index.tsx';
import ScrollMenu from './components/ScrollMenu/index.tsx';

import { getDemos } from '../../api/demos';
import { renderProjects } from '../../scripts/renderListAI';
import useProjectPagination from '../../hooks/useProjectPagination.ts';
import { TITLE_SPLIT_GROUP } from '../../utils/constants.js';
import { toastError } from '../../utils/toasts.js';

function Explore() {
    const [searchText, setSearchText] = useState('');
    const [isFast, setIsFast] = useState(false);
    const size = { xs: 12, sm: 6, md: 6, lg: 6, xl: 6, }
    const [filterActiveProjects, setFilterActiveProjects] = useState([])
    const [filterNotActiveProjects, setFilterNotActiveProjects] = useState([])

    const {
        activeProjects,
        notActiveProjects,
        error,
        loading,
        lastProjectRef
    } = useProjectPagination(getDemos);

    useEffect(() => {
        setFilterActiveProjects(activeProjects);
        setFilterNotActiveProjects(notActiveProjects);
    }, [activeProjects, notActiveProjects]);


    useEffect(() => {
        const filterDemos = () => {
            let filteredActive = activeProjects;
            let filteredNotActive = notActiveProjects;

            if (searchText) {
                filteredActive = filteredActive.filter(demo =>
                    demo.name.toLowerCase().includes(searchText.toLowerCase())
                );
                filteredNotActive = filteredNotActive.filter(demo =>
                    demo.name.toLowerCase().includes(searchText.toLowerCase())
                );
            }

            if (isFast) {
                filteredActive = filteredActive.filter(demo => demo.is_optimized);
                filteredNotActive = filteredNotActive.filter(demo => demo.is_optimized);
            }

            setFilterActiveProjects(filteredActive);
            setFilterNotActiveProjects(filteredNotActive);
        };

        filterDemos();
    }, [searchText, isFast, activeProjects, notActiveProjects]);

    if (error) {
        toastError(error)
    }

    return (
        <Container fluid="lg" style={{ minHeight: "calc(100vh - 190px)", marginTop: "0px" }}>
            <HeaderSpacer />
            <Row>
                <Col xs={12} md={8} lg={10} className="pb-4">
                    <h2>Explore model</h2>
                </Col>
            </Row>
            <ScrollMenu projects={activeProjects} isLoading={loading} />
            <Row>
                <Col xxl={3} lg={3} md={3}>
                    <Row className='mt-4 pt-1 px-2'>
                        <Col xs={12} xxl={12}>
                            <Search function={setSearchText} />
                        </Col>
                        <Col xs={12} xxl={12}>
                            <FastSpeed
                                state={isFast}
                                change={() => setIsFast(!isFast)}
                            />
                        </Col>
                        <Col xs={12} xxl={12} className='p-0 ms-0 mt-3 mb-3'>
                            <hr />
                        </Col>
                        <Col xs={12} xxl={12}>
                            <Categories />
                        </Col>
                    </Row>
                </Col>
                <Col lg={9} md={9} className="pt-4">
                    {filterActiveProjects.length !== 0 && renderProjects(
                        filterActiveProjects,
                        TITLE_SPLIT_GROUP.ACTIVE,
                        lastProjectRef,
                        size
                    )}
                    {filterNotActiveProjects.length !== 0 && renderProjects(
                        filterNotActiveProjects,
                        TITLE_SPLIT_GROUP.NOT_ACTIVE,
                        lastProjectRef,
                        size
                    )}
                    {loading && (
                        <Row>
                            <Col>
                                <CenteredContent>
                                    <Spinner animation="border" role="status" variant="secondary">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </CenteredContent>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
            <HeaderSpacer />
        </Container>
    );
}

export default Explore;
