import React, {useEffect, useState} from "react";
import {getSavedCards, updateAutoPaymentSettings} from "../../../api/finances";
import {toastSuccess} from "../../../utils/toasts";
import {Checkbox, Loading, StyledButton} from "@nextui-org/react";
import CardDropdown from "./CardDropdown";
import {StyledForm, StyledInput} from "./AddFundsModal";
import {handleDRFErrors} from "./handlers";

const AutoPaymentSettings = ({ user, onSuccess }) => {
    const [autoPaymentEnabled, setAutoPaymentEnabled] = useState(user.auto_payment_enabled || false);
    const [autoPaymentThreshold, setAutoPaymentThreshold] = useState(user.auto_payment_threshold || '');
    const [autoPaymentAmount, setAutoPaymentAmount] = useState(user.auto_payment_amount || '');
    const [autoPaymentCard, setAutoPaymentCard] = useState(user.auto_payment_card || null);
    const [savedCards, setSavedCards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cardsUpdated, setCardsUpdated] = useState(false);

    useEffect(() => {
        const fetchSavedCards = async () => {
            try {
                const cards = await getSavedCards(user.username);
                setSavedCards(cards);
                const defaultCard = cards.find(card => card.is_default);
                if (defaultCard) {
                    setAutoPaymentCard(defaultCard.stripe_payment_method_id);
                } else if (cards.length > 0 && !autoPaymentCard) {
                    setAutoPaymentCard(cards[0].stripe_payment_method_id);
                }
            } catch (error) {
                handleDRFErrors(error);
            }
        };
        fetchSavedCards();
        if (cardsUpdated) {
            setCardsUpdated(false);
        }
    }, [user.username, cardsUpdated]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const parsedThreshold = parseFloat(autoPaymentThreshold);
        const parsedAmount = parseFloat(autoPaymentAmount);

        if (autoPaymentEnabled) {
            if (isNaN(parsedThreshold) || parsedThreshold < 5) {
                handleDRFErrors({ non_field_errors: ["Auto-payment threshold must be at least $5"] });
                return;
            }
            if (isNaN(parsedAmount) || parsedAmount < 5) {
                handleDRFErrors({ non_field_errors: ["Auto-payment amount must be at least $5"] });
                return;
            }
            if (parsedThreshold >= parsedAmount) {
                handleDRFErrors({ non_field_errors: ["Auto-payment threshold must be less than auto-payment amount"] });
                return;
            }
        }

        setLoading(true);
        try {
            const updatedSettings = await updateAutoPaymentSettings(user.username, {
                auto_payment_enabled: autoPaymentEnabled,
                auto_payment_threshold: parsedThreshold,
                auto_payment_amount: parsedAmount,
                default_payment_method_id: autoPaymentCard
            });
            toastSuccess("Auto-payment settings updated successfully");
            setAutoPaymentEnabled(updatedSettings.auto_payment_enabled);
            setAutoPaymentThreshold(updatedSettings.auto_payment_threshold);
            setAutoPaymentAmount(updatedSettings.auto_payment_amount);
            onSuccess(updatedSettings);
        } catch (error) {
            handleDRFErrors(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <StyledForm onSubmit={handleSubmit}>
            <Checkbox
                size="sm"
                isSelected={autoPaymentEnabled}
                onChange={(checked) => setAutoPaymentEnabled(checked)}
            >
                Enable auto-payment
            </Checkbox>
            <StyledInput
                label="Auto-payment threshold ($)"
                type="number"
                value={autoPaymentThreshold}
                onChange={(e) => setAutoPaymentThreshold(e.target.value)}
                disabled={!autoPaymentEnabled}
                placeholder="Enter threshold amount (minimum $5)"
            />
            <StyledInput
                label="Auto-payment amount ($)"
                type="number"
                value={autoPaymentAmount}
                onChange={(e) => setAutoPaymentAmount(e.target.value)}
                disabled={!autoPaymentEnabled}
                placeholder="Enter auto-payment amount (minimum $5)"
            />
            <CardDropdown
                savedCards={savedCards}
                selectedCard={autoPaymentCard}
                onSelectCard={(key) => setAutoPaymentCard(key)}
                disabled={!autoPaymentEnabled}
                allowNewCard={false}
            />
            <StyledButton auto color="primary" type="submit" disabled={loading}>
                {loading ? <Loading type="points-opacity" color="currentColor" size="sm" /> : 'Save Auto-payment Settings'}
            </StyledButton>
        </StyledForm>
    );
};

export default AutoPaymentSettings;