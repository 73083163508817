import React from 'react';
import {Modal, Text, Button, Row} from "@nextui-org/react";

const ApiKeyConfirmationModal = ({ isOpen, onConfirm, onCancel }) => {
    const handleClose = () => {
        onCancel();
        document.body.style.overflow = 'auto';
    };

    const handleConfirm = () => {
        onConfirm();
        onCancel();
        document.body.style.overflow = 'auto';
    };

    return (
        <Modal open={isOpen} onClose={handleClose} width="400px">
            <Modal.Header>
                <Text h3>Confirm API Key Regeneration</Text>
            </Modal.Header>
            <Modal.Body>
                <Text>
                    Regenerating your API Key will invalidate the current key. This action cannot be undone.<br/>Are you sure you want to proceed?
                </Text>
            </Modal.Body>
            <Modal.Footer>
                <Row justify={"space-between"}>
                    <Button auto size={'sm'} flat onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button auto size={'sm'} flat color="success" onClick={handleConfirm}>
                        Regenerate
                    </Button>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

export default ApiKeyConfirmationModal;
