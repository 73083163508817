import React from 'react';
import { Modal, Text, Button } from "@nextui-org/react";

const DeleteConfirmationModal = ({ isOpen, onConfirm, onCancel, projectInfo }) => {
    const handleConfirm = () => {
        onConfirm();
        document.body.style.overflow = 'auto';
    };

    const handleCancel = () => {
        onCancel();
        document.body.style.overflow = 'auto';
    };

    const canDelete = projectInfo.state === 'IDLE' || projectInfo.state === 'ERROR';
    const deleteMessage = canDelete
        ? "Are you sure you want to delete this project?"
        : "Project can only be deleted when it is in IDLE or ERROR state.";

    return (
        <Modal open={isOpen} onClose={onCancel} width="400px">
            <Modal.Header>
                <Text h3>Confirm Delete</Text>
            </Modal.Header>
            <Modal.Body>
                <Text>{deleteMessage}</Text>
            </Modal.Body>
            <Modal.Footer>
                <Button auto size={'sm'} flat color="error" onClick={handleConfirm} disabled={!canDelete}>
                    Delete
                </Button>
                <Button auto size={'sm'} flat color="gray"  onClick={handleCancel}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteConfirmationModal;
