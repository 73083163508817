import { Tooltip as TT, Button } from "@nextui-org/react";

export default function Tooltip({ tooltip, button }) {
    return (
        <TT content={tooltip}>
            <Button auto flat size="xs" style={{ padding: '3px'}}>
                {button}
            </Button>
        </TT>
    );
}
