import React, { useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import styles from './Card.module.css';
import { Link as LK } from 'react-router-dom';

import flashBgGrey from '../Home/images/flashBgGrey.svg';
import lighting from './images/lighting.svg';
import State from "../State/index.tsx";

export default function Card({ props }) {
    const [isloadImage, setIsLoadImage ] = useState(false)
    return (
        <LK to={`/${props.owner}/${props.name}`} className={styles.link}>
            <Container fluid className={styles.interactive} >
                <Row className={styles.main}>
                    <Col className={styles.imageContainer} xl={6}>
                        <Image onLoad={ _ => setIsLoadImage(true)} src={isloadImage ? (props.cover_image ?? flashBgGrey) : flashBgGrey} className={styles.image} />
                        {props.is_optimized &&
                            <div className={styles.lightingContainer}>
                                <Image src={lighting} alt="Fast Speed" />
                            </div>
                        }
                        <div className={styles.runs}>
                            <State titleState={props.state} />
                        </div>
                    </Col>
                    <Col className={styles.desc} xl={6}>
                        <Row>
                            <Col className={styles.title}>
                                {props.name}
                            </Col>
                        </Row>
                        <Row>
                            <Col className={styles.description}>
                                {props.description}
                            </Col>
                        </Row>
                        <Row>
                            <Col className={styles.tags}>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </LK>
    );
}