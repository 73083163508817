import React, { ReactNode } from "react";
import { Link as LK } from "react-router-dom";
import ButtonFly from "../../../../Button";

/**
 * A component that displays an alert indicating missing parameters.
 * It shows the provided children content and a button that navigates to a specified path.
 *
 * @param {boolean} [run=false] - Indicates if the button should have a 'run' type.
 * @param {ButtonProps} button - The properties for the button including path, title, and image.
 * @param {ReactNode} children - The content to display alongside the button.
 *
 * @returns {JSX.Element} The rendered MissingParametersAlert component.
 */

const MissingParametersAlert: React.FC<MissingParametersAlertProps> = ({
    run = false,
    button = { path: "/", title: "Click", image: null },
    children
}) => {
    return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ lineHeight: "56px" }}>
                {children}
            </div>
            <LK to={button.path} style={{ padding: "10px" }}>
                <ButtonFly type={run ? "run" : undefined} image={button.image}>
                    {button.title}
                </ButtonFly>
            </LK>
        </div>
    );
};

export default MissingParametersAlert;

interface ButtonProps {
    path: string;
    title: string;
    image: string | null;
}

interface MissingParametersAlertProps {
    run?: boolean;
    button: ButtonProps;
    children: ReactNode;
}
