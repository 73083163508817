import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./style.module.css";

import Logo from "../Header/images/logo";
import DiscordImage from "./images/discord";
import LinkInImage from "./images/linkIn";
import { LINKS } from "../../utils/constants";


export default function Footer() {
    return (
        <div className={`${styles.main} px-xxl-5 px-sm-2`}>
            <Container fluid="lg">
                <Row className="pt-3" >
                    <Col lg={2} style={{paddingTop: "5px"}}>
                        <Logo />
                    </Col>
                    <Col>
                        <Row >
                            <Col className="d-flex justify-content-lg-end">
                            <div className={styles.text}>
                                © 2024 Viva AI Inc. Manhattan Beach, California
                            </div>
                            </Col>
                            <Col lg={4} xl={3} className="pt-2">
                                <Link to="/privacy" className={styles.linkFooter}>Privacy</Link>
                                <Link to="/terms" className={styles.linkFooter}>Terms of use</Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={1} className="d-flex justify-content-lg-end pt-2">
                        <Link to={LINKS.DISCORD}>
                            <DiscordImage />
                        </Link>
                        <Link to={LINKS.LINKED_IN}>
                            <LinkInImage />
                        </Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}