import React, { useState } from "react";
import { Col, Image } from "react-bootstrap";
import styles from "./index.module.css";

import chevronUp from "./images/chevronUp.svg";
import chevronDown from "./images/chevronDown.svg";

export default function Accordion({ state, func, title, showButton = true }) {
    const showOptionalFields = state
    return (
        <Col onClick={func} className={styles.main} >
            <span className={styles.name}>
                {title}
            </span>
            {showButton &&
                <span className={styles.showSetting}>
                    <Image
                        src={showOptionalFields ? chevronUp : chevronDown}
                        alt="chevron"
                        style={{
                            paddingLeft: "2px",
                            paddingTop: "5px",
                            paddingBottom: "5px"
                        }}
                    />
                </span>
            }
        </Col>
    );
}