import { toastError } from "../../../utils/toasts";

export const handleDRFErrors = (error) => {
    console.error("Full error object:", error); // Для отладки

    let errorData;

    if (error.response && error.response.data) {
        errorData = error.response.data;
    } else if (error.data) {
        errorData = error.data;
    } else if (typeof error === 'object') {
        errorData = error;
    } else {
        toastError("An unexpected error occurred");
        return;
    }

    console.log("Parsed error data:", errorData); // Для отладки

    if (errorData.non_field_errors) {
        errorData.non_field_errors.forEach(errorMessage => {
            toastError(errorMessage);
        });
    } else if (Array.isArray(errorData)) {
        errorData.forEach(errorMessage => {
            toastError(errorMessage);
        });
    } else {
        Object.keys(errorData).forEach(field => {
            const fieldErrors = errorData[field];
            if (Array.isArray(fieldErrors)) {
                fieldErrors.forEach(errorMessage => {
                    toastError(`${field}: ${errorMessage}`);
                });
            } else if (typeof fieldErrors === 'string') {
                toastError(`${field}: ${fieldErrors}`);
            }
        });
    }
};