import React from 'react';
import {Modal, Text, Button} from "@nextui-org/react";
import {checkCeleryTaskStatus} from "../../../../api/projects";
import {playProject} from "../../../../api/flow";
import {toastError, toastSuccess} from "../../../../utils/toasts";


const RunServerModal = ({isOpen, onClose, username, projectInfo, setProjectInfo, requestUser}) => {

    const handleClose = () => {
        onClose();
        document.body.style.overflow = 'auto';
    };

    const handleSubmit = async () => {
        setProjectInfo({...projectInfo, state: 'STARTING'});
        handleClose();
        await playProject(username, projectInfo.name);
    }

    return (
        isOpen ? (
                <Modal
                    open={isOpen}
                    onClose={handleClose}
                >
                    <Modal.Header>
                        <Text h3>Run Server</Text>
                    </Modal.Header>
                    <Modal.Body>
                        {requestUser.wallet <= 0 ? (
                            <Text size={16} color={'error'}>Please top up your balance.</Text>
                        ) : projectInfo.working_mode === 'OFF' ? (
                            <Text size={16} color={'error'}>The project cannot be started in the OFF mode.</Text>
                        ) : ['IDLE', 'ERROR'].includes(projectInfo.state) ? (
                            <Text size={16}>Are you sure you want to run the project?</Text>
                        ) : (
                            <Text size={16}>Project is in {projectInfo.state} state now.</Text>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button auto flat color="error" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            auto
                            onClick={handleSubmit}
                            color="success"
                            disabled={requestUser.wallet === 0 || projectInfo.working_mode === 'OFF' || !['IDLE', 'ERROR'].includes(projectInfo.state)}
                        >
                            Run
                        </Button>


                    </Modal.Footer>
                </Modal>
            ) :
            null
    )
        ;
};

export default RunServerModal;
