import React, {useContext} from 'react';
import {BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath} from 'reactflow';
import FlowContext from "../FlowContext";

export default function CustomEdge({id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = {}, markerEnd, }: EdgeProps) {
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const { edges, projectState, handleEdgeDeletion } = useContext(FlowContext);
    const edge = edges.find((edge) => edge.id === id);

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style}/>
            <EdgeLabelRenderer>
                <div
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        fontSize: 12,
                        pointerEvents: 'all',
                    }}
                    className="nodrag nopan"
                >
                    {
                        (["IDLE", "ERROR"].includes(projectState)) && (
                            <button
                                onClick={() => handleEdgeDeletion(edge)}
                                style={{
                                    padding: '0',
                                    margin: '0',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '50%',
                                    width: '25px',
                                    height: '25px',
                                    fontSize: '14px',
                                    lineHeight: '25px',
                                    textAlign: 'center',
                                    border: 'none',
                                    color: 'white',
                                    backgroundColor: 'lightcoral',
                                    cursor: 'pointer',
                                }}
                            >
                                X
                            </button>
                        )
                    }
                </div>
            </EdgeLabelRenderer>
        </>
    );
}
