
export function findLabelById(accId, availableHardware) {
    const item = availableHardware.find(item => item.id === parseInt(accId));
    return item ? item.label : null;
}

const generateMediaExtension = (mediaType) => {
    if (mediaType.startsWith('image/')) {
        return '.jpg';
    } else if (mediaType.startsWith('audio/')) {
        return '.mp3';
    } else if (mediaType.startsWith('video/')) {
        return '.mp4';
    } else {
        return '';
    }
};


export const generateOutputProcessingCode = (outputModel) => {
    let code = "";

    const generateProcessingByType = (fieldName, propertyInfo) => {
        if (propertyInfo.type === 'file' || (propertyInfo.anyOf && propertyInfo.anyOf.some(item => item.format === 'binary'))) {
            const extension = generateMediaExtension(propertyInfo.contentMediaType);
            return `
    ${fieldName}_encoded = response.output_data["${fieldName}"][0]
    ${fieldName} = base64.b64decode(${fieldName}_encoded)
    with open(f"${fieldName}_output${extension}", "wb") as file:
        file.write(${fieldName})
    print(f"Saved ${fieldName}_output${extension}")
`;
        } else {
            return `    print(f"${fieldName}: {response.output_data['${fieldName}']}")\n`;
        }
    };

    if (outputModel && outputModel.properties) {
        for (const [fieldName, propertyInfo] of Object.entries(outputModel.properties)) {
            code += generateProcessingByType(fieldName, propertyInfo);
        }
    }

    return code.trim();
};


export function generateDictFromSchema(schema) {
    const jsonResult = {};
    const fileFields = [];

    const generateMediaExtension = (mediaType) => {
        if (mediaType.startsWith('image/')) {
            return '.jpg';
        } else if (mediaType.startsWith('audio/')) {
            return '.mp3';
        } else if (mediaType.startsWith('video/')) {
            return '.mp4';
        } else {
            return '';
        }
    };

    const generateValueByType = (propertyInfo) => {
        switch (propertyInfo.type) {
            case 'string':
                return "Hello World";
            case 'integer':
                const minInt = propertyInfo.minimum || 1;
                const maxInt = propertyInfo.maximum || minInt + 1;
                return Math.max(minInt, Math.min(maxInt, minInt + 1));
            case 'number':
                const minFloat = propertyInfo.minimum || 0.1;
                const maxFloat = propertyInfo.maximum || minFloat + 0.1;
                return Math.max(minFloat, Math.min(maxFloat, minFloat + 0.1));
            case 'boolean':
                return propertyInfo.default !== undefined ? propertyInfo.default : true;
            case 'file':
                const contentMediaType = propertyInfo.contentMediaType || 'application/octet-stream';
                return `/path/to/your/file${generateMediaExtension(contentMediaType)}`;
            default:
                return `${propertyInfo.type}`;
        }
    };

    for (const [propertyName, propertyInfo] of Object.entries(schema.properties)) {
        if (propertyInfo.default !== undefined) {
            jsonResult[propertyName] = propertyInfo.default;
        } else {
            jsonResult[propertyName] = generateValueByType(propertyInfo);
        }
        if (propertyInfo.type === 'file') {
            fileFields.push(propertyName);
        }
    }

    // Convert jsonResult to a string, handling boolean values correctly
    let result = JSON.stringify(jsonResult, (key, value) => {
        if (typeof value === 'boolean') {
            return value ? 'True' : 'False';
        }
        return value;
    }, 2);

    // Replace file paths with pathlib.Path() calls, keeping the path in quotes
    fileFields.forEach(field => {
        const regex = new RegExp(`"${field}": "(.*?)"`, 'g');
        result = result.replace(regex, `"${field}": pathlib.Path("$1")`);
    });

    // Remove quotes around True and False
    result = result.replace(/"(True|False)"/g, '$1');

    return result;
}


export const generateStreamProcessingCode = (outputModel) => {
    let streamFields = [];
    let nonStreamFields = [];

    if (outputModel && outputModel.properties) {
        for (const [fieldName, propertyInfo] of Object.entries(outputModel.properties)) {
            if (propertyInfo.isStream && propertyInfo.type === 'string') {
                streamFields.push(fieldName);
            } else {
                nonStreamFields.push(fieldName);
            }
        }
    }

    let code = ``;
    streamFields.forEach(field => {
        code += `        print(response.output_data["${field}"].pop(), end="")
`;
    });
    nonStreamFields.forEach(field => {
        code += `    print(f"${field}: {response.output_data['${field}']}")
`;
    });
    return code.trim();
};


const hasMediaField = (model) => {
    if (model && model.properties) {
        return Object.values(model.properties).some(field =>
            field.type === 'file' ||
            (field.anyOf && field.anyOf.some(item => item.format === 'binary')) ||
            field.contentMediaType
        );
    }
    return false;
};

export const generateImports = (inputModel, outputModel) => {
    const imports = ['from flymyai import client, FlyMyAIPredictException'];

    if (hasMediaField(inputModel)) {
        imports.push('import pathlib');
    }

    if (hasMediaField(outputModel)) {
        imports.push('import base64');
    }

    return imports.join('\n');
};