import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element: Component, user, ...rest }) => {
    const isAuthenticated = user

    return isAuthenticated ? (
        <Component {...rest} />
    ) : (
        <Navigate to="/signup" replace />
    );
};

const mapStateToProps = (state) => ({
    user: state.user.user,
});

export default connect(mapStateToProps)(PrivateRoute);