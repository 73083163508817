import React, { useState } from 'react';
import { Modal, Text, Input, Button } from '@nextui-org/react';
import {getTeam, inviteMember} from '../../../api/users';
import { toastSuccess, toastError } from '../../../utils/toasts';

const InviteMemberModal = ({ isOpen, onClose, teamUsername, setTeamData }) => {
    const [username, setUsername] = useState('');

    const handleInviteMember = async () => {
        if (username.trim() !== '') {
            try {
                await inviteMember(teamUsername, username);
                const team = await getTeam(teamUsername);
                setTeamData(team);
                toastSuccess('Invitation sent successfully.');
                onClose();
                setUsername('');
            } catch (error) {
                toastError('Failed to send invitation. Please try again and make sure the user exists.');
            }
        }
    };

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Modal.Header>
                <Text h3>Invite Member</Text>
            </Modal.Header>
            <Modal.Body>
                <Input
                    aria-label="Invite"
                    fullWidth
                    placeholder="Enter username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button auto flat color="error" onClick={onClose}>
                    Cancel
                </Button>
                <Button auto onClick={handleInviteMember}>
                    Invite
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default InviteMemberModal;