import { Row, Col } from "react-bootstrap";
import SearchImage from "./media/search-image";
import styles from "./style.module.css";

export default function Search(props) {
    const handlerSearchText = (e) => {
        e.preventDefault()
        props.function(e.target.value)
    }
    return (
        <Row className={`${styles.searchContainer} d-flex align-items-center`}>
            <Col>
                <SearchImage />
                <input
                    type="text"
                    placeholder="Search model"
                    className={`${styles.inputField}`}
                    aria-label="Search"
                    onChange={e => handlerSearchText(e)}
                />
            </Col>
        </Row>
    );
}