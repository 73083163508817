import { Button, Table, useAsyncList } from "@nextui-org/react";
import React, { useState } from "react";
import { getHardwareData, getLaunchInferenceInfo } from "../../../../api/projects";
import { getLogs } from "../../../../utils/downloaders";


const HardwareTableModal = ({ username, projectName }) => {
    const [perfModalOpen, setPerfModalOpen] = useState(false);
    const [hardwareId, setHardwareId] = useState(null);
    const [currentAccelerators, setCurrentAccelerators] = useState(null);

    async function load({ cursor }) {
        cursor = cursor || 1;
        const response = await getHardwareData(username, projectName, cursor);
        return {
            items: response.results,
            cursor: response.next ? response.next.split('=')[1] : null,
        };
    }

    const list = useAsyncList({ load });

    const viewPerfReports = async (hwId, accelerators) => {
        setHardwareId(hwId);
        setCurrentAccelerators(accelerators);
        setPerfModalOpen(true);
    }

    const downloadLogs = async (hwId, accelerators) => {
        const liiList = await getLaunchInferenceInfo(username, projectName, hwId, accelerators);
        if (liiList.length > 0) {
            const lii = liiList[0];
            if (lii.has_log) {
                await getLogs(lii.id, username, projectName);
            }
        }
    }

    return (
        <>
            <Table
                aria-label="Hardware Information Table"
                shadow={false}
                css={{ overflowY: "auto", height: "60vh" }}
            >
                <Table.Header >
                    <Table.Column>ID</Table.Column>
                    <Table.Column>Created at</Table.Column>
                    <Table.Column>Finished at</Table.Column>
                    <Table.Column>HW</Table.Column>
                    <Table.Column>Region</Table.Column>
                    <Table.Column>State</Table.Column>
                    <Table.Column>Actions</Table.Column>
                </Table.Header>
                <Table.Body
                    items={list.items}
                    loadingState={list.loadingState}
                    onLoadMore={list.loadMore}
                >
                    {(item) => (
                        <Table.Row key={item.id}>
                            <Table.Cell>{item.id}</Table.Cell>
                            <Table.Cell>{new Date(item.created_at).toLocaleString()}</Table.Cell>
                            <Table.Cell>{item.finished_at ? new Date(item.finished_at).toLocaleString() : 'N/A'}</Table.Cell>
                            <Table.Cell>{item.accelerators}</Table.Cell>
                            <Table.Cell>{item.region}</Table.Cell>
                            <Table.Cell>{item.state}</Table.Cell>
                            <Table.Cell>
                                {item.state === 'TESTING' ? (
                                    <Button
                                        auto
                                        flat
                                        color="primary"
                                        onClick={() => viewPerfReports(item.id, item.accelerators)}
                                        bo
                                    >
                                        View Tests
                                    </Button>
                                ) : item.state === 'STARTING' ? (
                                    <Button
                                        auto
                                        flat
                                        size={"sm"}
                                        color="primary"
                                        onClick={() => downloadLogs(item.id, item.accelerators)}
                                    >
                                        Download
                                    </Button>
                                ) : null}
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        </>
    );
};

export default HardwareTableModal;