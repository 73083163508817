import React, {useEffect, useState} from 'react';
import {Container, Text, Card, Input, Spacer, Grid, Button, Link, Row} from '@nextui-org/react';
import {HeaderSpacer} from '../Header/HeaderSpacer';
import {checkUsername, generateApiKey, getTeam, getUser, updateMe} from '../../api/users';
import {Link as LK, useNavigate} from "react-router-dom";
import {toastError, toastSuccess, toastWarn} from "../../utils/toasts";
import {useDispatch, useSelector} from "react-redux";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {FaCopy} from "react-icons/fa";
import {logout} from "../../api/auth";
import {logoutUser} from "../../utils/auth";
import {RxUpdate} from "react-icons/rx";
import ApiKeyConfirmationModal from "./Modals/ApiKeyConfirmationModal";
import {setActiveUser, updateRequestUser} from "../../store/actions/user";
import TeamMembersTable from "./TeamMembersTable";

const ProfileSettings = () => {
    const [profileData, setProfileData] = useState({});
    const [teamData, setTeamData] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const activeUser = useSelector(state => state.user.activeUser);
    const user = useSelector(state => state.user.user);


    useEffect(() => {
        async function fetchData() {
            try {
                const [fetchedUser, updatedUser] = await Promise.all([
                    getUser(activeUser.username),
                    getUser(user.username)
                ]);

                setProfileData(fetchedUser);
                dispatch(updateRequestUser(updatedUser));

                if (activeUser.is_team) {
                    const team = await getTeam(activeUser.username);
                    setTeamData(team);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        if (activeUser) {
            fetchData();
        }
    }, [activeUser]);

    async function handleLogout() {
        try {
            await logout();
            await logoutUser();
        } catch (error) {
            dispatch({type: 'LOGIN_FAILURE', payload: error.message});
        }
        navigate('/');
    }

    const validateData = async (data) => {
        const errors = {};
        if (!data.username) {
            errors.username = "Username is required.";
        } else if (data.username.length < 4 || data.username.length > 150) {
            errors.username = "Username must be between 4 and 150 characters";
        } else if (!/^[a-zA-Z0-9_]+$/.test(data.username)) {
            errors.username = "Username can contain only letters, numbers, and underscores.";
        } else {
            const checkResponse = await checkUsername(data.username);
            if (!checkResponse.available) {
                errors.username = checkResponse.message;
            }
        }
        if (data.first_name && data.first_name.length > 150) {
            errors.first_name = "First Name must be no more than 150 characters.";
        }
        if (data.last_name && data.last_name.length > 150) {
            errors.last_name = "Last Name must be no more than 150 characters.";
        }
        return errors;
    };

    const handleInputChange = async (e, field) => {
        const updatedValue = {
            ...profileData,
            [field]: e.target.value
        };
        setProfileData(updatedValue);

        const errors = await validateData(updatedValue);
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [field]: errors[field]
        }));
    };

    async function handleGenerateApiKey() {
        try {
            const apiKeyData = await generateApiKey(activeUser.username);
            const updatedUser = {...profileData, api_key: apiKeyData.api_key};
            setProfileData(updatedUser);
            toastSuccess("API Key updated successfully.");
        } catch (error) {
            toastError(`Error generating API key: ${JSON.stringify(error.response)} `, error)
        }
    }

    const handleUpdateUser = async () => {
        const {email, wallet, ...updatableData} = profileData;
        const clientErrors = await validateData(updatableData);

        if (Object.keys(clientErrors).length > 0) {
            setValidationErrors(clientErrors);
            return;
        }

        try {
            const updatedUser = await updateMe(updatableData);
            dispatch(setActiveUser(updatedUser));
            setProfileData(updatedUser);
            setValidationErrors({});
            toastSuccess("Profile updated successfully.");
        } catch (error) {
            if (error && error.response && error.response.data) {
                setValidationErrors(error.response.data);
            } else {
                toastWarn("Error updating profile. Please try again.", `${error}`);
            }
        }
    };

    const isTeam = activeUser?.is_team;

    if (!activeUser) return null;

    return (
        <>
            <Container lg={true} justify={"center"} style={{minHeight: "calc(100vh - 190px)", marginTop: "0px"}}>
                <HeaderSpacer/>
                <Grid.Container gap={2}>
                    <Grid xs={12} sm={6} md={6} lg={6}>
                        <Card>
                            <Card.Body>
                                <div style={{marginLeft: "10px", marginRight: "10px"}}>
                                    <Text h3>{isTeam ? 'Team Profile' : 'Profile Info'}</Text>
                                    <Spacer y={2}/>
                                    <Input
                                        bordered
                                        labelPlaceholder="Email"
                                        width="100%"
                                        value={profileData.email || ""}
                                        readOnly
                                    />
                                    <Spacer y={3}/>
                                    <Input
                                        bordered
                                        labelPlaceholder="Username"
                                        width="100%"
                                        value={profileData.username || ""}
                                        onChange={e => handleInputChange(e, 'username')}
                                        helperText={validationErrors.username}
                                        readOnly
                                    />
                                    <Spacer y={3}/>
                                    {/*{!isTeam && (*/}
                                    {/*    <>*/}
                                    {/*        <Input*/}
                                    {/*            bordered*/}
                                    {/*            labelPlaceholder="First Name"*/}
                                    {/*            width="100%"*/}
                                    {/*            value={profileData.first_name || ""}*/}
                                    {/*            onChange={e => handleInputChange(e, 'first_name')}*/}
                                    {/*            helperText={validationErrors.first_name}*/}
                                    {/*        />*/}
                                    {/*        <Spacer y={3} />*/}
                                    {/*        <Input*/}
                                    {/*            bordered*/}
                                    {/*            labelPlaceholder="Last Name"*/}
                                    {/*            width="100%"*/}
                                    {/*            value={profileData.last_name || ""}*/}
                                    {/*            onChange={e => handleInputChange(e, 'last_name')}*/}
                                    {/*            helperText={validationErrors.last_name}*/}
                                    {/*        />*/}
                                    {/*    </>*/}
                                    {/*)}*/}
                                    {isTeam && teamData && (
                                        <>
                                            <Text h3>Team Members</Text>
                                            <TeamMembersTable requestUser={user} activeUser={activeUser}
                                                              teamData={teamData} setTeamData={setTeamData}/>
                                        </>
                                    )}
                                    <Spacer y={2}/>
                                </div>
                                <Grid.Container alignItems="center" justify="space-between">
                                    {!isTeam && (
                                        <>
                                            <Grid xs={6}>
                                                <Button type="button" onPress={handleLogout} bordered color={'error'}
                                                        auto>
                                                    Logout
                                                </Button>
                                            </Grid>
                                            {/*<Grid xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>*/}
                                            {/*    <Button bordered color={'secondary'} onClick={handleUpdateUser}>*/}
                                            {/*        Save Changes*/}
                                            {/*    </Button>*/}
                                            {/*</Grid>*/}
                                        </>
                                    )}
                                </Grid.Container>
                            </Card.Body>
                        </Card>
                    </Grid>
                    <Grid xs={12} sm={6} md={6} lg={6}>
                        <Card>
                            <Card.Body>
                                <Text h3>Balance</Text>
                                <Row justify={"space-between"}>
                                    <Text h3>${profileData.wallet || 0}</Text>
                                    <Link as={LK} to={`/finances`}>
                                        <Button size={'sm'} auto color="gradient" bordered>
                                            Go to Finances
                                        </Button>
                                    </Link>
                                </Row>
                                <Spacer y={0.5}/>
                                <Text h3>API Key</Text>
                                {
                                    profileData.api_key ? (
                                        <>
                                            <Input
                                                readOnly
                                                value={profileData.api_key}
                                                label="Current API Key"
                                                contentRightStyling={false}
                                                contentRight={
                                                    <Row style={{marginRight: "5px"}}>
                                                        <CopyToClipboard text={profileData.api_key}
                                                                         onCopy={() => toastSuccess('Api Key copied!', profileData.api_key)}>
                                                            <FaCopy style={{cursor: 'pointer', marginRight: '10px'}}/>
                                                        </CopyToClipboard>
                                                        {((activeUser.is_team && activeUser.team_founder === user?.username) || !activeUser.is_team ) ? (
                                                            <RxUpdate style={{ cursor: 'pointer' }} onClick={handleOpenModal} />
                                                        ) : null}

                                                    </Row>
                                                }
                                            />
                                            <Spacer y={0.5}/>
                                        </>
                                    ) : (
                                        <Button color="gradient" onClick={handleGenerateApiKey}>
                                            Create API Key
                                        </Button>
                                    )
                                }
                            </Card.Body>
                        </Card>
                    </Grid>
                </Grid.Container>
            </Container>
            <ApiKeyConfirmationModal isOpen={showModal} activeUser={activeUser} user={user}
                                     onConfirm={handleGenerateApiKey} onCancel={handleCloseModal}/>
        </>
    );
};

export default ProfileSettings;