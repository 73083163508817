import {Button, Card, Grid, Input, Loading, Modal, Row, Spacer, Text} from "@nextui-org/react";
import React, {memo, useCallback, useContext, useRef, useState} from "react";
import BaseNode, {
    MinimizedCardBody,
    MinimizedCardHeader,
    MinimizedCardWrapper,
    StyledLeftHandle,
    StyledRightHandle,
    StyledInput,
    StyledInputLabel
} from "../CustomNodes/BaseNode";
import {useSelector} from "react-redux";
import {Link as LK} from "react-router-dom";
import {createAiFile} from "../../../api/ai";
import Tooltip from "../Tooltips";
import FlowContext from "../FlowContext";
import {getHandleData} from "../utils";
import {compareDims, compareFormats} from "./checks";
import {getNode, updateIOField, updateNode} from "../../../api/flow";
import {toastWarn} from "../../../utils/toasts";
import {prepareNodeForApi} from "../Tree";
import StyledSelect from "../styles/styles";
import {TRAINED_FORMATS} from "../constants";
import {checkCeleryTaskStatus} from "../../../api/projects";




const ContentBody = ({
                         props,
                         data,
                         onFileInputChange,
                         getLoadingState,
                         projectState,
                         nodes,
                         node,
                         nodeId,
                         username,
                         projectName,
                         setExternalNodes
                     }) => {
    const hasAmazonS3Settings = useSelector((state) => state.clouds.has_amazon_s3_settings);
    const fileInputRef = useRef(null);
    let contentBody;
    const {ai_file, source_handles, target_handles} = data;
    const isLoading = getLoadingState(props.id);
    const [selectedHandle] = useState(null);
    const [handleModal, setHandleModal] = useState({visible: false});
    const [perfAnalyzerDims, setPerfAnalyzerDims] = useState("");
    const [handleType] = useState("source");
    const [trainedFormat, setTrainedFormat] = useState(data.trained_format || '');

    const handleTrainedFormatChange = async (event, node) => {
        setTrainedFormat(event.target.value);
        node.data.trained_format = event.target.value
        await updateNode(prepareNodeForApi(node), username, projectName);
    };

    const createIsValidConnection = (handleId, nodeId, handleType) => {
        return (connection) => {
            const newHandlesIds = ['new_source_handle', 'new_target_handle'];
            const {
                startNode,
                startHandle,
                endNode,
                endHandle
            } = getHandleData(nodes, handleId, nodeId, handleType, connection);
            if (startNode.id === endNode.id) {
                toastWarn('It\'s the same node', `sameNode-${startHandle.id}-${endHandle.id}`);
                return false;
            }
            if (newHandlesIds.includes(endHandle.id)) {
                return true;
            }
            if (JSON.stringify(endHandle.dims)) {
                if (!compareDims(startHandle.dims, endHandle.dims)) {
                    toastWarn('Dims do not match', `dimsDoNotMatch-${startHandle.id}-${endHandle.id}`);
                    return false;
                }
            }
            if (endHandle.ai_format && startHandle.ai_format) {
                if (!compareFormats(startHandle.ai_format, endHandle.ai_format)) {
                    toastWarn('Formats do not match', `formatsDoNotMatch-${startHandle.id}-${endHandle.id}`);
                    return false;
                }
            }
            return true;
        }
    }

    const addHandle = async () => {
        if (perfAnalyzerDims) {
            const perfAnalyzerDimsArray = perfAnalyzerDims.split(',')
                .map(dim => dim.trim())
                .map(dim => isNaN(dim) ? dim : parseInt(dim));
            const updatedSelectedHandleData = {
                ...selectedHandle.data,
                perf_analyzer_dims: perfAnalyzerDimsArray
            };

            const newHandle = {
                label: selectedHandle.label,
                data: {
                    ...updatedSelectedHandleData,
                },
            };

            if (selectedHandle) {
                const handleTypeKey = handleType === 'source' ? 'source_handles' : 'target_handles';
                const handleIndex = node.data['target_handles'].findIndex(
                    (handle) => handle.id === selectedHandle.id
                );
                if (handleIndex !== -1) {
                    node.data[handleTypeKey]['target_handles'] = newHandle;
                    await updateIOField(newHandle, selectedHandle.id, node.id, username, projectName);
                }
            }
            setHandleModal({visible: false});
            setPerfAnalyzerDims("");
            const tempNode = await getNode(node.id, username, projectName);
            await setExternalNodes(tempNode, tempNode.id);
        }
    };


    if (ai_file) {
        return (
            <>
                <MinimizedCardWrapper style={{alignItems: 'center', justifyContent: 'center' }}>
                    <Card variant={"flat"} css={{overflow: "visible", $$cardColor: "$colors$accents2"}}>
                        <MinimizedCardBody>
                            <Grid.Container style={{ alignItems: 'center', marginTop: '4px'}} justify="between">
                                <Grid xs={5}><Text>Trained format:</Text></Grid>
                                <Grid xs={7}>
                                    <StyledSelect value={trainedFormat} onChange={(event) => handleTrainedFormatChange(event, props)}>
                                        {TRAINED_FORMATS.map(type => <option key={type.value} value={type.value}>{type.label}</option>)}
                                    </StyledSelect>
                                </Grid>
                            </Grid.Container>
                        </MinimizedCardBody>
                    </Card>
                </MinimizedCardWrapper>
                <Spacer y={0.5}/>
                <MinimizedCardWrapper>
                    <Card variant={"flat"} css={{overflow: "visible", $$cardColor: "$colors$accents2"}}>
                        <MinimizedCardHeader>Inputs</MinimizedCardHeader>
                        {target_handles &&
                            target_handles.map((target) => (
                                <MinimizedCardBody key={target.id}>
                                    <Grid.Container justify="between">
                                        <Grid xs={6}>{target.label}</Grid>
                                        <Grid xs={6}>
                                            <Grid.Container justify="end">
                                                <Grid xs={3} style={{padding: 0, marginRight: '3px'}}>
                                                    {/*<Button auto size="xs" style={{padding: '3px'}}*/}
                                                    {/*        onClick={() => {*/}
                                                    {/*            setSelectedHandle(target);*/}
                                                    {/*            openModal(target, "target");*/}
                                                    {/*        }}>*/}
                                                    {/*    edit*/}
                                                    {/*</Button>*/}
                                                </Grid>
                                                <Grid xs={4}>
                                                    <Tooltip tooltip={target.dims.join(',')} button='dims'></Tooltip>
                                                </Grid>
                                                <Grid xs={4}>
                                                    <Tooltip tooltip={target.ai_format} button='format'></Tooltip>
                                                </Grid>

                                            </Grid.Container>
                                        </Grid>
                                    </Grid.Container>
                                    <StyledLeftHandle
                                        id={`${target.id.toString()}`}
                                        type="target"
                                        position="left"
                                        title="Input"
                                        isValidConnection={createIsValidConnection(target.id, nodeId, 'target_handles')}
                                    />
                                </MinimizedCardBody>
                            ))}
                    </Card>
                </MinimizedCardWrapper>
                <Spacer y={0.5}/>
                <MinimizedCardWrapper>
                    <Card variant={"flat"} css={{overflow: "visible", $$cardColor: "$colors$accents2"}}>
                        <MinimizedCardHeader>Outputs</MinimizedCardHeader>
                        {source_handles &&
                            source_handles.map((source) => (
                                <MinimizedCardBody key={source.id}>
                                    <Grid.Container justify="between">
                                        <Grid xs={6}>{source.label}</Grid>
                                        <Grid xs={6}>
                                            <Grid.Container justify="end">
                                                <Grid xs={4}>
                                                    <Tooltip tooltip={source.dims.join(',')} button='dims'></Tooltip>
                                                </Grid>
                                                <Grid xs={4}>
                                                    <Tooltip tooltip={source.ai_format} button='format'></Tooltip>
                                                </Grid>
                                            </Grid.Container>
                                        </Grid>
                                    </Grid.Container>
                                    <StyledRightHandle
                                        id={`${source.id.toString()}`}
                                        type="source"
                                        position="right"
                                        isValidConnection={createIsValidConnection(source.id, nodeId, 'source_handles')}
                                    />
                                </MinimizedCardBody>
                            ))}
                    </Card>
                </MinimizedCardWrapper>
                <Spacer y={0.2}/>
                <Modal open={handleModal.visible} onClose={() => setHandleModal({visible: false})}>
                    <Modal.Header>
                        <Text size={18}>Edit Handle</Text>
                    </Modal.Header>
                    <Modal.Body>
                        <Input
                            label="Perf-analyzer dims"
                            placeholder="Enter dims"
                            value={perfAnalyzerDims}
                            onChange={(e) => {
                                setPerfAnalyzerDims(e.target.value);
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button auto flat color="success" onClick={addHandle}>
                            Save
                        </Button>
                        <Button auto flat color="error" onClick={() => setHandleModal({visible: false})}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    } else if (!["IDLE", "ERROR"].includes(projectState)) {
        contentBody = (
            <>
                <Row justify={'center'}>
                    <Text>Project is locked now.</Text>
                </Row>
            </>
        );
    } else if (!hasAmazonS3Settings) {
        contentBody = (
            <>
                <Row justify={'center'}>
                    <Text p>Please, provide Amazon S3 settings to upload files.</Text>
                </Row>
                <Spacer y={0.2}/>
                <Row justify={'center'}>
                    <Button color="gradient" as={LK} to="/profile">
                        Cloud Settings
                    </Button>
                </Row>
            </>
        );
    } else if (isLoading) {
        contentBody = (
            <>
                <Row justify={'center'}>
                    <Text h4>Uploading file...</Text>
                </Row>
                <Spacer y={0.2}/>
                <Row justify={'center'} css={{paddingBottom: "15px"}}>
                    <Loading type="points"/>
                </Row>
            </>
        );
    } else {
        contentBody = (
            <>
                <StyledInput
                    ref={fileInputRef}
                    id="file-input"
                    type="file"
                    onChange={(event) => onFileInputChange(props, event)}
                />
                <StyledInputLabel
                    htmlFor="file-input"
                    onClick={() => fileInputRef.current.click()}
                >
                    Choose file
                </StyledInputLabel>
            </>
        );
    }


    return contentBody;
};


const AINode = ({username, projectName, nodeId, setExternalNodes, ...props}) => {
    const {projectState, nodes, edges, loadingStates, setLoadingStates} = useContext(FlowContext);
    const getLoadingState = (nodeId) => {
        return loadingStates[nodeId] || false;
    };

    const handleFileInputChange = useCallback(async (nodeProps, event) => {
        setLoadingStates((prevLoadingStates) => {
            return {...prevLoadingStates, [nodeProps.id]: true};
        });
        const selectedFile = event.target.files[0];
        const fileName = selectedFile.name;
        const fileData = new FormData();
        fileData.append("file", selectedFile);
        fileData.append("name", fileName);
        fileData.append("node_id", nodeProps.id);
        const updatingNode = await createAiFile(fileData);

        let status = 'PENDING';
        while (status === 'PENDING') {
            const response = await checkCeleryTaskStatus(updatingNode.task_id);
            status = response.status;
            if (status === 'PENDING') {
                await new Promise((resolve) => setTimeout(resolve, 500));
            }
        }
        if (status === 'ERROR') {
            return;
        }
        setLoadingStates((prevLoadingStates) => {
            const newLoadingStates = {...prevLoadingStates};
            delete newLoadingStates[nodeProps.id];
            return newLoadingStates;
        });

        // await setExternalNodes(updatingNode);\
        const tempNode = await getNode(updatingNode.node_id, username, projectName);

        await setExternalNodes(tempNode, nodeId);
    }, [setLoadingStates, setExternalNodes, nodeId, username, projectName]);


    return (
        <BaseNode
            id={props.id}
            label={props.data.label}
            data={props.data}
            content={
                <ContentBody
                    props={props}
                    data={props.data}
                    onFileInputChange={handleFileInputChange}
                    getLoadingState={getLoadingState}
                    projectState={projectState}
                    nodes={nodes}
                    edges={edges}
                    nodeId={props.id}
                    node={props}
                    username={username}
                    projectName={projectName}
                    setExternalNodes={setExternalNodes}
                />
            }
        />
    );
}

export default memo(AINode);