import {getStartupLogs} from "../api/projects";

export async function getLogs(launchId, username, projectName) {
    const response = await getStartupLogs(username, projectName, launchId);
    const downloadUrl = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `launch#${launchId}_proj#${projectName}.txt`);
    document.body.appendChild(link);
    link.click();
    link.remove();
}

