import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.css';
import Slider from '../../../../../utils/slider';
import ButtonFly from '../../../../Button';

import { LuRefreshCw } from "react-icons/lu";


const InputInt = ({ formData, formErrors, name, isLoading, isInstantGeneratingEnabled, type, detail, handleChange }) => {
    const [currentValue, setCurrentValue] = useState(0);
    const [isInitialized, setIsInitialized] = useState(false);
    const inputRef = useRef(null);
    const stepValue = type === 'number' ? 0.1 : (type === 'integer' ? 1 : 0);
    const minimum = detail.minimum !== undefined ? detail.minimum : 0;
    const maximum = detail.maximum !== undefined ? detail.maximum : 0;
    // const minimum = -500;
    // const maximum = -2500;
    const showSlider = minimum < maximum
    const showRandomButton = Math.abs(maximum - minimum) > 999

    // console.log(name, ":", formData[name], "min", detail.minimum, "max", detail.maximum)

    useEffect(() => {
        if (formData[name] !== undefined && !isInitialized) {
            if (formData[name] === "") {
                setCurrentValue(0)
            } else {
                setCurrentValue(parseFloat(formData[name]));
            }
            setIsInitialized(true);
        }
    }, [formData, name, isInitialized]);


    useEffect(() => {
        const inputElement = inputRef.current;
        if (inputElement) {
            const handleWheel = (e) => {
                e.preventDefault();
                e.stopPropagation();
            };

            inputElement.addEventListener('wheel', handleWheel, { passive: false });

            return () => {
                inputElement.removeEventListener('wheel', handleWheel);
            };
        }
    }, []);

    const getRandomNumber = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);

        return Math.floor(Math.random() * (max - min + 1)) + min;
    };


    const handleRandomValue = () => {
        const randomValue = getRandomNumber(minimum, maximum);
        setCurrentValue(randomValue);
        handleChange(name, randomValue, type, detail);
    }

    const handleSliderChange = (value) => {
        const clampedValue = Math.max(detail.minimum, Math.min(detail.maximum, value));
        setCurrentValue(clampedValue)
        handleChange(name, currentValue, type, detail);
    };

    const handleInputChange = (e) => {
        const value = parseFloat(e.target.value);
        setCurrentValue(value)
        handleChange(name, value, type, detail);
    };

    return (
        <>
            {showSlider && !showRandomButton &&
                <Slider min={minimum}
                    max={maximum}
                    values={currentValue}
                    onChange={handleSliderChange}
                    disabled={isLoading && !isInstantGeneratingEnabled}
                    step={stepValue}
                />}
            <span className={
                showSlider && !showRandomButton ?
                    styles.inputContainer :
                    styles.inputContainerWithoutSliders}>
                <input type="number"
                    value={currentValue}
                    ref={inputRef}
                    disabled={isLoading && !isInstantGeneratingEnabled}
                    onChange={handleInputChange}
                    className={showSlider && !showRandomButton ? styles.input : styles.inputWithoutSliders}
                    step={stepValue}
                />
                {showRandomButton &&
                    <ButtonFly
                        onClick={handleRandomValue}
                        style={{ height: "44px", padding: "0 10px 0 10px", width: "44px", fontSize: "20px" }}
                    >
                        <LuRefreshCw style={{ color: "gray", marginBottom: "5px" }} />
                    </ButtonFly>
                }
                {formErrors[name] && (
                    <div className={
                        showRandomButton || (!showRandomButton && !showSlider)
                            ? styles.errorContainerNonSlider
                            : styles.errorContainer
                    }
                    >
                        <span className={styles.errorText}>
                            {formErrors[name]}
                        </span>
                    </div>
                )}
            </span>
        </>
    );
};

export default InputInt;
