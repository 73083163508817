import { Image } from "react-bootstrap";

import styles from "./index.module.css";

export default function ButtonFly({ onClick = null, type = "reset", image, style={}, children }) {
    return (
        <button
            className={type === "run" ? styles.smallButton : styles.smallBorderedButton}
            style={style}
            type="button"
            onClick={onClick}
        >
            {children}{image ? (<Image src={image} alt="lightingButton" />) : ""}
        </button>

    );
}