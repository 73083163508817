import styled from 'styled-components';
import {Modal, Text, Input} from "@nextui-org/react";
import { CardElement } from '@stripe/react-stripe-js';
import { getUser } from "../../../api/users";
import { FaStripe, FaCreditCard,} from "react-icons/fa6";
import {FaCog} from "react-icons/fa";
import AutoPaymentSettings from "./AutoPaymentSettings";
import PaymentForm from "./PaymentForm";
import {useState} from "react";

export const StyledModal = styled(Modal)`
    .nextui-modal-body {
        padding: 0;
    }
`;

export const ModalSection = styled.div`
    flex: 1;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
`;

export const SectionTitle = styled.h2`
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #0070f3;

    svg {
        margin-right: 0.5rem;
    }
`;

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const StyledCardElement = styled(CardElement)`
    padding: 0.75rem;
    border: 1px solid #e0e0e0;
    border-radius: 0.5rem;
    background-color: #f7f7f7;
`;


export const StyledInput = styled(Input)`
    background-color: #f7f7f7;
    border-radius: 0.5rem;

    .nextui-input-content--left {
        height: 100%;
    }
`;

const PoweredBy = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5rem;
    font-size: 0.75rem;
    color: #666;

    svg {
        margin-left: 0.25rem;
    }
`;

export const ModalContent = styled.div`
    display: flex;
    height: 100%;
    cursor: default;
`;


const AddFundsModal = ({ isOpen, onClose, user, setUser }) => {
    const [cardsUpdated, setCardsUpdated] = useState(false);

    const handleSuccess = async () => {
        const updatedUser = await getUser(user.username);
        setUser(updatedUser);
        setCardsUpdated(true);
        onClose();
    };

    return (
        <StyledModal open={isOpen} onClose={onClose} width="800px">
            <Modal.Header>
                <Text h3>Add Funds & Auto-payment Settings</Text>
            </Modal.Header>
            <Modal.Body>
                <ModalContent>
                    <ModalSection>
                        <SectionTitle><FaCreditCard /> Add Funds</SectionTitle>
                        <PaymentForm
                            user={user}
                            onSuccess={handleSuccess}
                            setCardsUpdated={setCardsUpdated}
                        />
                    </ModalSection>
                    <ModalSection>
                        <SectionTitle><FaCog /> Auto-payment Settings</SectionTitle>
                        <AutoPaymentSettings
                            user={user}
                            onSuccess={handleSuccess}
                            cardsUpdated={cardsUpdated}
                            setCardsUpdated={setCardsUpdated}
                        />
                    </ModalSection>
                </ModalContent>
            </Modal.Body>
            <Modal.Footer>
                <PoweredBy>
                    Powered by <FaStripe style={{ fontSize: "40px" }} />
                </PoweredBy>
            </Modal.Footer>
        </StyledModal>
    );
};

export default AddFundsModal;