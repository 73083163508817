import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from "../../api/users";
import { setActiveUser, updateRequestUser } from "../../store/actions/user";
import NavBar from './NavBar/index.tsx';

function Header() {
    const activeUser = useSelector(state => state.user.activeUser);
    const user = useSelector(state => state.user.user);
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            try {
                if (user) {
                    const originalUser = await getUser(user.username);
                    if (originalUser) {
                        dispatch(updateRequestUser(originalUser));
                    }
                    if (!activeUser) {
                        dispatch(setActiveUser(originalUser));
                    }
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        }

        fetchData();
    }, [activeUser]);

    return (
        <header>
            <NavBar/>
        </header>
    );
}

export default Header;