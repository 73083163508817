

export function compareDims(startDims, endDims) {
    if (startDims?.length !== endDims?.length) {
        return false;
    }
    return startDims.every((value, index) => {
        if (typeof value === 'string' || typeof endDims[index] === 'string') {
            return true;
        }
        return value === endDims[index];
    });
}

export function compareFormats(startFormat, endFormat) {
    return JSON.stringify(startFormat) === JSON.stringify(endFormat)
}
