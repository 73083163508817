import React, { useEffect, useState, useRef, useCallback } from "react";
import { Col, Container, Image, Row, Spinner } from "react-bootstrap";
import { getTransactions } from "../../../api/finances";
import { Item } from "./type";
import SimpleBar from 'simplebar-react';

import 'simplebar-react/dist/simplebar.min.css';
import styles from "./index.module.css";
import stylesFinance from "../Finances.module.css";


export default function TransactionsTableResult({ user }) {
    const [data, setData] = useState<Item[]>([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef<IntersectionObserver | null>(null);

    const columns = [
        { name: "Amount", uid: "amount" },
        { name: "Date", uid: "timestamp" },
        { name: "Description", uid: "description" },
        { name: "Transaction Type", uid: "transaction_type" },
    ];

    const fetchData = async (page: number) => {
        setLoading(true)
        try {
            const response = await getTransactions(user.username, page);
            setData(prevData => [...prevData, ...response.results]);
            setHasMore(response.results.length > 0);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoading(false)
    };

    useEffect(() => {
        fetchData(page);
    }, [page, user]);

    const lastElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPage(prevPage => prevPage + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [hasMore]);

    return (
        <Container fluid="lg">
            <Row className={styles.container}>
                <Col>
                    <table className={styles.table}>
                        <caption className={styles.caption}>
                            <div className={stylesFinance.bigText} style={{ paddingBottom: "24px" }}>Transactions</div>
                        </caption>
                        <thead className={styles.thead}>
                            <tr>
                                {columns.map(({ name, uid }) => (
                                    <th key={uid} className={`${styles.thtd} `} scope="col">{name}</th>
                                ))}
                            </tr>
                        </thead>
                    </table>
                    <SimpleBar style={{ maxHeight: 505 }} autoHide={false}>
                        <table className={styles.table}>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index} className={styles.in} ref={index === data.length - 1 ? lastElementRef : null}>
                                        <td>{item.amount}</td>
                                        <td>{new Date(item.timestamp).toLocaleDateString('en-US')}</td>
                                        <td>{item.description}</td>
                                        <td>{item.transaction_type}</td>
                                    </tr>
                                ))}
                                {loading &&
                                    <tr style={{ width: "100%" }}>
                                        <td></td>
                                        <td style={{ textAlign: "center", height: "56px" }}>
                                            <Spinner />
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </SimpleBar>
                </Col>
            </Row>
        </Container>
    );
}

function Status({ value }) {
    const statusValue = {
        "Succeeded": { title: "Succeeded", style: { backgroundColor: "#DAFBE1", color: "#059D5B" } },
        "Requires Payment Method": { title: "In progress", style: { backgroundColor: "#E1FBFB", color: "#0F7583" } },
        "Error": { title: "Error", style: { backgroundColor: "#FFE0E0", color: "#B32632" } },
    }
    return (
        <span className={styles.status} style={statusValue[value]?.style}>{statusValue[value]?.title || 'Error'}</span>
    );
}
