import { toast } from 'react-toastify';

const baseToastConfig = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    zIndex: 9999,
};

export const toastWarn = (message, toastId) => {
    toast.warn(message, {
        ...baseToastConfig,
        toastId: toastId,
    });
};

export const toastError = (message, toastId) => {
    toast.error(message, {
        ...baseToastConfig,
        toastId: toastId,
    });
}

export const toastSuccess = (message, toastId) => {
    toast.success(message, {
        ...baseToastConfig,
        toastId: toastId,
    });
}
