import axios from 'axios';
import {store} from '../store/store';
import {logoutUser, refreshUserToken} from "../utils/auth";
import {toastError} from "../utils/toasts";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const gatewayUrl = process.env.REACT_APP_GATEWAY_URL;
const BASE_URL = `${backendUrl}/api/v1/`
export const GATEWAY_URL = `${gatewayUrl}/api/v1/`

export function getBaseUrl() {
    return BASE_URL;
}

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const axiosGatewayInstance = axios.create({
    baseURL: GATEWAY_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use((config) => {
    const { accessToken } = store.getState().auth || {};
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
});


axiosInstance.interceptors.response.use(
    (response) => response.data,
    async (error) => {
        const { response } = error;
        if (response) {
            if (response.status === 401) {
                if (response.data.code === 'token_not_valid') {
                    const { refreshToken } = store.getState().auth || {};
                    if (refreshToken) {
                        await refreshUserToken(refreshToken);
                        return axiosInstance(error.config);
                    }
                } else {
                    await logoutUser();
                }
                return Promise.reject('An unexpected error occurred');
            }
            if (response.status === 403) {
                return Promise.reject(error);
            }
            if (response.status >= 400 && response.status < 500) {
                return Promise.reject(response.data);
            }
            if (response.status >= 500){
                toastError('An unexpected error occurred', 'unexpected-error');
                return Promise.reject('An unexpected error occurred');
            }


        } else if (error.request) {
            toastError('Network Error', 'network-error');
            return Promise.reject("Network Error");

        } else {
            toastError('An unexpected error occurred', 'unexpected-error');
            return Promise.reject('An unexpected error occurred');
        }
        const errorData = response?.data || {};
        const errorMessage =
            errorData.non_field_errors?.[0]  || errorData.message || error.statusText;
        const errorId = response ? `${response.status}-${errorMessage}` : 'unknown-error';
        toastError(errorMessage, errorId);
        return Promise.reject(errorMessage);
    }
);




export default axiosInstance;