import React from "react";
import { Row, Col } from "react-bootstrap";
import MenuItem from "./MenuItem/index.tsx";

import styles from "./index.module.css";

export default function Categories() {
    const category = [
        "Text / LLMs",
        "Media",
        "LoRAs",
        "ComfyUI",
        "Others",
    ]

    return (
        <>
            <Row>
                <Col style={{ paddingLeft: "0px" }}>
                    <div className={styles.title}>
                        Category
                    </div>
                </Col>
            </Row>
            <Row>
                <Col style={{ paddingLeft: "0px" }}>
                    {category.map((item, index) => (
                        <MenuItem
                            key={index}
                            item={item}
                            count={
                                Math.round(Math.random() * (100 - 1) + 1)
                            }
                        />
                    ))}
                </Col>
            </Row>
        </>
    )
}