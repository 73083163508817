import styled from "styled-components";

const StyledSelect = styled.select`
    width: 100%;
    height: 34px;
    padding: 0 12px;
    border-radius: 10px;
    border: 2px solid #d9d9d9;
    background-color: #fff;
    color: #333;
    font-size: 14px;
    appearance: none;
    transition: border-color 0.2s ease-in-out;

    &:hover {
        border-color: #222222;
    }

    &:focus {
        border-color: #222222;
        outline: none;
    }

    & option {
        background-color: #fff;
        color: #333;
    }
`;


export default StyledSelect;

export function getStateColor(state) {
    if (!state) {
        return '#889096';
    }
    let color;
    const upperState = state.toUpperCase();

    if (upperState === 'RUNNING') {
        color = '#17C964';
    } else if (upperState === 'IDLE') {
        color = '#7e8dd7';
    } else if (upperState === 'ERROR') {
        color = '#F31260';
    } else if (upperState === 'STARTING') {
        color = '#F5A524';
    } else if (upperState === 'TESTING') {
        color = '#F5A524';
    } else if (upperState === 'STOPPING') {
        color = '#889096';
    } else {
        color = '#889096';
    }
    return color;
}

