import { Container, Spacer, Text, Link } from "@nextui-org/react";
import { HeaderSpacer } from "../components/Header/HeaderSpacer";
import React from "react";
import { CenteredContent } from "../components/Projects/ProjectMain/ProjectMainPage";

function Welcome() {
    return (
        <Container lg={true} justify={"center"} style={{ minHeight: "calc(100vh - 190px)", marginTop: "0px" }}>
            <HeaderSpacer />
            <Spacer y={2} />
            <CenteredContent>
                <div>
                    <Text h1 weight="bold">
                        Thank you for registration!
                    </Text>
                    <Spacer y={2} />
                    <Text size={22}>Public access to the platform will be available soon!</Text>
                    <Spacer y={1} />
                    <Text size={22}>
                        Ping us on <Link href="https://discord.gg/t6hPBpSebw">Discord</Link> if you want to get access now.
                    </Text>
                </div>
            </CenteredContent>
        </Container>
    );
}

export default Welcome;
