import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import ReactFlow, {ReactFlowProvider, useEdgesState, useNodesState,} from 'reactflow';
import 'reactflow/dist/style.css';
import Sidebar from './Sidebar';
import './styles/index.css';
import {
    parseGraphToInitialData,
    parseNodeFromTree,
    prepareEdgeForApi,
    prepareFieldForApi,
    prepareNodeForApi,
} from "./Tree";
import {
    createEdge,
    createIOField,
    createNode,
    deleteEdge,
    deleteIOField,
    deleteNode,
    getFlowTree,
    getNode,
    playProject,
    stopProject,
    testProject,
    updateNode
} from "../../api/flow";
import FlowContext from "./FlowContext";
import {getFieldIsTarget, getNewHandleID, getNodeIdToConnect, isNewHandle} from "./utils";
import CustomNodeTypes from "./CustomNodes/CustomNodeTypes";
import CustomEdge from './CustomNodes/CustomEdge';
import {Link, Spacer, Text} from "@nextui-org/react";
import {getSpecificModelsList} from "../../api/ai";
import {checkCeleryTaskStatus} from "../../api/projects";
import {Link as LK} from 'react-router-dom';
import {FaArrowLeft} from 'react-icons/fa';


const Flow = (props) => {
    const {username, projectName, isDemo} = props;
    const reactFlowWrapper = useRef(null);
    const [nodes, setNodes] = useNodesState([]);
    const [edges, setEdges] = useEdgesState([]);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [projectState, setProjectState] = useState(null);
    const [project, setProject] = useState(false);
    const [loadingStates, setLoadingStates] = useState({});
    const [specificModels, setSpecificModels] = useState([]);
    const previousLengthNodes = useRef(nodes.length);
    const isTreeLoading = useRef(false);
    const isSpecificModelsLoading = useRef(false);


    useEffect(() => {
        if (reactFlowInstance) {
            reactFlowInstance.fitView();
        }

    }, [reactFlowInstance]);

    useEffect(() => {
        const fetchSpecificModelsList = async () => {
            if (isSpecificModelsLoading.current) {
                return;
            }
            isSpecificModelsLoading.current = true;
            const list = await getSpecificModelsList();
            setSpecificModels(list);
        };
        fetchSpecificModelsList();
    }, [setSpecificModels]);

    const loadTreeFromBackend = useCallback(async () => {
        try {
            const flow = await getFlowTree(username, projectName);
            const {initialNodes, initialEdges, projectState, project} = parseGraphToInitialData(flow);
            const projectDisplayingState = isDemo ? 'DEMO' : projectState;
            setNodes(initialNodes);
            setEdges(initialEdges);
            setProjectState(projectDisplayingState);
            setProject(project);
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }, [username, projectName, setEdges, setNodes]);

    const setExternalNodes = useCallback(async (node) => {
        try {
            const newNode = parseNodeFromTree(node);

            setNodes((prevNodes) => {
                const indexToReplace = prevNodes.findIndex((existingNode) => existingNode.id === newNode.id);

                if (indexToReplace !== -1) {
                    return prevNodes.map((existingNode, index) => (index === indexToReplace ? newNode : existingNode));
                } else {
                    return [...prevNodes, newNode];
                }
            });
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }, [setNodes]);

    useEffect(() => {
        const fetchData = async () => {
            if (isTreeLoading.current) {
                return;
            }
            isTreeLoading.current = true;
            await loadTreeFromBackend();
        };
        fetchData();
    }, [loadTreeFromBackend]);

    const nodeTypes = CustomNodeTypes({username, projectName, setExternalNodes, projectState})
    const edgeTypes = useMemo(() => ({
        custom: CustomEdge,
    }), []);


    const handleSkyButtonClick = async (action) => {
        try {
            let taskResponse;
            if (action === 'play') {
                taskResponse = await playProject(username, projectName);
            } else if (action === 'stop') {
                taskResponse = await stopProject(username, projectName);
            } else if (action === 'test') {
                taskResponse = await testProject(username, projectName, false);
            } else if (action === 'test_tensorrt') {
                taskResponse = await testProject(username, projectName, true);
            } else {
                return;
            }
            await loadTreeFromBackend();

            let status = 'PENDING';
            while (status === 'PENDING') {
                const task = await checkCeleryTaskStatus(taskResponse.task_id);
                status = task.status;
                if (status === 'PENDING') {
                    await new Promise((resolve) => setTimeout(resolve, 3000));
                } else if (["SUCCESS", "ERROR"].includes(status)) {
                    return await loadTreeFromBackend();
                }
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const removeNode = async (nodeId) => {
        try {
            const connectedEdges = edges.filter(
                (edge) => edge.source === nodeId || edge.target === nodeId
            );
            const newEdges = edges.filter(edge => !connectedEdges.includes(edge));
            setEdges(newEdges);

            setNodes((currentNodes) => currentNodes.filter((node) => node.id !== nodeId));

            const asyncTasks = connectedEdges.map(async (edge) => {
                const sourceNode = nodes.find((node) => node.id === edge.source);
                const targetNode = nodes.find((node) => node.id === edge.target);
                if (edge) {
                    await deleteEdge(edge.id, username, projectName);
                }

                if (sourceNode.type === 'INPUT') {
                    await deleteIOField(edge.sourceHandle, sourceNode.id, username, projectName, 'input');
                }
                if (targetNode.type === 'OUTPUT') {
                    await deleteIOField(edge.targetHandle, targetNode.id, username, projectName, 'output');
                }

                const newNode = await getNode(targetNode.id, username, projectName);
                replaceNodeArray(targetNode.id, newNode);

                if (sourceNode.id !== targetNode.id) {
                    const newNode = await getNode(sourceNode.id, username, projectName);
                    replaceNodeArray(sourceNode.id, newNode);
                }
            });

            await Promise.all(asyncTasks);

            await deleteNode(nodeId, username, projectName);
        } catch (error) {
            console.error("An error occurred while removing the node:", error);
        }
    };

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback(
        async (event) => {
            event.preventDefault();
            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            let type = event.dataTransfer.getData('application/reactflow');
            const special_model_name = type

            if (typeof type === 'undefined' || !type) {
                return;
            }
            // SPECIAL MODEL ADDING
            if (["SDXL-1-0", "SDXL-Turbo", "SD-1-5", "SD-Cascade", "SVD-1-XT", "SDXL-Turbo-Accelerated", "SDXL-1-0-Accelerated", "SDXL-1-0-Lightning-Accelerated", "SDXL-Turbo-FMAAccelerated", "SDXL-1-0-FMAAccelerated", "SDXL-Turbo-Dreamshaper-v2"].includes(special_model_name)) {
                type = "SD"
            }
            const position = reactFlowInstance.screenToFlowPosition({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });

            let tempId = (Math.random() + 1).toString(36).substring(2);
            const newNode = {
                id: tempId,
                type: type,
                position: position,
                special_model_name: special_model_name,
                draggable: false,
                data: {
                    label: String(type),
                    weight: null,
                    source_handles: [],
                    target_handles: [],
                    deletable: true,
                    draggable: true,
                    background_color: "#FFFFFF"
                },
            };
            setNodes((nodes) => [...nodes, newNode]);

        },
        [reactFlowInstance, setNodes]
    );

    const replaceNodeArray = useCallback((targetId, newNode) => {
        try {
            setNodes(nds => {
                return nds.map(item => {
                    if (item.id === targetId) {
                        return parseNodeFromTree(newNode);
                    }
                    return item;
                });
            });
        } catch (error) {
            console.error("An error occurred while replacing the node in the array:", error);
        }
    }, [setNodes]);

    useEffect(() => {
        const updateLastElement = async () => {
            try {
                if (nodes.length > previousLengthNodes.current && previousLengthNodes.current !== 0) {
                    const lastElement = nodes[nodes.length - 1];
                    let label = lastElement.data.label
                    if (lastElement.special_model_name) {
                        label = lastElement.special_model_name
                    }
                    const newNodeConfig = {
                        type: String(lastElement.type),
                        label: label,
                        position: lastElement.position,
                        special_model_name: lastElement.special_model_name,
                    };
                    let data = await createNode(newNodeConfig, username, projectName);
                    const newNode = parseNodeFromTree(data);
                    const nodeIndex = nodes.findIndex(node => node.id === lastElement.id);
                    if (nodeIndex !== -1) {
                        nodes[nodeIndex] = newNode;
                        setNodes([...nodes]);
                    }
                }
                previousLengthNodes.current = nodes.length;
            } catch (error) {
                console.error("An error occurred while updating the last element:", error);
            }
        };
        updateLastElement();
    }, ['nodes', nodes.length, 'username', 'projectName', 'setNodes', 'replaceNodeArray']);

    const handleEdgeDeletion = async (selectedEdge) => {
        try {
            const {source, target, sourceHandle, targetHandle, id} = selectedEdge;
            const sourceNode = nodes.find((node) => node.id === source);
            const targetNode = nodes.find((node) => node.id === target);
            const updatedArray = edges.filter(item => item !== selectedEdge);
            setEdges(updatedArray);
            await deleteEdge(id, username, projectName);
            if (sourceNode.type === 'INPUT') {
                await deleteIOField(sourceHandle, sourceNode.id, username, projectName,);
            }
            if (targetNode.type === 'OUTPUT') {
                await deleteIOField(targetHandle, targetNode.id, username, projectName,);
            }
            let newSource = await getNode(sourceNode.id, username, projectName);
            let newTarget = await getNode(targetNode.id, username, projectName);
            replaceNodeArray(selectedEdge.source, newSource);
            replaceNodeArray(selectedEdge.target, newTarget);
        } catch (error) {
            console.error("An error occurred while deleting the edge:", error);
        }
    };

    const onNodeDragStop = async (event, node) => {
        try {
            const oldPosition = nodes.find(n => n.id === node.id).position;
            const updatedNodes = nodes.map((n) => n.id === node.id ? {...n, position: node.position} : n);
            if (node.position.x !== oldPosition.x || node.position.y !== oldPosition.y) {
                setNodes(updatedNodes);
                await updateNode(prepareNodeForApi(node), username, projectName);
            }
        } catch (error) {
            console.error("An error occurred during dragging node", error);
        }
    };

    const replaceIdInArray = (arr, targetId, newId) => {
        return arr.map((item) => {
            if (item.id === targetId) {
                return {...item, id: newId};
            }
            return item;
        });
    };

    const updateLastEdge = useCallback(async () => {
        try {
            let lastEdge = edges[edges.length - 1];

            const isNumericId = /^\d+$/.test(lastEdge.id);
            if (!isNumericId) {
                const data = await createEdge(
                    prepareEdgeForApi(lastEdge, username, projectName),
                    username, projectName
                );
                let newSource = await getNode(data.source, username, projectName);
                let newTarget = await getNode(data.target, username, projectName);

                replaceNodeArray(lastEdge.source, newSource);
                replaceNodeArray(lastEdge.target, newTarget);

                const updatedEdges = replaceIdInArray(edges, lastEdge.id, String(data.id));
                setEdges(updatedEdges);
            }

        } catch (error) {
            let lastEdge = edges[edges.length - 1];
            const existingEdge = edges.find(
                (edge) =>
                    edge.target === lastEdge.target &&
                    edge.targetHandle === lastEdge.targetHandle
            );
            if (existingEdge) {
                await loadTreeFromBackend();
            }
        }
    }, [edges, username, projectName, loadTreeFromBackend, replaceNodeArray, setEdges]);

    useEffect(() => {
        updateLastEdge();
    }, [updateLastEdge]);

    const onConnect = useCallback(
        async function (params) {
            try {
                const {sourceHandle, target, targetHandle} = params;
                const existingEdge = edges.find(
                    (edge) =>
                        edge.target === target &&
                        edge.targetHandle === targetHandle
                );

                if (existingEdge) {
                    await loadTreeFromBackend();
                    return;
                }

                if (isNewHandle(targetHandle) || isNewHandle(sourceHandle)) {
                    const newHandleID = getNewHandleID(targetHandle, sourceHandle);
                    const isTarget = getFieldIsTarget(newHandleID);
                    const nodeIdToConnect = getNodeIdToConnect(isTarget, params);
                    const preparedHandle = prepareFieldForApi(params, isTarget);
                    const newField = await createIOField(preparedHandle, nodeIdToConnect, username, projectName);
                    if (isTarget) {
                        params.targetHandle = String(newField.id);
                    } else {
                        params.sourceHandle = String(newField.id);
                    }
                }

                const newEdgeConfig = {
                    id: params.id = (Math.random() + 1).toString(36).substring(2),
                    source: String(params.source),
                    target: String(params.target),
                    sourceHandle: String(params.sourceHandle),
                    targetHandle: String(params.targetHandle),
                    animated: true,
                    style: {"stroke": '#FD4B01', "strokeWidth": 2},
                    type: 'custom'
                };
                setEdges((currentEdges) => [...currentEdges, newEdgeConfig]);
            } catch (error) {
                console.error("An error occurred during the connection:", error);
            }
        },
        [edges, username, projectName, loadTreeFromBackend, setEdges]
    );

    const isAnyFileLoading = Object.values(loadingStates).some((isLoading) => isLoading);
    return (
        <div className="dndflow">
            <FlowContext.Provider value={{
                username,
                projectName,
                removeNode,
                projectState,
                nodes,
                edges,
                handleEdgeDeletion,
                loadingStates,
                setLoadingStates,
                specificModels,
                isDemo
            }}>
                <ReactFlowProvider>
                    <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                        <ReactFlow
                            nodesDraggable={["IDLE", "ERROR"].includes(projectState)}
                            nodesConnectable={["IDLE", "ERROR"].includes(projectState)}
                            elementsSelectable={["IDLE", "ERROR"].includes(projectState)}
                            fitView={true}
                            variant="dots"
                            nodes={nodes}
                            edges={edges}
                            defaultNodes={nodes}
                            defaultEdges={edges}
                            onConnect={onConnect}
                            onInit={setReactFlowInstance}
                            onDrop={onDrop}
                            onDragOver={onDragOver}
                            onNodeDragStop={onNodeDragStop}
                            nodeTypes={nodeTypes}
                            edgeTypes={edgeTypes}
                        >

                            <div style={{
                                position: 'absolute',
                                top: '10px',
                                left: '10px',
                                zIndex: 1000,
                                display: 'flex',
                                flexDirection: 'column',
                                background: 'rgba(255, 255, 255, 0.8)',
                                padding: '10px',
                                borderRadius: '5px',
                            }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    marginBottom: '5px',
                                }}>
                                    <Link color="text" as={LK} to={`/${username}/${projectName}`}>
                                        <FaArrowLeft style={{marginRight: "10px"}}/>
                                    </Link>
                                    <Text style={{
                                        fontSize: "20px",
                                        fontWeight: 'bold',
                                        paddingTop: "14px",
                                    }}>
                                        {project.name}
                                    </Text>
                                </div>
                            </div>
                        </ReactFlow>
                    </div>
                    {["IDLE", "ERROR"].includes(projectState) ? <Sidebar projectState={projectState}/> : null}
                </ReactFlowProvider>
            </FlowContext.Provider>
        </div>
    );
};

export default Flow;