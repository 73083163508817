import React, {useEffect, useRef} from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link as LK } from "react-router-dom";

import styles from "./index.module.css";

import lightning from "./images/lightning.svg";
import stub from "../../../Home/images/flashBgGrey.svg";

interface ScrollMenuProps {
    projects: Project[];
    isLoading: boolean;
}

const ScrollMenu: React.FC<ScrollMenuProps> = ({ projects, isLoading }) => {
    const keywords: string[] = ["flux", "whisper"];
    const cardContainerRef = useRef<HTMLDivElement>(null);

    const filteredProjects: Project[] = projects.filter(project => {
        const projectName = project.name.toLowerCase();
        return keywords.some(keyword => projectName.includes(keyword));
    });

    const handleWheel = (event: WheelEvent) => {
        if (cardContainerRef.current) {
            event.preventDefault();
            cardContainerRef.current.scrollLeft += event.deltaY;
        }
    };

    useEffect(() => {
        const container = cardContainerRef.current;
        if (container) {
            container.addEventListener('wheel', handleWheel);
        }
        return () => {
            if (container) {
                container.removeEventListener('wheel', handleWheel);
            }
        };
    }, [isLoading]);

    return (
        <>
            {filteredProjects.length ?
                <Row className="pb-3">
                    <Col xxl={3} lg={3} md={3} className={styles.titleContainer}>
                        <div className={styles.imageContainer} >
                            <Image src={lightning} alt="lightning" />
                        </div>
                        <div className={styles.title}>
                            Our Most Popular Models
                        </div>
                    </Col>
                    <Col xxl={9} lg={9} md={9} className={styles.main}>
                        <Col className={styles.cardContainer} ref={cardContainerRef} >
                            {filteredProjects.map((project: Project, index: number) => (
                                <LK
                                    to={`/${project.owner}/${project.name}`}
                                    className={styles.link}
                                    key={index}
                                >
                                    <div className={styles.card}>
                                        <div className={styles.image}>
                                            <Image
                                                src={project.cover_image || stub}
                                                alt={project.name}
                                            />
                                        </div>
                                        <div className={styles.text}>
                                            {project.name}
                                        </div>
                                    </div>
                                </LK>
                            ))}
                        </Col>
                    </Col>
                </Row>
                :
                <></>
            }
        </>
    );
}

export default ScrollMenu;