import { Col, Row } from "react-bootstrap";
import Card from "../components/Card";

/**
    * Renders a list of projects within a responsive grid layout.
    *
    * @param {Array} projects - An array of project objects to be displayed. 
    * Each project object should contain the necessary data for rendering 
    * a card.
    * @param {string} title - The title to be displayed above the list of 
    * projects. This title will only be shown if there are projects to display.
    * @param {function} lastProjectRef - A React ref callback function that 
    * is used to observe the last project element for triggering additional 
    * actions, such as loading more projects.
    *
    * @returns {JSX.Element} A JSX element containing a row of project cards. 
    * Each project is rendered within a Bootstrap column, and the list is 
    * wrapped in a Bootstrap row.
    * The title is displayed above the projects if the projects array is 
    * not empty.
*/

export const renderProjects = (projects, title, lastProjectRef, size) => (
    <Row style={{ paddingBottom: "48px" }}>
        {projects?.length !== 0 &&
            <h4 style={{ fontSize: "24px", fontWeight: "bold" }}>
                {title}
            </h4>}
        {projects?.map((project, index) => (
            <Col key={index} xs={size.xs} sm={size.sm} md={size.md} lg={size.lg} xl={size.xl}
                ref={projects.length === index + 1 ? lastProjectRef : null}>
                <Card props={project} />
            </Col>
        ))}
    </Row>
);