import React, {useEffect, useState} from 'react';
import {Modal, Text, Button, Input, Textarea, Spacer, Row} from "@nextui-org/react";
import {
    cloneProject,
    deleteProject,
    updateProjectInfo,
    createProject,
    checkProjectName,
    changeProjectWorkingMode
} from "../../../../api/projects";
import {useNavigate} from "react-router-dom"
import StyledSelect from "../../../Flow/styles/styles";
import DeleteConfirmationModal from "./DeleteConfirmationModal";

const SettingsModal = ({
                           isOpen,
                           onClose,
                           mode = 'edit',
                           username,
                           projectInfo,
                           setProjectInfo,
                           availableHardware,
                           isDemo = false
                       }) => {
    const navigate = useNavigate();
    const [newProjectInfo, setNewProjectInfo] = useState(projectInfo);
    const [validationErrors, setValidationErrors] = useState({});
    const isEditable = projectInfo.state === 'IDLE' || projectInfo.state === 'ERROR' || mode !== 'edit';
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const showDeleteConfirmation = () => setIsDeleteModalOpen(true);

    const confirmDelete = async () => {
        await deleteProject(username, newProjectInfo.name);
        navigate(`/projects`);
        handleClose();
        setIsDeleteModalOpen(false);
    };
    const cancelDelete = () => setIsDeleteModalOpen(false);


    const handleClose = () => {
        onClose();
        document.body.style.overflow = 'auto';
    };

    const hasValidationErrors = () => Object.keys(validationErrors).some(key => validationErrors[key] !== "");

    const validateProjectName = async (name) => {
        if (!name) return "Project Name is required.";
        if (name.length < 4 || name.length > 150) return "Project Name must be between 4 and 150 characters";
        if (!/^[a-zA-Z0-9_-]+$/.test(name)) return "Project Name can contain only letters, numbers, and underscores.";
        const checkResponse = await checkProjectName(username, name, newProjectInfo.name);
        if (!checkResponse.available) return checkResponse.message;
        return "";
    };

    const handleInputChange = (field, value) => {
        setNewProjectInfo(prev => ({...prev, [field]: value}));
    };

    useEffect(() => {
        const validate = async () => {
            const nameError = await validateProjectName(newProjectInfo.name);
            setValidationErrors({...validationErrors, name: nameError});
        };
        validate();
    }, [newProjectInfo.name]);

    const handleSubmit = async () => {
        const errors = await validateProjectName(newProjectInfo.name);
        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            return;
        }
        let newProject;
        if (mode === 'create') {
            newProject = await createProject(username, newProjectInfo);
        } else {
            newProject = await updateProjectInfo(username, projectInfo.name, newProjectInfo);
            if (newProject.working_mode !== projectInfo.working_mode) {
                await changeProjectWorkingMode(username, newProject.name, {"working_mode": newProjectInfo.working_mode});
            }
        }
        setProjectInfo(newProjectInfo);
        navigate(`/${username}/${newProject.name}`);
        handleClose();
    };

    const deleteThatProject = async () => {
        await deleteProject(username, newProjectInfo.name);
        navigate(`/projects`);
        handleClose();
    };

    if (!projectInfo) return null;
    return (
        isOpen ? (
            <>
                <Modal open={isOpen} onClose={handleClose} width="600px">
                    <Modal.Header>
                        <Text h3>{mode === 'edit' ? 'Project Settings' : 'Create New Project'}</Text>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}>
                            <label style={{display: 'block', marginBottom: '10px'}}>
                                <span style={{display: 'block'}}>Project Name:</span>
                                <Input
                                    aria-label="Project Name"
                                    width="100%"
                                    value={newProjectInfo.name}
                                    onChange={e => handleInputChange('name', e.target.value)}
                                    helperText={validationErrors.name}
                                    readOnly={isDemo}
                                />
                            </label>
                            {/*<Spacer y={1}/>*/}
                            {/*<label style={{display: 'block'}}>*/}
                            {/*    <span style={{display: 'block'}}>Working Mode:</span>*/}
                            {/*    <StyledSelect*/}
                            {/*        value={newProjectInfo.working_mode}*/}
                            {/*        onChange={e => setNewProjectInfo({*/}
                            {/*            ...newProjectInfo,*/}
                            {/*            working_mode: e.target.value*/}
                            {/*        })}*/}
                            {/*        disabled={!isEditable}*/}
                            {/*    >*/}
                            {/*        <option value="OFF">Off</option>*/}
                            {/*        <option value="INSTANCE">Instance</option>*/}
                            {/*        <option value="SERVERLESS">Serverless</option>*/}
                            {/*    </StyledSelect>*/}
                            {/*</label>*/}
                            {/*<Spacer y={1}/>*/}
                            {/*<label style={{display: 'block'}}>*/}
                            {/*    <span style={{display: 'block'}}>Preferred Accelerator:</span>*/}
                            {/*    <StyledSelect*/}
                            {/*        value={newProjectInfo.preferred_accelerator}*/}
                            {/*        onChange={e => setNewProjectInfo({*/}
                            {/*            ...newProjectInfo,*/}
                            {/*            preferred_accelerator: e.target.value*/}
                            {/*        })}*/}
                            {/*        disabled={availableHardware.length === 0 || !isEditable}*/}
                            {/*    >*/}
                            {/*        {availableHardware.length === 0 ? (*/}
                            {/*            <option value="">No available accelerators</option>*/}
                            {/*        ) : (*/}
                            {/*            availableHardware.map((accelerator) => (*/}
                            {/*                <option key={accelerator.id} value={accelerator.id}>*/}
                            {/*                    {accelerator.label}*/}
                            {/*                </option>*/}
                            {/*            ))*/}
                            {/*        )}*/}
                            {/*    </StyledSelect>*/}
                            {/*</label>*/}
                            {/*<Spacer y={1}/>*/}
                            {/*<label style={{display: 'block', marginBottom: '10px'}}>*/}
                            {/*    <span style={{display: 'block'}}>Region:</span>*/}
                            {/*    <StyledSelect*/}
                            {/*        value={'us-cheapest'}*/}
                            {/*        disabled={!isEditable}*/}
                            {/*    >*/}
                            {/*        <option value="us-cheapest">US - Cheapest</option>*/}
                            {/*    </StyledSelect>*/}
                            {/*</label>*/}
                            {/*<Spacer y={1}/>*/}
                            <label style={{display: 'block'}}>
                                <span style={{display: 'block'}}>Project Description:</span>
                                <Textarea
                                    aria-label="Project Description"
                                    width="100%"
                                    value={newProjectInfo.description}
                                    onChange={e => setNewProjectInfo({...newProjectInfo, description: e.target.value})}
                                    readOnly={isDemo}
                                />
                            </label>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Row justify={'space-between'}>
                            <div>
                                <Button auto flat size={'sm'} color="error" onClick={showDeleteConfirmation}>
                                    Delete
                                </Button>
                            </div>
                            <div>
                                <Row>
                                    <Button auto flat size={'sm'} color="gray" onClick={handleClose}>
                                        Close
                                    </Button>
                                    <Spacer x={0.8}/>
                                    <Button auto size={'sm'} onClick={handleSubmit} color="success"
                                            disabled={hasValidationErrors()}>
                                        Save
                                    </Button>
                                </Row>
                            </div>
                        </Row>

                    </Modal.Footer>
                </Modal>
                <DeleteConfirmationModal
                    isOpen={isDeleteModalOpen}
                    onConfirm={confirmDelete}
                    onCancel={cancelDelete}
                    projectInfo={newProjectInfo}
                />
            </>
        ) : null
    );
};

export default SettingsModal;
