export const handleTypeOptions = [
    { value: '3', label: 'Text' },
    { value: '1', label: 'Integer' },
    { value: '2', label: 'Float' },
    { value: '6', label: 'Image' },
    { value: '4', label: 'Video' },
    { value: '5', label: 'Audio' },
    { value: '0', label: 'Boolean' },
];


//State
export const STATE = {
    RUNNING: "RUNNING",
    STARTING: "STARTING",
    STOPPING: "STOPPING",
    IDLE: "IDLE",
    ERROR: "ERROR"
}
export const ACTIVE = "RUNNING, STARTING, STOPPING,";
export const NOT_ACTIVE = "IDLE, ERROR";

export const TITLE_SPLIT_GROUP = {
    ACTIVE: "Active projects",
    NOT_ACTIVE: "On Demand",
}

export const LINKS = {
    DISCORD: "https://discord.gg/t6hPBpSebw",
    LINKED_IN: "https://www.linkedin.com/company/flymyai/",
    CONTACT_US: "https://tally.so/r/3NVg5N",
}

export const MAXIMUM_PODS_DISPLAYED = 30;