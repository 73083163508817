import React from 'react';
import {Card, Container, Input, Text, Spacer} from '@nextui-org/react';
import { Link as LK, useNavigate } from 'react-router-dom';
import {HeaderSpacer} from '../Header/HeaderSpacer';
import SingButtons from "./SignButtons";
import loginImage from './images/signin.jpeg';


const LoginForm = () => {

    return (
        <Container xs={true} justify={"center"} style={{minHeight: "calc(100vh - 190px)", marginTop: "0px"}}>
            <HeaderSpacer/>
            <Card>
                <Card.Body>
                    <div style={{marginLeft: "10px", marginRight: "10px"}}>
                            <Text h2>Login</Text>
                            <Card.Image
                                src={loginImage}
                                objectFit="cover"
                                width="100%"
                                style={{borderRadius: "10px"}}
                            />
                            <Spacer y={1} />
                            <Text size={11}>Don't have an account? <LK to="/signup" style={{color: "#7828C8"}}>Sign Up</LK></Text>
                            <Spacer y={0.5}/>
                            <SingButtons isSignUp={false}/>
                    </div>
                </Card.Body>
            </Card>
            <HeaderSpacer/>
        </Container>
    );
};

export default LoginForm;