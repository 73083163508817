import lightingButton from "./images/lightingButton.svg"

export const ERROR_RUN_BTN = {
    LOGIN : {
        BUTTON : {
            path: "/login",
            title: "Login",
            image: null,
        },
        TEXT: "Please, login to run this model",
    },
    WALLET : {
        BUTTON : {
            path: "/finances",
            title: "Finances",
            image: lightingButton,
        },
        TEXT: "Please, top up your wallet",
    },
    SETTINGS : {
        BUTTON : {
            path: "/profile",
            title: "Settings",
            image: lightingButton,
        },
        TEXT: "Please, generate API Key",
    },
}