import axiosInstance from "./request";

const aiUrl = "ai/";


export async function createAiFile(data) {
    return axiosInstance.post(`${aiUrl}files/`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

export async function getSpecificModelsList() {
    return axiosInstance.get(`${aiUrl}specific-models/`);
}

