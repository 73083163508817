import React from "react";

import styles from "./index.module.css";

export default function State({ titleState }) {
    const state = {
        "ACTIVE": {
            style: { background: "#E1FBFB", color: "#0F7583" },
        },
        "RUNNING": {
            style: { background: "#DAFBE1", color: "#059D5B" },
        },
        "ERROR": {
            style: { background: "#FFE0E0", color: "#B32632" },
        },
        "IDLE": {
            style: { background: "#E8F0FB", color: "#1053D4" },
        },
        "STARTING": {
            style: { background: "#DAFBE1", color: "#059D5B" },
        },
        "STOPPING": {
            style: { background: "#FFE0E0", color: "#B32632" },
        },
    }
    return (
        <div className={styles.text}>
            <span
                style={state[titleState]?.style}
                className={styles.state}
            >
                {titleState === "IDLE"
                    ? titleState
                    : titleState.replace(
                        titleState.slice(1),
                        titleState.slice(1).toLowerCase())
                }
                {/*&nbsp;*/}
                {/*<Image*/}
                {/*    src={state[projectInfo.state]?.image}*/}
                {/*    alt="Exclamation Mark"*/}
                {/*    className={styles.image}*/}
                {/*/>*/}
            </span>
        </div>
    )
}