export const isNewHandle = (handle) =>
    handle === "new_target_handle" || handle === "new_source_handle";

export const getNewHandleID = (targetHandle, sourceHandle) =>
    targetHandle === "new_target_handle"
        ? targetHandle
        : sourceHandle === "new_source_handle"
            ? sourceHandle
            : null;

export const getFieldIsTarget = (newHandleID) =>
    newHandleID !== "new_source_handle";


export const getNodeIdToConnect = (isTarget, params) =>
    isTarget === true ? params.target : params.source;

export const getHandleType = (fieldType) =>
    fieldType === "input" ? "targetHandle" : "sourceHandle";


export function snakeCaseToRegularText(snakeCaseString) {
    const words = snakeCaseString.split("_");
    return words
        .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
}



export function getHandleData(nodes, handleId, nodeId, handleType, connection) {
    const {source, target, sourceHandle, targetHandle} = connection;
    const startNode = nodes.find(node => node.id === nodeId);
    const startHandle = findHandle(nodes, nodeId, handleType, handleId);

    let endNode;
    let endHandle;
    if (handleType === 'source_handles') {
        endNode = nodes.find(node => node.id === target.split('__')[0]);
        endHandle = findHandle(nodes, endNode.id, 'target_handles', targetHandle);
    } else {
        endNode = nodes.find(node => node.id === source.split('__')[0]);
        endHandle = findHandle(nodes, endNode.id, 'source_handles', sourceHandle);
    }

    return {startNode, startHandle, endNode, endHandle};
}

export function findHandle(nodes, nodeId, handleType, handleId) {
    const node = nodes.find(node => node.id === nodeId);
    if (!node) {
        console.error('Node not found', nodeId);
        return null;
    }

    const handle = node.data[handleType].find(handle => String(handle.id) === String(handleId));
    if (!handle) {
        console.error('Handle not found', handleId);
        return null;
    }

    return handle;
}


export const getMaxHandleWidth = (handles, ioNode=false) => {
    const initialWidth = 30;
    const maxWidth = handles.reduce((max, handle) => {
        let handleLabel;
        if (ioNode) {
            handleLabel = handle.label_slug
        }
        else {
            handleLabel = handle.label
        }
        if (handle.label) {
            const handleLength = handleLabel.length * 8 + 10;
            return Math.max(handleLength, max);
        }
        return max;
    }, initialWidth);

    return `${maxWidth}px`;
};