import {Card, Grid, Spacer, Button, Modal, Text, Input} from "@nextui-org/react";
import React, {useContext, useEffect, useState} from "react";
import BaseNode, {
    HandleActionsContainer,
    MinimizedCardBody,
    MinimizedCardHeader,
    MinimizedCardWrapper,
    StyledLeftHandle,
    StyledRightHandle,
} from "../CustomNodes/BaseNode";
import Tooltip from "../Tooltips";
import FlowContext from "../FlowContext";
import {getHandleData, getMaxHandleWidth} from "../utils";
import {compareDims, compareFormats} from "./checks";
import {getNode, updateIOField, updateNode} from "../../../api/flow";
import {prepareNodeForApi} from "../Tree";
import {toastWarn} from "../../../utils/toasts";
import StyledSelect from "../styles/styles";


const ContentBody = ({
                         props,
                         data,
                         nodeId,
                         nodes,
                         edges,
                         node,
                         projectName,
                         username,
                         setExternalNodes,
                         availableLLMs
                     }) => {
    const [LLM, setLLM] = useState(data.llm || "");
    const {source_handles, target_handles} = data;
    const [showMoreInputs, setShowMoreInputs] = useState(false);
    const [showMoreOutputs, setShowMoreOutputs] = useState(false);
    const [selectedHandle] = useState(null);
    const [handleModal, setHandleModal] = useState({visible: false});
    const [perfAnalyzerDims, setPerfAnalyzerDims] = useState("");
    const [handleType] = useState("source");
    const [maxHandleWidth, setMaxHandleWidth] = useState(50);

    useEffect(() => {
        if (target_handles.length > 0 || source_handles.length > 0) {
            const handles = target_handles.concat(source_handles);
            const maxWidth = getMaxHandleWidth(handles);
            setMaxHandleWidth(maxWidth);
        }
    }, [source_handles, target_handles]);

    const handleLLMTypeChange = async (event, node) => {
        setLLM(event.target.value);
        node.data.llm = event.target.value
        await updateNode(prepareNodeForApi(node), username, projectName);
    };


    const addHandle = async () => {
        if (perfAnalyzerDims) {
            const perfAnalyzerDimsArray = perfAnalyzerDims.split(',')
                .map(dim => dim.trim())
                .map(dim => isNaN(dim) ? dim : parseInt(dim));
            const updatedSelectedHandleData = {
                ...selectedHandle.data,
                perf_analyzer_dims: perfAnalyzerDimsArray
            };

            const newHandle = {
                label: selectedHandle.label,
                data: {
                    ...updatedSelectedHandleData,
                },
            };

            if (selectedHandle) {
                const handleTypeKey = handleType === 'source' ? 'source_handles' : 'target_handles';
                const handleIndex = node.data['target_handles'].findIndex(
                    (handle) => handle.id === selectedHandle.id
                );
                if (handleIndex !== -1) {
                    node.data[handleTypeKey]['target_handles'] = newHandle;
                    await updateIOField(newHandle, selectedHandle.id, node.id, username, projectName);
                }
            }
            setHandleModal({visible: false});
            setPerfAnalyzerDims("");
            const tempNode = await getNode(node.id, username, projectName);
            await setExternalNodes(tempNode, tempNode.id);
        }
    };

    const createIsValidConnection = (handleId, nodeId, handleType) => {
        return (connection) => {
            const newHandlesIds = ['new_source_handle', 'new_target_handle'];
            const {
                startNode,
                startHandle,
                endNode,
                endHandle
            } = getHandleData(nodes, handleId, nodeId, handleType, connection);
            if (startNode.id === endNode.id) {
                toastWarn('It\'s the same node', `sameNode-${startHandle.id}-${endHandle.id}`);
                return false;
            }
            if (newHandlesIds.includes(endHandle.id)) {
                return true;
            }
            if (JSON.stringify(endHandle.dims)) {
                if (!compareDims(startHandle.dims, endHandle.dims)) {
                    toastWarn('Dims do not match', `dimsDoNotMatch-${startHandle.id}-${endHandle.id}`);
                    return false;
                }
            }
            if (endHandle.ai_format && startHandle.ai_format) {
                if (!compareFormats(startHandle.ai_format, endHandle.ai_format)) {
                    toastWarn('Formats do not match', `formatsDoNotMatch-${startHandle.id}-${endHandle.id}`);
                    return false;
                }
            }
            return true;
        }
    }

    const handleShowMoreInputs = () => {
        setShowMoreInputs(!showMoreInputs);
    };

    const handleShowMoreOutputs = () => {
        setShowMoreOutputs(!showMoreOutputs);
    };

    const connectedHandle = (handleId) => {
        return edges.some(edge => edge.sourceHandle.toString() === handleId.toString() || edge.targetHandle.toString() === handleId.toString());
    };

    return (
        <>
            <MinimizedCardWrapper style={{alignItems: 'center', justifyContent: 'center'}}>
                <Card variant={"flat"} css={{overflow: "visible", $$cardColor: "$colors$accents2"}}>
                    <MinimizedCardBody>
                        <Grid.Container style={{alignItems: 'center', marginTop: '4px'}} justify="between">
                            <Grid xs={4}><Text>LLM Name:</Text></Grid>
                            <Grid xs={8}>
                                <StyledSelect className="nodrag" value={LLM} onChange={(event) => handleLLMTypeChange(event, props)}>
                                    {availableLLMs.map(llm => <option key={llm.id} value={llm.id}>{llm.label}</option>)}
                                </StyledSelect>
                            </Grid>
                        </Grid.Container>
                    </MinimizedCardBody>
                </Card>
            </MinimizedCardWrapper>
            <Spacer y={1}/>
            <MinimizedCardWrapper>
                <Card variant={"flat"} css={{overflow: "visible"}}>
                    <MinimizedCardHeader>Inputs</MinimizedCardHeader>
                    {target_handles &&
                        target_handles.filter(target => !target.data.optional)
                            .map((target) => (
                                <MinimizedCardBody key={target.id}>
                                    <Grid.Container justify="between">
                                        <Grid xs={6} style={{ width: maxHandleWidth }} >
                                            {target.label}
                                        </Grid>
                                        <Grid xs={6}>
                                            <HandleActionsContainer>
                                                <div style={{marginRight: '5px'}}>
                                                    <Tooltip tooltip={target.dims.join(',')} button='dims'></Tooltip>
                                                </div>
                                                <Tooltip tooltip={target.ai_format} button='format'></Tooltip>
                                            </HandleActionsContainer>
                                        </Grid>
                                    </Grid.Container>
                                    <StyledLeftHandle
                                        id={`${target.id.toString()}`}
                                        type="target"
                                        position="left"
                                        optional={target?.data?.optional}
                                        isValidConnection={createIsValidConnection(target.id, nodeId, 'target_handles')}
                                    />
                                </MinimizedCardBody>
                            ))
                    }
                    {target_handles && target_handles.some(target => target.data.optional) && (
                        <>
                            <MinimizedCardHeader>Optional Inputs</MinimizedCardHeader>
                            {target_handles.filter(target => target.data.optional)
                                .map((target) => {
                                    if (!showMoreInputs && !connectedHandle(target.id)) {
                                        return null;
                                    }
                                    return (
                                        <MinimizedCardBody key={target.id}>
                                            <Grid.Container justify="between">
                                                <Grid xs={6} style={{ width: maxHandleWidth }} >
                                                    {target.label}
                                                </Grid>
                                                <Grid xs={6}>
                                                    <HandleActionsContainer>
                                                        <div style={{marginRight: '5px'}}>
                                                            <Tooltip tooltip={target.dims.join(',')} button='dims'></Tooltip>
                                                        </div>
                                                        <Tooltip tooltip={target.ai_format} button='format'></Tooltip>
                                                    </HandleActionsContainer>
                                                </Grid>
                                            </Grid.Container>
                                            <StyledLeftHandle
                                                id={`${target.id.toString()}`}
                                                type="target"
                                                position="left"
                                                optional={target?.data?.optional}
                                                isValidConnection={createIsValidConnection(target.id, nodeId, 'target_handles')}
                                            />
                                        </MinimizedCardBody>
                                    )
                                })
                            }
                            <Spacer y={0.3}/>
                            <Button auto size="sm" bordered color="primary"
                                    onClick={handleShowMoreInputs}>{showMoreInputs ? 'Show Less' : 'Show More'}
                            </Button>
                        </>
                    )}
                </Card>
            </MinimizedCardWrapper>
            <Spacer y={1}/>
            <MinimizedCardWrapper>
                <Card variant={"flat"} css={{overflow: "visible", $$cardColor: "$colors$accents2"}}>
                    <MinimizedCardHeader>Outputs</MinimizedCardHeader>
                    {source_handles &&
                        source_handles.filter(source => !source.data.optional)
                            .map((source) => (
                                <MinimizedCardBody key={source.id}>
                                    <Grid.Container justify="between">
                                        <Grid xs={6} style={{ width: maxHandleWidth }} >
                                            {source.label}
                                        </Grid>
                                        <Grid xs={6}>
                                            <HandleActionsContainer>
                                                <div style={{marginRight: '5px'}}>
                                                    <Tooltip tooltip={source.dims.join(',')} button='dims'></Tooltip>
                                                </div>
                                                <Tooltip tooltip={source.ai_format} button='format'></Tooltip>
                                            </HandleActionsContainer>
                                        </Grid>
                                    </Grid.Container>
                                    <StyledRightHandle
                                        id={`${source.id.toString()}`}
                                        type="source"
                                        position="right"
                                        optional={source?.data?.optional}
                                        isValidConnection={createIsValidConnection(source.id, nodeId, 'source_handles')}
                                    />
                                </MinimizedCardBody>
                            ))
                    }
                    {source_handles && source_handles.some(source => source.data.optional) && (
                        <>
                            <MinimizedCardHeader>Optional Outputs</MinimizedCardHeader>
                            {source_handles.filter(source => source.data.optional)
                                .map((source) => {
                                    if (!showMoreOutputs && !connectedHandle(source.id)) {
                                        return null;
                                    }
                                    return (
                                        <MinimizedCardBody key={source.id}>
                                            <Grid.Container justify="between">
                                                <Grid xs={6} style={{ width: maxHandleWidth }} >
                                                    {source.label}
                                                </Grid>
                                                <Grid xs={6}>
                                                    <HandleActionsContainer>
                                                        <div style={{marginRight: '5px'}}>
                                                            <Tooltip tooltip={source.dims.join(',')} button='dims'></Tooltip>
                                                        </div>
                                                        <Tooltip tooltip={source.ai_format} button='format'></Tooltip>
                                                    </HandleActionsContainer>
                                                </Grid>
                                            </Grid.Container>
                                            <StyledRightHandle
                                                id={`${source.id.toStrig()}`}
                                                type="source"
                                                position="right"
                                                optional={source?.data?.optional}
                                                isValidConnection={createIsValidConnection(source.id, nodeId, 'source_handles')}
                                            />
                                        </MinimizedCardBody>
                                    )
                                })
                            }
                            <Button auto size="sm" bordered color="primary"
                                    onClick={handleShowMoreOutputs}>{showMoreOutputs ? 'Show Less' : 'Show More'}
                            </Button>
                        </>
                    )}
                </Card>
            </MinimizedCardWrapper>
        </>
    );
};


const LLMNode = ({username, projectName, setExternalNodes, ...props}) => {
    const {projectState, nodes, edges, specificModels} = useContext(FlowContext);
    return (
        <div>
            <BaseNode
                id={props.id}
                label={props.data.label}
                type={props.type}
                data={{...props.data, label: 'LLM'}}
                content={
                    <ContentBody
                        props={props}
                        data={props.data}
                        projectState={projectState}
                        nodes={nodes}
                        edges={edges}
                        nodeId={props.id}
                        node={props}
                        username={username}
                        projectName={projectName}
                        setExternalNodes={setExternalNodes}
                        availableLLMs={specificModels.llms}
                    />
                }
            />
        </div>
    );
};

export default LLMNode;
