const initialState = {
    has_amazon_s3_settings: false,
    has_azure_settings: false,
    has_digital_ocean_settings: false,
    has_dropbox_settings: false,
};

const clouds = (state = initialState, action) => {
    if (action.type === 'UPDATE_CLOUDS') {
        if (action.payload) {
            const has_amazon_s3_settings = action.payload.has_amazon_s3_settings || false;
            const has_azure_settings = action.payload.has_azure_settings || false;
            const has_digital_ocean_settings = action.payload.has_digital_ocean_settings || false;
            const has_dropbox_settings = action.payload.has_dropbox_settings || false;
            return {
                has_amazon_s3_settings: has_amazon_s3_settings,
                has_azure_settings: has_azure_settings,
                has_digital_ocean_settings: has_digital_ocean_settings,
                has_dropbox_settings: has_dropbox_settings,
            };
        }
        return state;
    } else {
        return state;
    }
};


export default clouds;
