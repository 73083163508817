import styles from "./styles/ApiSectionMain.module.css";
import PythonApiSection from "./PythonApiSection";
import { Container, Row, Col } from "react-bootstrap";
import Accordion from "../../components/Accordion/index.tsx";
import { useState } from "react";
import { useResize } from "../../../../../hooks/useresize.ts";

export default function ApiSectionMain({ username, projectInfo, inputModel, outputModel, apikey }) {
    const [showApi, setShowApi] = useState(false);
    const isBigScreen = useResize() > 991
    return (
        <Container fluid>
            <Row className={styles.main}>
                {isBigScreen
                    ? <Col><h4 className={styles.title}>API</h4></Col>
                    : <Accordion
                        state={showApi}
                        func={_ => setShowApi(!showApi)}
                        title="API"
                    />
                }
                {(showApi || isBigScreen) &&
                    <PythonApiSection className={styles.section}
                        username={username} projectInfo={projectInfo} inputModel={inputModel}
                        outputModel={outputModel} apikey={apikey} />
                }
            </Row>
        </Container>
    );
}