export function parseTreeToNodes(nodeTree) {
    const processedNodes = [];

    const getChildNodes = (parentId) => {
        return nodeTree.filter(node => node.parent === parentId);
    }

    const processNode = (node) => {
        const parsedNode = parseNodeFromTree(node);
        processedNodes.push(parsedNode);

        const childNodes = getChildNodes(node.id);
        childNodes.forEach(childNode => processNode(childNode));
    }
    const topLevelNodes = nodeTree.filter(node => !node.parent);
    topLevelNodes.forEach(node => processNode(node));

    return {
        initialNodes: processedNodes,
    };
}


export function parseNodeFromTree(node) {
    return {
        id: node.id.toString(),
        type: node.type,
        position: node.position,
        connectable: node.connectable,
        hidden: node.hidden,
        data: {
            label: node.label,
            ai_file: node.ai_file,
            project_id: node.project_id,
            weight: node.weight,
            code: node.code,
            llm: node.llm,
            requirements: node.requirements,
            source_handles: node.source_handles,
            target_handles: node.target_handles,
            deletable: node.deletable,
            draggable: node.draggable,
            background_color: node.background_color,
            trained_format: node.trained_format,
        },
    };
}


export function parseEdgesToObjects(edges) {
    return edges.map(parseEdgeToObject);
}

export function parseNodeToObject(node) {
    return {
        ...node,
        id: node.id.toString(),
        data: {
            ...node.data,
            label: node.label,
            hidden: node.hidden,
            deletable: node.deletable,
        },
    };
}

export function parseEdgeToObject(edge) {
    return {
        id: edge.id.toString(),
        animated: true,
        source: edge.source.toString(),
        target: edge.target.toString(),
        sourceHandle: edge.source_handle.toString(),
        targetHandle: edge.target_handle.toString(),
        style: {
            strokeWidth: 2,
            stroke: '#ff4b00',
        },
        type: 'custom',
    };
}

export function parseGraphToInitialData(graph) {
    const { initialNodes } = parseTreeToNodes(graph.tree);
    const initialEdges = parseEdgesToObjects(graph.edges);
    const projectState = graph.project.state;
    const project = graph.project;

    return {
        initialNodes,
        initialEdges,
        projectState,
        project,
    };
}

export function prepareEdgeForApi(edge, username, projectName) {
    return {
        source_handle: edge.sourceHandle,
        target_handle: edge.targetHandle,
        username: username,
        project_name: projectName,
    };
}

export function prepareNodeForApi(node, username, projectName) {
    return {
        ...node,
        username: username,
        project_name: projectName,
        parent: node.parent || null,
        type: node.type || null,
        code: node.data.code || null,
        requirements: node.data.requirements || null,
        llm: node.data.llm || null,
        trained_format: node.data.trained_format || null,
    };
}

export function convertPropsToNodeConfig(props) {
    const { id, type, ai_file, weight, processing_code, data, xPos, yPos } = props;

    return {
        id,
        type,
        ai_file,
        weight,
        processing_code,
        label: data.label,
        position: {
            x: xPos,
            y: yPos,
        },
    };
}


export function prepareFieldForApi(params, isTarget) {
    const {type, source, sourceHandle, target, targetHandle} = params;
    return {
        type: type,
        target_id: target,
        source_id: source,
        target_handle_id: targetHandle,
        source_handle_id: sourceHandle,
        is_target: isTarget,
    };
}
