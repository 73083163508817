export default function Lightning() {
    return (
        <svg width="13" height="22" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.45115 16L4.03917 10.3928L0 10.8132L8.30931 0L6.31377 6.43998L11 6.01789L2.45115 16Z" fill="url(#paint0_linear_1165_1112)" />
            <defs>
                <linearGradient id="paint0_linear_1165_1112" x1="1.34146" y1="3.36842" x2="10.0804" y2="3.54954" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#1A67C1" />
                    <stop offset="1" stop-color="#0F32AF" />
                </linearGradient>
            </defs>
        </svg>

    )
}
