import {Button, Card, Grid, Spacer, Text,} from "@nextui-org/react";
import React, {useContext, useEffect, useState} from "react";
import BaseNode, {
    HandleActionsContainer,
    MinimizedCardBody,
    MinimizedCardHeader,
    MinimizedCardWrapper,
    StyledLeftHandle,
    StyledRightHandle,
} from "../CustomNodes/BaseNode";
import Tooltip from "../Tooltips";
import FlowContext from "../FlowContext";
import {getHandleData, getMaxHandleWidth} from "../utils";
import {compareDims, compareFormats} from "./checks";
import {updateNode} from "../../../api/flow";
import {prepareNodeForApi} from "../Tree";
import {toastWarn} from "../../../utils/toasts";
import StyledSelect from "../styles/styles";


const ContentBody = ({
                         props,
                         data,
                         nodeId,
                         nodes,
                         projectName,
                         username,
                         availableSDs
                     }) => {
    const [SD, setSD] = useState(data.sd || "");
    const {source_handles, target_handles} = data;
    const [maxHandleWidth, setMaxHandleWidth] = useState(50);

    useEffect(() => {
        if (target_handles.length > 0 || source_handles.length > 0) {
            const handles = target_handles.concat(source_handles);
            const maxWidth = getMaxHandleWidth(handles);
            setMaxHandleWidth(maxWidth);
        }
    }, [source_handles, target_handles]);


    const createIsValidConnection = (handleId, nodeId, handleType) => {
        return (connection) => {
            const newHandlesIds = ['new_source_handle', 'new_target_handle'];
            const {
                startNode,
                startHandle,
                endNode,
                endHandle
            } = getHandleData(nodes, handleId, nodeId, handleType, connection);
            if (startNode.id === endNode.id) {
                toastWarn('It\'s the same node', `sameNode-${startHandle.id}-${endHandle.id}`);
                return false;
            }
            if (newHandlesIds.includes(endHandle.id)) {
                return true;
            }
            if (JSON.stringify(endHandle.dims)) {
                if (!compareDims(startHandle.dims, endHandle.dims)) {
                    toastWarn('Dims do not match', `dimsDoNotMatch-${startHandle.id}-${endHandle.id}`);
                    return false;
                }
            }
            if (endHandle.ai_format && startHandle.ai_format) {
                if (!compareFormats(startHandle.ai_format, endHandle.ai_format)) {
                    toastWarn('Formats do not match', `formatsDoNotMatch-${startHandle.id}-${endHandle.id}`);
                    return false;
                }
            }
            return true;
        }
    }

    return (
        <>
            <MinimizedCardWrapper>
                <Card variant={"flat"} css={{overflow: "visible"}}>
                    <MinimizedCardHeader>Inputs</MinimizedCardHeader>
                    {target_handles &&
                        target_handles.filter(target => !target.data.optional)
                            .map((target) => (
                                <MinimizedCardBody key={target.id}>
                                    <Grid.Container justify="between">
                                        <Grid xs={6} style={{ width: maxHandleWidth }} >
                                            {target.label}
                                        </Grid>
                                        <Grid xs={6}>
                                            <HandleActionsContainer>
                                                <div style={{marginRight: '5px'}}>
                                                    <Tooltip tooltip={target.dims.join(',')} button='dims'></Tooltip>
                                                </div>
                                                <Tooltip tooltip={target.ai_format} button='format'></Tooltip>
                                            </HandleActionsContainer>
                                        </Grid>
                                    </Grid.Container>
                                    <StyledLeftHandle
                                        id={`${target.id.toString()}`}
                                        type="target"
                                        position="left"
                                        optional={target.data?.optional?.toString()}
                                        isValidConnection={createIsValidConnection(target.id, nodeId, 'target_handles')}
                                    />
                                </MinimizedCardBody>
                            ))
                    }
                    {target_handles && target_handles.some(target => target.data.optional) && (
                        <>
                            <MinimizedCardHeader>Optional Inputs</MinimizedCardHeader>
                            {target_handles.filter(target => target.data.optional)
                                .map((target) => {
                                    return (
                                        <MinimizedCardBody key={target.id}>
                                            <Grid.Container justify="between">
                                                <Grid xs={6} style={{ width: maxHandleWidth }} >
                                                    {target.label}
                                                </Grid>
                                                <Grid xs={6}>
                                                    <HandleActionsContainer>
                                                        <div style={{marginRight: '5px'}}>
                                                            <Tooltip tooltip={target.dims.join(',')} button='dims'></Tooltip>
                                                        </div>
                                                        <Tooltip tooltip={target.ai_format} button='format'></Tooltip>
                                                    </HandleActionsContainer>
                                                </Grid>
                                            </Grid.Container>
                                            <StyledLeftHandle
                                                id={`${target.id.toString()}`}
                                                type="target"
                                                position="left"
                                                optional={target.data?.optional?.toString()}
                                                isValidConnection={createIsValidConnection(target.id, nodeId, 'target_handles')}
                                            />
                                        </MinimizedCardBody>
                                    )
                                })
                            }
                        </>
                    )}
                </Card>
            </MinimizedCardWrapper>
            <Spacer y={1}/>
            <MinimizedCardWrapper>
                <Card variant={"flat"} css={{overflow: "visible", $$cardColor: "$colors$accents2"}}>
                    <MinimizedCardHeader>Outputs</MinimizedCardHeader>
                    {source_handles &&
                        source_handles.filter(source => !source.data.optional)
                            .map((source) => (
                                <MinimizedCardBody key={source.id}>
                                    <Grid.Container justify="between">
                                        <Grid xs={6} style={{ width: maxHandleWidth }} >
                                            {source.label}
                                        </Grid>
                                        <Grid xs={6}>
                                            <HandleActionsContainer>
                                                <div style={{marginRight: '5px'}}>
                                                    <Tooltip tooltip={source.dims.join(',')} button='dims'></Tooltip>
                                                </div>
                                                <Tooltip tooltip={source.ai_format} button='format'></Tooltip>
                                            </HandleActionsContainer>
                                        </Grid>
                                    </Grid.Container>
                                    <StyledRightHandle
                                        id={`${source.id.toString()}`}
                                        type="source"
                                        position="right"
                                        optional={source.data?.optional?.toString()}
                                        isValidConnection={createIsValidConnection(source.id, nodeId, 'source_handles')}
                                    />
                                </MinimizedCardBody>
                            ))
                    }
                </Card>
            </MinimizedCardWrapper>
        </>
    );
};


const SDNode = ({username, projectName, setExternalNodes, ...props}) => {
    const {projectState, nodes, edges, specificModels} = useContext(FlowContext);
    return (
        <div>
            <BaseNode
                id={props.id}
                label={props.data.label}
                type={props.type}
                data={{...props.data, label: 'SD'}}
                content={
                    <ContentBody
                        props={props}
                        data={props.data}
                        projectState={projectState}
                        nodes={nodes}
                        edges={edges}
                        nodeId={props.id}
                        node={props}
                        username={username}
                        projectName={projectName}
                        setExternalNodes={setExternalNodes}
                        availableSDs={specificModels.sds}
                    />
                }
            />
        </div>
    );
};

export default SDNode;
