import {Button, Row, Spacer} from "@nextui-org/react";
import {FaGithub} from "react-icons/fa";

import { FaGoogle } from "react-icons/fa";

import React from "react";
const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const GITHUB_CLIENT_ID = process.env.REACT_APP_GITHUB_CLIENT_ID;
const GITHUB_OAUTH_URL = `https://github.com/login/oauth/authorize?client_id=${GITHUB_CLIENT_ID}&redirect_uri=${FRONTEND_URL}/auth/callback/github&scope=user`

const GOOGLE_OAUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${FRONTEND_URL}/auth/callback/google&prompt=consent&response_type=code&client_id=${GOOGLE_CLIENT_ID}&scope=openid%20email%20profile&access_type=offline`;


const SingButtons = ({isSignUp=false}) => {
    const prefix = isSignUp ? 'Sign Up' : 'Sign In';
    return (
        <>
                <Button
                    bordered
                    color={'secondary'}
                    icon={<FaGithub/>}
                    style={{width: "100%"}}
                    onClick={() => {
                        window.location.href = GITHUB_OAUTH_URL
                    }}
                >
                    {prefix} via Github
                </Button>
                <Spacer x={1}/>
                <Button
                    bordered
                    color={'secondary'}
                    icon={<FaGoogle/>}
                    style={{width: "100%"}}
                    onClick={() => {
                        window.location.href = GOOGLE_OAUTH_URL
                    }}
                >
                    {prefix} via Google
                </Button>
        </>
    );
};


export default SingButtons;
