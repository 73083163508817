import axiosInstance from "./request";

const usersUrl = 'users/profile/'
const teamsUrl = 'users/teams/'

export async function getMe() {
    return await axiosInstance.get(`${usersUrl}me/`)
}

export async function getUser(username) {
    return await axiosInstance.get(`${usersUrl}${username}/detail/`)
}

export async function updateMe(userData) {
    return await axiosInstance.patch(`${usersUrl}me/`, userData)
}

export async function checkUsername(username) {
    return await axiosInstance.post(`${usersUrl}check_username/`, {'username': username})
}

export async function generateApiKey(username) {
    return await axiosInstance.post(`${usersUrl}${username}/generate_api_key/`,)
}

export async function createTeam(data) {
    return await axiosInstance.post(`${teamsUrl}`, data)
}

export async function getTeam(teamName) {
    return await axiosInstance.get(`${teamsUrl}${teamName}/`)

}

export async function inviteMember(team, username) {
    return await axiosInstance.post(`${teamsUrl}${team}/invite_member/`, {'username': username})
}

export async function removeMember(team, username) {
    return await axiosInstance.post(`${teamsUrl}${team}/remove_member/`, {'username': username})
}