import React, {memo, useContext, useEffect, useState} from "react";
import { Card, Grid, Modal, Button, Text } from "@nextui-org/react";
import BaseNode, {
    HandleActionsContainer,
    MinimizedCardBody,
    MinimizedCardHeader,
    MinimizedCardWrapper,
    StyledLeftHandle
} from "../CustomNodes/BaseNode";
import Tooltip from "../Tooltips";
import FlowContext from "../FlowContext";
import {getHandleData, getMaxHandleWidth} from "../utils";
import { compareDims } from "./checks";
import { toastWarn } from "../../../utils/toasts";
import StyledSelect from "../styles/styles";
import { handleTypeOptions } from "../../../utils/constants";
import { updateIOField, getNode } from "../../../api/flow";


const ContentBody = ({data, nodes, nodeId, projectState, node, setExternalNodes, username, projectName}) => {
    const target_handles = data.target_handles || [];
    const [maxHandleWidth, setMaxHandleWidth] = useState('50px');
    const newObjExists = target_handles.find((obj) => obj.id === 'new_target_handle');
    if (!newObjExists && ['ERROR', 'IDLE'].includes(projectState)) {
        const newObj = {
            id: 'new_target_handle',
            label_slug: 'Connect field',
        };
        target_handles.push(newObj);
    }

    const [handleModal, setHandleModal] = useState({visible: false});
    const [selectedHandle, setSelectedHandle] = useState(null);
    const [fieldType, setFieldType] = useState(null);

    const openModal = (target) => {
        setSelectedHandle(target);
        setHandleModal({visible: true});
        const fieldType = target.field_type;
        setFieldType(fieldType);
    };

    useEffect(() => {
        if (data.target_handles.length > 0) {
            const maxWidth = getMaxHandleWidth(data.target_handles, true);
            setMaxHandleWidth(maxWidth);
        }
    }, [data.target_handles]);

    const addHandle = async () => {
        if (fieldType) {
            const updatedSelectedHandleData = {
                ...selectedHandle.data,
            };
            const newHandle = {
                label: selectedHandle.label,
                data: updatedSelectedHandleData,
                field_type: fieldType,
            };

            if (selectedHandle) {
                const handleTypeKey = 'source_handles';
                node.data[handleTypeKey]['source_handles'] = newHandle;
                await updateIOField(newHandle, selectedHandle.id, node.id, username, projectName);
                setHandleModal({ visible: false });
                setSelectedHandle(null);
                const tempNode = await getNode(nodeId, username, projectName);
                await setExternalNodes(tempNode, tempNode.id);
            }
        }
    };

    const createIsValidConnection = (handleId, nodeId, handleType) => {
        return (connection) => {
            const allowedNodeTypes = ['CUSTOM_PYTHON', 'AI', 'LLM']
            const newHandlesIds = ['new_source_handle', 'new_target_handle'];
            const { startHandle, endNode, endHandle } = getHandleData(nodes, handleId, nodeId, handleType, connection);
            if (newHandlesIds.includes(endHandle.id) && newHandlesIds.includes(startHandle.id)) {
                toastWarn("Please, first create the handle manually", `createHandleManually-${startHandle.id}-${endHandle.id}`);
                return false;
            }
            if (startHandle.id === 'new_target_handle' && allowedNodeTypes.includes(endNode.type) ) {
                return true;
            } else {
                if (JSON.stringify(endHandle.dims)) {
                    if (!compareDims(startHandle.dims, endHandle.dims)) {
                        toastWarn('Dims do not match', `dimsDoNotMatch-${startHandle.id}-${endHandle.id}`);
                        return false;
                    }
                }
                if (endHandle.ai_format) {
                    if (JSON.stringify(startHandle.ai_format) !== JSON.stringify(endHandle.ai_format)) {
                        toastWarn('Formats do not match', `formatsDoNotMatch-${startHandle.id}-${endHandle.id}`);
                        return false;
                    }
                }
                return true
            }
        }
    }
    return (
        <>
            <MinimizedCardWrapper>
                <Card variant={"flat"} css={{overflow: "visible", $$cardColor: "$colors$accents2"}}>
                    <MinimizedCardHeader>Inputs</MinimizedCardHeader>
                    {target_handles &&
                        target_handles.map((target) => (
                            <MinimizedCardBody key={target.id}>
                                <Grid.Container justify="between" style={{ width: '100%' }}>
                                    <Grid xs={6} style={{ width: maxHandleWidth }} >
                                        {target.label_slug}
                                    </Grid>
                                    {target.id !== 'new_target_handle' &&
                                        <Grid xs={6}>
                                            <HandleActionsContainer>
                                                {projectState !== 'DEMO' &&
                                                <Button auto size="xs" style={{ marginRight: '5px' }}
                                                        onClick={() => {
                                                            setSelectedHandle(target);
                                                            openModal(target, "target");
                                                        }}>
                                                    edit
                                                </Button>
                                                }
                                                <div style={{ marginRight: '5px' }}>
                                                    <Tooltip tooltip={target.dims.join(',')} button='dims'></Tooltip>
                                                </div>
                                                <Tooltip tooltip={target.ai_format} button='format' ></Tooltip>
                                            </HandleActionsContainer>
                                        </Grid>
                                    }
                                </Grid.Container>
                                <StyledLeftHandle
                                    id={`${target.id}`}
                                    type="target"
                                    position="left"
                                    optional={target.data?.optional?.toString()}
                                    isValidConnection={createIsValidConnection(target.id, nodeId, "target_handles")}
                                />
                            </MinimizedCardBody>
                        ))}
                </Card>
            </MinimizedCardWrapper>
            <Modal open={handleModal.visible} onClose={() => setHandleModal({ visible: false })}>
                <Modal.Header>
                    <Text size={18}>Edit Handle</Text>
                </Modal.Header>
                <Modal.Body>
                    <label style={{ display: 'block' }}>
                        <span style={{ display: 'block' }}>API Field Type:</span>
                        <StyledSelect
                            value={fieldType}
                            onChange={(e) => setFieldType(e.target.value)}
                        >
                            {handleTypeOptions.map(type =>
                                <option key={type.value} value={type.value}>{type.label}</option>
                            )}
                        </StyledSelect>
                    </label>
                </Modal.Body>
                <Modal.Footer>
                    <Button auto flat color="success" onClick={addHandle}>
                        Save
                    </Button>
                    <Button auto flat color="error" onClick={() => setHandleModal({ visible: false })}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

const OutputNode = ({setExternalNodes, username, projectName, ...props}) => {
    const {nodes, projectState} = useContext(FlowContext);
    return (
        <BaseNode
            id={props.id}
            label={props.data.label}
            type={props.type}
            data={props.data}
            content={
                <ContentBody
                    data={{...props.data}}
                    nodes={nodes}
                    nodeId={props.id}
                    node={props}
                    projectState={projectState}
                    setExternalNodes={setExternalNodes}
                    username={username}
                    projectName={projectName}
                />
            }
        />
    );
}

export default memo(OutputNode);
