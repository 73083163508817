import React, {useCallback, useEffect, useState} from 'react';
import {Modal, Text, Button, Input, Spacer, Loading} from "@nextui-org/react";
import { updateIOField } from "../../../api/flow";
import { toastWarn } from "../../../utils/toasts";
import {checkCeleryTaskStatus, getCPNTestResults, testCPN} from "../../../api/projects";

const CustomTestModal = ({ isOpen, onClose, node, username, projectName }) => {
    const [sourceHandles, setSourceHandles] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [testResult, setTestResult] = useState('');
    const [userInputValues, setUserInputValues] = useState([]);
    const [allFieldsFilled, setAllFieldsFilled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        setTestResult(null);
        onClose();
        document.body.style.overflow = 'auto';
    };


    useEffect(() => {
        if (isOpen && node) {
            const filteredHandles = node.data.source_handles.filter(handle => handle.id !== "new_source_handle");
            setSourceHandles(filteredHandles);
            setUserInputValues(filteredHandles.map(handle => handle.data.perf_analyzer_dims || ''));
        }
    }, [isOpen, node]);

    const validateDims = (dims) => {
        const regex = /^\d+(,\d+)*$/;
        return regex.test(dims);
    };

    const checkAllFieldsFilled = useCallback((handles, userValues) => {
        const allFilled = handles.every((handle, index) => {
            const userInput = userValues[index];
            const hasError = validationErrors[index];
            if (hasError) {
                return false;
            }
            if (Array.isArray(userInput)) {
                return userInput.length > 0;
            } else if (typeof userInput === 'string') {
                return Boolean(userInput.trim());
            } else {
                return false;
            }
        });
        setAllFieldsFilled(allFilled);
    }, [validationErrors, setAllFieldsFilled]);

    useEffect(() => {
        checkAllFieldsFilled(sourceHandles, userInputValues);
    }, [sourceHandles, userInputValues, validationErrors, checkAllFieldsFilled]);


    const renderTestResult = () => {
        if (!testResult) return null;

        const isSuccess = testResult.is_passed;
        const resultStyle = {
            color: isSuccess ? '#17C964' : '#F31260',
            fontFamily: 'monospace',
            fontSize: '14px',
            paddingBottom: '1px',
            marginBottom: '1px',
        };

        const messageStyle = {
            backgroundColor: isSuccess ? '#d4edda' : '#f8d7da',
            borderRadius: '5px',
        };

        const textLogStyle = {
            fontFamily: 'monospace',
            fontSize: '14px',
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            hyphens: 'auto',
            margin: '10px',
            color: '#F31260FF'
        }

        return (
            <>
                <Text style={resultStyle}>
                    Status: {isSuccess ? 'Success' : 'Failure'}
                </Text>
                {!isSuccess && (
                    <div style={messageStyle}>
                        <Text style={textLogStyle}>{testResult.message}</Text>
                    </div>
                )}
            </>
        );
    };

    const handleDimChange = (index, value) => {
        const updatedUserInputValues = [...userInputValues];
        updatedUserInputValues[index] = value;
        setUserInputValues(updatedUserInputValues);

        const isValid = validateDims(value);
        const newValidationErrors = {...validationErrors};
        if (!isValid) {
            newValidationErrors[index] = "Only positive numbers separated by commas are allowed.";
        } else {
            delete newValidationErrors[index];
        }
        setValidationErrors(newValidationErrors);

        const updatedHandles = [...sourceHandles];
        const dims = value.split(',').map(dim => dim.trim()).filter(dim => dim);
        if (updatedHandles[index].data) {
            updatedHandles[index].data.perf_analyzer_dims = dims;
        }

        setSourceHandles(updatedHandles);
        checkAllFieldsFilled(updatedHandles, updatedUserInputValues);
    };

    const updatePerfDims = async (handle) => {
        if (handle.data && handle.data.perf_analyzer_dims) {
            try {
                await updateIOField(handle, handle.id, node.id, username, projectName);
            } catch (error) {
                toastWarn("Failed to update perf_analyzer_dims");
            }
        }
    };

    const handleTest = async () => {
        setTestResult(null)
        setIsLoading(true);
        for (const handle of sourceHandles) {
            await updatePerfDims(handle);
        }
        const taskResponse = await testCPN(username, projectName, node.id);
        let status = 'PENDING';
        while (status === 'PENDING') {
            const task = await checkCeleryTaskStatus(taskResponse.task_id);
            status = task.status;
            if (status === 'PENDING') {
                await new Promise(resolve => setTimeout(resolve, 1000));
            } else if (["SUCCESS", "ERROR"].includes(status)) {
                setTestResult(await getCPNTestResults(username, projectName, node.id));
                setIsLoading(false);
                break;
            }
        }
    };

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Modal.Header>
                <Text h3>Custom Testing</Text>
            </Modal.Header>
            <Modal.Body>
                {isLoading && <Loading>Loading...</Loading>}
                {!isLoading && sourceHandles.map((handle, index) => (
                    <div key={`handle-${index}`}>
                        <Input
                            id={`input-dims-${index}`}
                            aria-label={`Dimensions for ${handle.label_slug}`}
                            labelLeft={handle.label}
                            placeholder={handle.dims || 'Enter dimensions'}
                            value={userInputValues[index] || ''}
                            onChange={(e) => handleDimChange(index, e.target.value)}
                            helperText={validationErrors[index]}
                            fullWidth={true}
                            disabled={isLoading}
                        />
                        <Spacer y={0.5}/>
                    </div>
                ))}
                {testResult && (
                    <>
                        {renderTestResult()}
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    auto
                    color="warning"
                    disabled={isLoading || !allFieldsFilled || Object.keys(validationErrors).length > 0}
                    onClick={async () => {
                        for (const handle of sourceHandles) {
                            await updatePerfDims(handle);
                        }
                        await handleTest();
                    }}>
                    Test Pipeline
                </Button>
                <Button auto flat color="error" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CustomTestModal;
