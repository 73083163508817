import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Col, Container, Row } from 'react-bootstrap';

import titleStyle from "../../Finances.module.css";
import styles from "./index.module.css";
import AddCardForm from '../../AddCardForm/index.tsx';

function AddPayment({ user, show, setShow, setUnsavedCards, amount }) {
    const handleClose = () => setShow(false);

    return (
        <>
            <Modal show={show} onHide={handleClose} >
                <Container fluid style={{ padding: "30px" }}>
                    <Row>
                        <Col className={titleStyle.bigText} xs={8}>Add Payment Method</Col>
                        <Col>
                            <Modal.Header closeButton style={{ border: "none", padding: "6px" }}>
                            </Modal.Header>
                        </Col>
                    </Row>
                    <AddCardForm
                        user={user}
                        handleClose={handleClose}
                        setUnsavedCards={setUnsavedCards}
                        amount={amount}
                    />
                </Container>
            </Modal>
        </>
    );
}

export function Label({ children }) {
    return (
        <label className={`${styles.label} ${styles.text}`}>{children}</label>
    );
}

export function Input({ value, setValue, placeholder = "0", style = {}, type = "number" }) {
    const [isNotValidAmount, setIsNotValidAmount] = useState(true)
    const onChangeInputValue = (value: string) => {
        if (setValue) {
            const folatValue = parseInt(value)
            setIsNotValidAmount(true)
            if (folatValue <= 0 || isNaN(folatValue)) {
                setValue("")
                setIsNotValidAmount(true)
            } else if (folatValue < 5) {
                setValue(folatValue)
                setIsNotValidAmount(true)
            }
            else {
                setValue(folatValue)
                setIsNotValidAmount(false)
            }
        }
    }
    return (
        <Col style={{ position: "relative" }}>
            <input
                className={`
                            ${styles.inputMoney} 
                            ${styles.inputText}
                                        `}
                onChange={(e) => onChangeInputValue(e.target.value)}
                value={parseInt(value)}
                type={type}
                placeholder={placeholder}
                style={style}
            />
            <div className={`${styles.dollar} ${styles.inputText}`}>$</div>
        </Col>
    );
}

export default AddPayment;