import {createContext} from "react";

const FlowContext = createContext({
    projectID: null,
    removeNode: () => {},
    nodes: [],
    edges: [],
});

export default FlowContext;
