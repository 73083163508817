import React, {useState} from 'react';
import {Table, Row, Col, Tooltip, User, Text, Button, Spacer} from "@nextui-org/react";

import InviteMemberModal from "./Modals/InviteTeamMemberModal";
import RemoveMemberModal from "./Modals/RemoveMemberModal";
import {MdDeleteForever} from "react-icons/md";

const TeamMembersTable = ({requestUser, teamData, setTeamData}) => {
    const [inviteMemberModalOpen, setInviteMemberModalOpen] = useState(false);
    const [removeMemberModalOpen, setRemoveMemberModalOpen] = useState(false);
    const [selectedMember, setSelectedMember] = useState(null);

    const openInviteMemberModal = () => {
        setInviteMemberModalOpen(true);
    };

    const closeInviteMemberModal = () => {
        setInviteMemberModalOpen(false);
    };

    const openRemoveMemberModal = (member) => {
        setSelectedMember(member);
        setRemoveMemberModalOpen(true);
    };

    const closeRemoveMemberModal = () => {
        setSelectedMember(null);
        setRemoveMemberModalOpen(false);
    };

    const columns = [
        {name: "NAME", uid: "name"},
        {name: "ROLE", uid: "role"},
        {name: "JOINED", uid: "joined"},
        {name: "ACTIONS", uid: "actions"},
    ];

    const renderCell = (member, columnKey) => {
        switch (columnKey) {
            case "name":
                return (
                    <User squared src="" name={member.user.username} css={{p: 0}}>
                        {member.user.email}
                    </User>
                );
            case "role":
                return (
                    <Col>
                        <Row>
                            <Text b size={14} css={{tt: "capitalize"}}>
                                {member.role.name}
                            </Text>
                        </Row>
                    </Col>
                );
            case "joined":
                return (
                    <Col>
                        <Row>
                            <Text b size={13} css={{tt: "capitalize", color: "$accents7"}}>
                                {new Date(member.date_joined).toLocaleDateString()}
                            </Text>
                        </Row>
                    </Col>
                );
            case "actions":

                return (
                    <Row justify="center" align="center">
                        <Col css={{d: "flex"}}>
                            <Tooltip content="Remove member" color="error">
                                {teamData.team_founder === requestUser.username && member.role.name !== 'Owner' && (
                                    <Button
                                        isDisabled={member.role.name === 'Owner'}
                                        onClick={() => openRemoveMemberModal(member)}
                                        auto
                                        light
                                        icon={<MdDeleteForever size={20} fill="#FF0080"/>}
                                    />
                                    )
                                }
                            </Tooltip>
                        </Col>
                    </Row>
                )
            default:
                return null;
        }
    };

    return (
        <>
            <Table
                aria-label="Team members table"
                css={{
                    height: "auto",
                    minWidth: "100%",
                }}
            >
                <Table.Header columns={columns}>
                    {(column) => (
                        <Table.Column key={column.uid} align={column.uid === "actions" ? "center" : "start"}>
                            {column.name}
                        </Table.Column>
                    )}
                </Table.Header>
                <Table.Body items={teamData.members}>
                    {(item) => (
                        <Table.Row key={item.user.username}>
                            {(columnKey) => (
                                <Table.Cell>{renderCell(item, columnKey)}</Table.Cell>
                            )}
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
            {teamData && teamData.team_founder === requestUser.username && (
                <Button auto flat style={{"width": "100%", "marginTop": "12px"}} color="secondary" bordered onClick={openInviteMemberModal}>
                    Invite Member
                </Button>
            )}
            <InviteMemberModal
                isOpen={inviteMemberModalOpen}
                onClose={closeInviteMemberModal}
                teamUsername={teamData.username}
                setTeamData={setTeamData}
            />
            <RemoveMemberModal
                isOpen={removeMemberModalOpen}
                onClose={closeRemoveMemberModal}
                teamUsername={teamData.username}
                selectedMember={selectedMember}
                setTeamData={setTeamData}
            />
        </>
    );
};

export default TeamMembersTable;