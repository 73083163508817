import React, { useState } from 'react';
import { logout } from '../../api/auth';
import {logoutUser} from "../../utils/auth";
import { useDispatch } from 'react-redux';
import { Card, Container, Text, Spacer, Button } from '@nextui-org/react';
import {HeaderSpacer} from "../Header/HeaderSpacer";
import {useNavigate} from "react-router-dom";

function LoginForm() {
    const [error] = useState(null);



    return (
        <Container xs justify="center">
            <HeaderSpacer/>
            <Card>
                <Card.Body>
                    <Text h2>Logout</Text>
                    <Spacer y={1} />
                    {error && (
                        <>
                            <Card css={{ backgroundColor: '$warning' }}>
                                <Card.Body>{error}</Card.Body>
                            </Card>
                            <Spacer y={2} />
                        </>
                    )}
                    <Text>Are you sure you want to log out?</Text>
                    <Spacer y={1} />

                </Card.Body>
            </Card>
        </Container>
    );
}

export default LoginForm;
