import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { Spacer, Text } from "@nextui-org/react";
import DeploymentInfo from "./PodCard";
import { MAXIMUM_PODS_DISPLAYED, STATE } from "../../../../../utils/constants";

import styles from "./styles/DeploySettings.module.css";

import Union from "./images/Union.svg";

export default function PodsContainer({
    projectDeploymentInfo,
    maxPods,
    widthScreen,
    BREAKPOINT,
    ...props }: Props) {

    const pods = getSortedPods(projectDeploymentInfo.deployments); 
    const activePods = pods.length;
    const potentialPods = Math.max(0, maxPods - activePods);
    const podsByStatus = getNumberPodsByStatus(projectDeploymentInfo);

    return (
        <>
            <Row>
                <Col>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ lineHeight: "36px" }}>
                            <span className={styles.settingLabel}>
                                Monitoring
                            </span>
                        </div>
                        {widthScreen >= BREAKPOINT &&
                            <Btn props={props} />
                        }
                    </div>
                </Col>
            </Row>
            <Row style={{ paddingTop: "12px" }}>
                <Col className={styles.label} style={{ paddingTop: "4px" }}>
                    Active Pods:&nbsp;
                    <span className={styles.podsCount}>
                        {activePods}/{maxPods}
                    </span>
                </Col>
            </Row>
            <Row>
                <Col className={styles.activePods} xs={12} >
                    {activePods > 30 ?
                        Object.entries(podsByStatus).map(([state, count], index) => (
                            count > 0 && <DeploymentInfo key={index} deployment={{ state, count }} />
                        ))
                        : pods.map(
                            (deployment, index) => (
                                <DeploymentInfo
                                    key={index}
                                    deployment={deployment}
                                />
                            ))}
                </Col>
            </Row>
            <Spacer y={1} />
            <Row justify={"space-between"}>
                <Text
                    className={styles.label}
                    style={{ marginBottom: "0" }}
                >
                    Idle Pods:&nbsp;
                    <span className={styles.podsCount}>
                        {potentialPods < 0 ? 0 : potentialPods}
                    </span>
                </Text>
            </Row>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
                {Array.from({ length: Math.min(potentialPods, MAXIMUM_PODS_DISPLAYED) }, (_, index) => (
                    <DeploymentInfo key={`potential-${index}`} deployment={{ state: "IDLE" }} />
                ))}
            </div>
            {widthScreen < BREAKPOINT &&
                <Row className="pt-4">
                    <Col>
                        <Btn props={props} />
                    </Col>
                </Row>
            }
        </>
    );
}

function Btn({props}) {
    return (
        <button
            className={styles.refresh}
            onClick={_ => props.updateProjectState()}
        >
            Refresh <Image
                        src={Union}
                        alt="Union"
                        height={14}
                        style={{ paddingBottom: "2px" }}
                    />
        </button>
    );
}

const getSortedPods = (projects: Pod[]): Pod[] => {
    return projects.sort((a, b) => {
        const getStateIndex = (state: string): number => {
            switch (state) {
                case STATE.RUNNING: return 0;
                case STATE.STARTING: return 1;
                case STATE.STOPPING: return 2;
                default: return 3;
            }
        }
        const stateIndexA = getStateIndex(a.state)
        const stateIndexB = getStateIndex(b.state)

        if (stateIndexA < stateIndexB) return -1;
        if (stateIndexA > stateIndexB) return 1;

        return 0;
    });
}

const getNumberPodsByStatus = (projects: ProjectDeploymentInfo): PodStatusCount => {
    return projects.deployments.reduce((acc: PodStatusCount, item: Pod) => {
        acc[item.state] = (acc[item.state] || 0) + 1;
        return acc;
    }, {});
};

interface Props {
    projectDeploymentInfo: ProjectDeploymentInfo,
    maxPods: number,
    widthScreen: number,
    BREAKPOINT: number,
    [key: string]: any,
}

interface PodStatusCount {
    RUNNING?: number;
    STARTING?: number;
    STOPPING?: number;
}

interface Pod {
    accelerators__label: string,
    hourly_price: number,
    state: "STARTING" | "RUNNING" | "STOPPING",
}

interface ProjectDeploymentInfo {
    state: "STARTING" | "RUNNING" | "STOPPING" | "IDLE" | "ERROR",
    deployments: Pod[],
}