import axiosInstance from "./request";

const projectUrl = "/";


export async function getFlowTree(username, projectName) {
    return await axiosInstance.get(`${projectUrl}${username}/${projectName}/head/`);
}

export async function updateNode(node, username, projectName) {
    return await axiosInstance.put(`${projectUrl}${username}/${projectName}/nodes/${node.id}/`, node);
}

export async function createNode(node, username, projectName) {
    return await axiosInstance.post(`${projectUrl}${username}/${projectName}/nodes/`, node);
}

export async function deleteNode(nodeID, username, projectName) {
    return await axiosInstance.delete(`${projectUrl}${username}/${projectName}/nodes/${nodeID}/`);
}

export async function getNode(nodeID, username, projectName) {
    return await axiosInstance.get(`${projectUrl}${username}/${projectName}/nodes/${nodeID}/`);
}

export async function createEdge(edge, username, projectName) {
    return await axiosInstance.post(`${projectUrl}${username}/${projectName}/edges/`, edge);
}

export async function getNodes(username, projectName, type="") {
    if (type === "") {
        return await axiosInstance.get(`${projectUrl}${username}/${projectName}/nodes/`);
    }
    return await axiosInstance.get(`${projectUrl}${username}/${projectName}/nodes/?type=${type}`);
}

export async function deleteEdge(edgeID, username, projectName,) {
    return await axiosInstance.delete(`${projectUrl}${username}/${projectName}/edges/${edgeID}/`);
}


export async function createIOField(field, nodeID, username, projectName) {
    return await axiosInstance.post(`${projectUrl}${username}/${projectName}/nodes/${nodeID}/fields/`, field);
}

export async function createManuallyIOField(field, nodeID, username, projectName, fieldType) {
    return await axiosInstance.post(`${projectUrl}${username}/${projectName}/nodes/${nodeID}/fields/add_manually/?field_type=${fieldType}/`, field);
}

export async function updateIOField(field, fieldID, nodeID, username, projectName,) {
    return await axiosInstance.put(`${projectUrl}${username}/${projectName}/nodes/${nodeID}/fields/${fieldID}/`, field);
}

export async function deleteIOField(fieldID, nodeID, username, projectName,) {
    return await axiosInstance.delete(`${projectUrl}${username}/${projectName}/nodes/${nodeID}/fields/${fieldID}/`);
}

export async function downloadConfig(nodeID, username, projectName,) {
    return await axiosInstance.get(`${projectUrl}${username}/${projectName}/nodes/${nodeID}/get_config/`);
}

export async function playProject(username, projectName) {
    return await axiosInstance.post(`${projectUrl}${username}/${projectName}/play/`);
}

export async function testProject(username, projectName, runTensorRT) {
    return await axiosInstance.post(`${projectUrl}${username}/${projectName}/test/`, {run_tensor_rt: runTensorRT});
}

export async function stopProject(username, projectName,) {
    return await axiosInstance.post(`${projectUrl}${username}/${projectName}/stop/`);
}



