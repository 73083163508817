const initialState = {
    accessToken: null,
    refreshToken: null,
    expirationTime: null,
    error: null,
};

const token = (state = initialState, action) => {
    switch (action.type) {
        case 'TOKEN_CREATED':
            return {
                ...state,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
                expirationTime: action.payload.expirationTime,
                error: null,
            };
        case 'ACCESS_TOKEN_REFRESHED':
            return {
                ...state,
                accessToken: action.payload.accessToken,
                expirationTime: action.payload.expirationTime,
                error: null,
            };

        case 'TOKEN_DESTROYED':
            return {
                ...state,
                accessToken: null,
                refreshToken: null,
                expirationTime: null,
                error: null
            };
        case 'TOKEN_FAILED':
            return {
                ...state,
                expirationTime: null,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default token;
