import React, { useState, useEffect } from 'react';
import { Range, getTrackBackground } from 'react-range';

const TRACK_BG = '#f0f0f0';
const THUMB_BG = '#0F32AF';

const Slider = ({ min, max, values, onChange, disabled = false, step = 1 }) => {
    const [minimum, setMinimum] = useState(0)
    const [maximum, setMaximum] = useState(2)
    const [value, setValues] = useState([0]);

    useEffect(() => {
        const minimum = parseFloat(min)
        const maximum = parseFloat(max)
        setMinimum(minimum);
        setMaximum(maximum);
        if (values < minimum) {
            setValues([minimum]);
        } else if (values > maximum) {
            setValues([maximum])
        } else {
            setValues([values])
        }
    }, [min, max, values]);

    return (
        <div style={{ position: 'relative', width: '100%', margin: '20px 0' }}>
            <Range
                values={[value]}
                step={step}
                min={minimum}
                max={maximum}
                disabled={disabled}
                onChange={onChange}
                renderTrack={({ props, children }) => (
                    <div {...props}
                        style={{
                            ...props.style,
                            height: '6px',
                            width: '100%',
                            background: getTrackBackground({
                                values: [value],
                                colors: [THUMB_BG, TRACK_BG],
                                min: minimum,
                                max: maximum,
                            }),
                        }}
                    >
                        {children}
                    </div>
                )}
                renderThumb={({ props }) => (
                    <div {...props}
                        style={{
                            ...props.style,
                            height: '16px',
                            width: '16px',
                            borderRadius: '50%',
                            backgroundColor: THUMB_BG,
                            outline: 'none', // Убираем обводку для более чистого вида
                        }}
                        role="slider"
                        aria-valuenow={value}
                        aria-valuemin={minimum}
                        aria-valuemax={maximum}
                        tabIndex={0} // Делаем элемент доступным для фокуса 
                    />
                )}
            />
        </div>
    );
};

export default Slider;